// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Observable, Subscription } from "rxjs";

/**
 * Subscribe to many things and then unsubscribe to all of them with one call.
 * Makes it much harder to have accidental memory leaks in your RxJS apps.
 */
export class SubscriptionSet {
    constructor() {

    }

    private _subscriptions : Subscription[] = [];
    
    interval(timeout, callback) {
        let interval = setInterval(callback, timeout);
        this._subscriptions.push(<any>{
            unsubscribe() {
                clearInterval(interval);
            }
        });
    }

    add(subscription : Subscription) {
        this._subscriptions.push(subscription);
        return subscription;
    }

    subscribe<T>(obs : Observable<T>, callback : (t : T) => void): Subscription {
        let sub = obs.subscribe(callback);
        this._subscriptions.push(sub);

        sub.add(() => this._subscriptions = this._subscriptions.filter(x => x !== sub));
        return sub;
    }

    unsubscribeAll() {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._subscriptions = [];
    }
}