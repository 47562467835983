// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SettingsService } from '@/shell-common';

@Component({
  selector: 'app-entry-jump',
  templateUrl: './entry-jump.component.html',
  styleUrls: ['./entry-jump.component.scss']
})
export class EntryJumpComponent implements OnInit {

  constructor(
    private router : Router,
    private settingsService : SettingsService
  ) { }

  ngOnInit() {
    if (this.settingsService.monitors.length > 0 || environment.isElectron) {
      this.router.navigate(['/app']);
    } else {
      this.router.navigate(['/web/home']);
    }
  }
}
