// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { PromotionalLicenseAvailability } from '@astronautlabs/licensing';

export const PROMOTIONAL_LICENSES : PromotionalLicenseAvailability[] = [
    {
        enabled: true,
        code: 'test:pro',
        sku: 'professional', 
        licenseDetails: {
            comment: 'Welcome to Professional!',
            startedAt: Date.parse('2019-01-01'), 
            validUntil: Date.parse('2019-11-01')
        }
    },{
        enabled: true,
        code: 'test:enterprise',
        sku: 'enterprise', 
        licenseDetails: {
            comment: 'Welcome to Enterprise!',
            startedAt: Date.parse('2019-01-01'), 
            validUntil: Date.parse('2019-11-01')
        }
    },{
        enabled: true,
        code: 'tyt:toostrong',
        sku: 'enterprise', 
        licenseDetails: {
            comment: 'Hello Turks!',
            startedAt: Date.parse('2019-11-01'), 
            validUntil: Date.parse('2022-11-01')
        }
    },{
        enabled: true,
        code: 'test:community',
        sku: 'community', 
        licenseDetails: {
            comment: 'Welcome to Community!',
            startedAt: Date.parse('2019-01-01'), 
            validUntil: Date.parse('2019-11-01')
        }
    },
    {
        enabled: true,
        code: 'test:expired',
        sku: 'professional', 
        licenseDetails: {
            comment: 'Expired Test',
            startedAt: Date.parse('2019-01-01'), 
            validUntil: Date.now() - 1000
        }
    },
    {
        enabled: true,
        code: 'test:billing-failure',
        sku: 'professional', 
        licenseDetails: {
            comment: 'Billing Failure Test',
            renewable: true,
            startedAt: Date.parse('2019-01-01'), 
            validUntil: Date.now() - 1000
        }
    },
    {
        enabled: true,
        code: 'siggraph2019',
        expiresAt: Date.parse('2019-09-01'),
        sku: 'enterprise',
        licenseDetails: {
            comment: `
            Hello SIGGRAPH ! Please enjoy this preview version of Overview until 
            September 1, 2019 GMT
            `,
            startedAt: Date.parse('2019-06-01 00:00:00'), 
            validUntil: Date.parse('2019-09-01 00:00:00'),
            
            grantscreen: `
            # Hello SIGGRAPH 2019!

            We have something new to share. 
            Built with love in Los Angeles, CA. 
            
            ![Hello SIGGRAPH!](https://thumbs.gfycat.com/SelfreliantInfamousAfricanwildcat-small.gif "Hello SIGGRAPH!")

            Please enjoy this preview version of Overview.
            All features are active and enabled through September 1, 2019 GMT.

            `,

            endscreen: `

            # Hello SIGGRAPH! Thank you for trying Overview

            We hope you like what we've built so far. 
            Contact us and let us know what you thought.
            
            `,
            endActions: [ 
                {
                    label: 'What did you think?',
                    url: 'mailto:feedback@overviewqc.com'
                },
                {
                    label: 'Learn more',
                    url: 'https://overviewqc.com/'
                }
            ]
        }
    },
    {
        enabled: true,
        code: 'smw2019',
        expiresAt: Date.parse('2020-01-01'),
        sku: 'enterprise',
        licenseDetails: {
            comment: `
            Hello Streaming Media West 2019! Please enjoy this preview version of Overview until 2020 GMT.
            `,
            startedAt: Date.parse('2019-06-01 00:00:00'), 
            validUntil: Date.parse('2020-01-01 00:00:00'),
            
            grantscreen: `
            # Hello Streaming Media West 2019!

            We have something new to share. 
            Built with love here in LA. 
            
            ![SMW2019](https://www.streamingmedia.com/Conferences/images/west2018/logo.svg)

            Please enjoy this preview version of Overview.
            All features are active and enabled through September 1, 2019 GMT.

            `,

            endscreen: `

            # Hello Streaming Media West 2019! 
            
            Thank you for trying Overview.

            We hope you like what we've built so far. 
            Contact us and let us know what you thought.
            
            `,
            endActions: [
                {
                    label: 'What did you think?',
                    url: 'mailto:feedback@overviewqc.com'
                }
            ]
        }
    }
]