// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { MonitorSource } from '@/overview/monitors';
import { Observable, Observer, Subscribable, Subscription, PartialObserver, Unsubscribable } from 'rxjs';
import * as uuidv4 from 'uuid/v4';

export interface TimelineTrack {
    id : string;
    label : string;
}

export interface TimelineSpan {
    id : string;
    track : TimelineTrack;

    type? : string;
    startsAt : number;
    length? : number;
    label? : string;
    summary? : string;
    detailsTitle? : string;
    details? : string;
    unclosed? : boolean;
}

export interface Trigger {
    type : 'scte-35' | 'cue-in' | 'cue-out' | 'cue-out-cont';
    timecode : number;
    data : any;
}

export interface NetworkRequest {
    id : string;
    url : string;
    status : string;
    errorMessage : string;
    startedAt : Date;
    finishedAt : Date;
    timeMS : number;

    readonly time : number;
    readonly scheme : string;
    readonly domain : string;
    readonly path : string;
    readonly query : string;
    readonly dirname : string;
    readonly filename : string;
    readonly filenameWithoutExtension : string;
    readonly extension : string;
}

export class NodeNetworkRequest implements NetworkRequest {
    constructor(readonly request : Request) {
        let url = new URL(request.url);
        
        this._url = request.url;
        this._id = uuidv4();
        this._scheme = url.protocol;
        this._path = url.pathname;
        this._query = url.search;
        this._domain = url.host;

        this._dirname = url.pathname.replace(/\/[^\/]+$/, '');
        this._filename = url.pathname.replace(/^.*\//, '');

        if (this._dirname === '' && this._filename === '') {
            this._dirname = '';
            this._filename = url.pathname.slice(1);
        }

        if (this._filename === '') {
            this._filename = null;
        }

        if (this._filename.includes('.')) {
            this._filenameWithoutExtension = this._filename.replace(/\.[^\.]*$/, '');
            this._extension = this._filename.replace(/^.*\./, '');
        } else {
            this._filenameWithoutExtension = null;
            this._extension = null;
        }


        this._interval = setInterval(() => this.updateTiming(), 1000.0);
    }

    get id() : string {
        return this._id;
    }

    _id : string;

    private _interval : any;
    private _url : string;
    private _scheme : string;
    private _domain : string;
    private _path : string;
    private _query : string;
    private _dirname : string;
    private _filename : string;
    private _filenameWithoutExtension : string;
    private _extension : string;
    
    toJSON() {
        return <NetworkRequest>{
            id: this.id,
            url: this.url,
            status: this.status,
            errorMessage: this.errorMessage,
            startedAt: this.startedAt,
            finishedAt: this.finishedAt,
            timeMS: this.timeMS,
        
            time: this.time,
            scheme: this.scheme,
            domain: this.domain,
            path: this.path,
            query: this.query,
            dirname: this.dirname,
            filename: this.filename,
            filenameWithoutExtension: this.filenameWithoutExtension,
            extension: this.extension
        }
    }

    status : string = 'started';
    errorMessage : string;
    startedAt : Date;
    finishedAt : Date;
    response : Response;

    timeMS : number;

    updateTiming() {
        if (!this.startedAt) {
            this.timeMS = 0;
            return;
        }
    
        let fin : number;

        if (this.finishedAt)
            fin = this.finishedAt.getTime();
        else
            fin = Date.now();

        this.timeMS = fin - this.startedAt.getTime()
    }

    get time() {
        return this.timeMS / 1000;
    }

    get url() { return this._url; }
    get scheme() { return this._scheme; }
    get domain() { return this._domain; }
    get path() { return this._path; }
    get query() { return this._query; }
    get dirname() { return this._dirname; }
    get filename() { return this._filename; }
    get filenameWithoutExtension() { return this._filenameWithoutExtension; }
    get extension() { return this._extension; }

    started() {
        this.status = 'pending';
        this.startedAt = new Date();
    }

    errored(message = 'An unknown error occurred') {
        this.finished();
        this.status = 'error';
        this.errorMessage = message;
    }

    finished() {
        this.finishedAt = new Date();
        this.status = 'finished';
        this.updateTiming();

        clearInterval(this._interval);
    }
}

export interface RendererMediaManifest {
    channels? : number;
    duration : number;
}

export interface AudioMeterChannelReading {
    lifetimePeakLevel : number;
    clipping : boolean;
    lastClip : number;
    peakLevel : number;
    rms : number;
}

export interface AudioLoudnessReading {
    shortTermLoudness : number;
    maximumShortTermLoudness : number;
    momentaryLoudness : number;
    momentaryLoudnessForIndividualChannels : number[];
    maximumMomentaryLoudness : number;
    integratedLoudness : number;
    loudnessRangeStart : number;
    loudnessRangeEnd : number;
    loudnessRange : number;
    measurementDuration : number;
}

export interface AudioTruePeakReading {
    channels : number[];
    rawPeaks : number[];
}

export interface AudioMeterReading {
    channels : AudioMeterChannelReading[];
    loudness : AudioLoudnessReading;
    truePeak : AudioTruePeakReading;
    sampleRate : number;
    averaging : number;
}


export interface RendererSettings {
    enableNetworkMonitoring : boolean;
    audioMeters : AudioMeterOptions;
}

export interface RendererError {
    type : string;
    message : string;
}

export interface AudioMeterOptions {
    sampleRate? : number;
    clipLevel? : number;
    averaging? : number;
    clipLag? : number;
    rmsWindowTime? : number;

    enableAudioMonitoring? : boolean;
    enableLUFS? : boolean;
    enableTruePeak? : boolean;
}

export class EventEmitter<T> {
    subscribe(callback : (v : T) => void) : Unsubscribable {
        this.callbacks.push(callback);
        return new Subscription(() => this.callbacks = this.callbacks.filter(x => x !== callback));
    }

    private callbacks : ((v : T) => void)[] = [];

    next(value : T) {
        for (let cb of this.callbacks)
            cb(value);
    }
}

/**
 * An array that contains all the event types that can be
 * emitted by a renderer. Each of these is an Observable property 
 * of a renderer. You can use this to metaprogram and it will be 
 * kept up to date with the available renderer observables.
 */
export const RENDERER_EVENTS = [
    'error',
    'audioMeterReadings',
    'errors',
    'muteChanged',
    'logSpanUpdated',
    'manifestChanged',
    'networkRequestUpdated',
    'positionProgressed',
    'programSpanUpdated',
    'seeked',
    'statsUpdated',
    'triggerUpdated',
    'warnings'
];

export interface LiveStreamRenderer {
    id : string;
    name : string;
    tag? : string;

    /**
     * True if the renderer supports exposing a MediaStream.
     * Remote (isolated) rendering and recording is only 
     * supported for renderers that support streaming.
     */
    supportsStream? : boolean;
    mediaStream? : MediaStream;

    // API

    mute?() : Promise<void>;
    unmute?() : Promise<void>;
    seek(position : number): Promise<void>;

    // Lifecycle
    
    start(source : MonitorSource, settings : RendererSettings);
    destroy() : Promise<void>;
    focused?();
    unfocused?();

    // Updates

    programSpanUpdated : EventEmitter<TimelineSpan>;
    logSpanUpdated : EventEmitter<TimelineSpan>;
    triggerUpdated : EventEmitter<Trigger>;
    networkRequestUpdated : EventEmitter<NetworkRequest>;
    manifestChanged : EventEmitter<RendererMediaManifest>;
    positionProgressed : EventEmitter<number>;
    statsUpdated : EventEmitter<string>;
    audioMeterReadings : EventEmitter<AudioMeterReading>;
    muteChanged : EventEmitter<boolean>;
    errors : EventEmitter<RendererError>;
    warnings : EventEmitter<RendererError>;
    seeked : EventEmitter<number>;
    mediaStreamChanged? : EventEmitter<MediaStream>;
}
