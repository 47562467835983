// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { ProductInit } from '@astronautlabs/chassis';
import { FEATURES } from './features';
import { SKUS } from './skus';
import { FAQS } from './faqs';
import { PROMOTIONAL_LICENSES } from './promotional-licenses';
import { environment } from '../environments/environment';

export const PRODUCT : ProductInit = {
    id: 'overview',
    name: 'Overview',
    logoUrl: '/assets/logo2.svg',
    summary: 'A modern streaming video quality control and monitoring app',
    features: FEATURES,
    skus: SKUS,
    //promotionalLicenseAvailability: PROMOTIONAL_LICENSES,
    faqs: FAQS,
    version: 'Alpha',
    release: '1.0.0alpha1',
    pages: {
      app: {
        url: '/app'
      },
      features: {
        url: '/web/features',
        callToActionText: 'Try it now',
        callToActionUrl: '/web/downloads',
        summary: ''
      },
      pricing: {
        url: '/web/pricing'
      }
    },
    stripePubKey: environment.stripePubKey,
    alServiceUrl: environment.alServiceUrl,
    licenseServiceUrl: `${environment.alServiceUrl}/licensing`,
    updateServer: 'https://overview-releases.astrocdn.com'
  }