// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { BS1770LoudnessNode, LoudnessState } from './loudness-meter.audioworklet';
import { Subject, Observable } from 'rxjs';

export { LoudnessState };

export interface Stage1Coefficients {
    b0 : number;
    b1 : number;
    b2 : number;
    a1 : number;
    a2 : number;
}

const SAMPLE_RATE_48k = 48000;
const STAGE_1_AT_48khz : Stage1Coefficients = {
    b0: 1.53512485958697,
    b1: -2.69169618940638,
    b2: 1.19839281085285,
    a1: -1.69065929318241,
    a2: 0.73248077421585
};

export class LoudnessMeter {
    constructor (private _context : AudioContext) {
        
    }

    static async create(context : AudioContext, source : AudioNode) {
        let meter = new LoudnessMeter(context);
        await meter.initialize(source, context.destination);
        return meter;
    }

    async initialize(source : AudioNode, destination : AudioNode) {
        this.initializeCoefficientDerivation();

        let context = this._context;
        let coeff = this.determineCoefficients(context.sampleRate);
        let { b0, b1, b2, a1, a2 } = coeff;

        try {
            this.stage1aNode = new IIRFilterNode(context, {
                feedback: [ 1, a1, a2 ],
                feedforward: [ b0, b1, b2 ]
            });
        } catch (e) {
            console.error('Failed to initialize stage1a:');
            console.error(e);
            console.error('Bailing!');
            return;
        }

        this.stage1bNode = new IIRFilterNode(context, {
            feedback: [ 1, -1.99004745483398, 0.99007225036621 ],
            feedforward: [ 1.0, -2.0, 1.0 ]
        });

        source.connect(this.stage1aNode);
        this.stage1aNode.connect(this.stage1bNode);

        this.loudnessNode = await BS1770LoudnessNode.create(context);

        source.connect(this.loudnessNode, 0, 0);
        this.stage1bNode.connect(this.loudnessNode, 0, 1);

        this.loudnessNode.connect(destination);

        this.loudnessNode.update.subscribe(state => this._update.next(state));
    }

    private _update = new Subject<LoudnessState>();

    get update() : Observable<LoudnessState> {
        return this._update;
    }

    determineCoefficients(sampleRate : number): Stage1Coefficients {
        let coefficients = Object.assign({}, STAGE_1_AT_48khz);

        if (sampleRate != SAMPLE_RATE_48k) {
            // See 111222_my_notes_to_the_calculation_of_the_filter_coefficients.tif
            // for the derivations of these equations.
            const K = Math.tan(this.arctanK * SAMPLE_RATE_48k / sampleRate);
            const { Q, VH, VB, VL } = this;

            const commonFactor = 1. / (1. + K/this.Q + K*K);
            coefficients.b0 = (VH + VB*K/Q + VL*K*K)*commonFactor;
            coefficients.b1 = 2.*(VL*K*K - VH)*commonFactor;
            coefficients.b2 = (VH - VB*K/Q + VL*K*K)*commonFactor;
            coefficients.a1 = 2.*(K*K - 1.)*commonFactor;
            coefficients.a2 = (1. - K/Q + K*K)*commonFactor;
        }

        return coefficients;
    }

    initializeCoefficientDerivation() {
        let at48k = STAGE_1_AT_48khz;

        // https://github.com/klangfreund/LUFSMeter
        // Determine the values Q, VH, VB, VL and arctanK.
        // See 111222_my_notes_to_the_calculation_of_the_filter_coefficients.tif
        // for the derivations of these equations.
        let KoverQ = (2. - 2. * at48k.a2) / (at48k.a2 - at48k.a1 + 1.);
        let K = Math.sqrt((at48k.a1 + at48k.a2 + 1.) / (at48k.a2 - at48k.a1 + 1.));
        this.Q = K / KoverQ; 
        this.arctanK = Math.atan(K);
        this.VB = (at48k.b0 - at48k.b2)/(1. - at48k.a2);
        this.VH = (at48k.b0 - at48k.b1 + at48k.b2)/(at48k.a2 - at48k.a1 + 1.);
        this.VL = (at48k.b0 + at48k.b1 + at48k.b2)/(at48k.a1 + at48k.a2 + 1.); 
    }
    
    /** 
     * Filter parameters that are set in the constructor and used in
     * prepareToPlay to calculate the filter coefficients.
     */
    private Q : number;
    private VH : number;
    private VB : number;
    private VL : number;
    private arctanK : number;

    stage1aNode : IIRFilterNode;
    stage1bNode : IIRFilterNode;
    loudnessNode : BS1770LoudnessNode;
}