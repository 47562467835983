// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, ElementRef, HostListener, Output, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'ov-resizer',
    template: `
        
    `,
    styles: [`
        :host {
            width: 10px;
            height: 100%;
            cursor: ew-resize;
            user-select: none;
            flex-shrink: 0;
        }
    `]
})
export class ResizerComponent {
    constructor(
        private element : ElementRef<HTMLElement>) {

    }

    _resized = new BehaviorSubject<number>(100);

    @Output()
    get resized() {
        return this._resized;
    }

    @Input()
    get width() {
        return this._width;
    }
    
    set width(value) {
        this._width = value;
        this._resized.next(value);
    }

    _width : number;

    @Input()
    direction : string;

    @HostListener('mousedown', ['$event'])
    onmousedown(event : MouseEvent) {
        let startPosition = event.pageX;
        let width = this.width;

        let mouseMove = (event : MouseEvent) => {
            let diff = event.pageX - startPosition;
            let newWidth = width - diff;

            this._resized.next(newWidth);
            //this.element.nativeElement.style.width = `${newWidth}px`;
        };

        let mouseUp = (event : MouseEvent) => {
            clearEvents();
        };

        let clearEvents = () => {
            document.removeEventListener('mouseup', mouseUp);
            document.removeEventListener('mousemove', mouseMove);
        }

        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', mouseUp);
    }
}