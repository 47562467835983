// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, ElementRef } from "@angular/core";

@Component({
    selector: 'ov-icon',
    template: `
        
    `,
    styles: [
        `
            :host {
                display: block;
                height: 2em;
            }

            ::ng-deep svg {
                width: auto;
                height: 100%;
            }
            .logo-icon {
                font-size: 8em;
            }
        `
    ]
})
export class OverviewIconComponent {
    constructor(
        elementRef : ElementRef<HTMLElement>
    ) {
        elementRef.nativeElement.innerHTML = `
        <?xml version="1.0" encoding="UTF-8" standalone="no"?>
        <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        sodipodi:docname="icon2.svg"
        inkscape:version="1.0 (4035a4fb49, 2020-05-01)"
        id="svg6094"
        version="1.1"
        viewBox="0 0 67.73333 67.733329"
        height="67.73333mm"
        width="67.73333mm">
        <defs
            id="defs6088" />
        <sodipodi:namedview
            inkscape:document-rotation="0"
            inkscape:window-maximized="1"
            inkscape:window-y="-11"
            inkscape:window-x="3829"
            inkscape:window-height="2066"
            inkscape:window-width="3840"
            fit-margin-bottom="0"
            fit-margin-right="0"
            fit-margin-left="0"
            fit-margin-top="0"
            showgrid="false"
            inkscape:current-layer="layer1"
            inkscape:document-units="mm"
            inkscape:cy="163.84981"
            inkscape:cx="146.76843"
            inkscape:zoom="2.8284271"
            inkscape:pageshadow="2"
            inkscape:pageopacity="0"
            borderopacity="1.0"
            bordercolor="#666666"
            pagecolor="#000000"
            id="base" />
        <metadata
            id="metadata6091">
            <rdf:RDF>
            <cc:Work
                rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                <dc:title></dc:title>
            </cc:Work>
            </rdf:RDF>
        </metadata>
        <g
            transform="translate(451.90832,194.81344)"
            id="layer1"
            inkscape:groupmode="layer"
            inkscape:label="Layer 1">
            <path
            id="path5065-5-0"
            d="m -425.04856,-191.87306 -2.27356,0.69485 c -4.74658,1.4502 -8.71444,3.84429 -12.33178,7.44067 -4.8621,4.83385 -7.42903,9.67902 -8.92561,16.84784 -0.40645,1.94737 -0.33806,8.46922 0.11321,10.80799 1.59509,8.26668 6.31867,15.28815 13.57233,20.17333 3.51392,2.36662 8.14812,4.07417 12.82156,4.72449 2.24364,0.31219 7.86042,0.0851 10.21423,-0.41305 5.9965,-1.26909 10.99814,-4.0108 15.44208,-8.46494 4.48134,-4.4916 7.26929,-9.61962 8.48444,-15.6039 0.67938,-3.34918 0.67635,-9.20242 -0.007,-12.37358 -1.32227,-6.13333 -3.95498,-10.93836 -8.49119,-15.49699 -3.72036,-3.73872 -7.61523,-6.11928 -12.46494,-7.6195 -2.40187,-0.74313 -3.18714,-0.73559 -4.17539,0.0418 -0.0408,0.0321 -0.0834,0.0761 -0.12584,0.11712 a 26.064767,26.064767 0 0 1 19.52999,25.19859 26.064767,26.064767 0 0 1 -26.06492,26.06495 26.064767,26.064767 0 0 1 -26.06498,-26.06495 26.064767,26.064767 0 0 1 21.67139,-25.68985 z"
            style="fill:#0059b1;fill-opacity:1;stroke-width:0.211924"
            inkscape:connector-curvature="0" />
        </g>
        </svg>
        `;
    }
}