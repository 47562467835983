// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Update {
    version : string;
    
    /**
     * The release name.
     */
    releaseName?: string;
    
    /**
     * The release notes. List if `updater.fullChangelog` is set to `true`, `string` otherwise.
     */
    releaseNotes?: string;

    /**
     * The release date.
     */
    releaseDate: string;

    /**
     * The staged rollout percentage, 0-100.
     */
    readonly stagingPercentage?: number;
}

@Injectable()
export class UpdaterService {
    constructor() {
        this.checkForUpdates();
    }

    private _updateAvailable = new BehaviorSubject<Update>(null);

    public getReleaseChannel() : string {
        return 'web';
    }

    get updateAvailable(): Observable<Update> {
        return this._updateAvailable;
    }

    public updateAndRestart() {
    }

    protected markUpdateAvailable(update : Update) {
        this._updateAvailable.next(update);
    }

    protected checkForUpdates() {
    }
}