// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, OnInit, ElementRef, Input, NgZone } from '@angular/core';

declare var window: any;

// more
// http://www.kevs3d.co.uk/dev/snowfield/
// https://github.com/raphamorim/canvas-experiments/blob/gh-pages/assets/js/particles.js
// https://github.com/raphamorim/canvas-experiments/blob/gh-pages/assets/js/inception.js

@Component({
	selector: 'slvr-starfield',
	template: `
		<canvas id="starfield" width="1440" height="834"></canvas>
	`,
	styles: [`
		:host {
			display: block;
		}
		
		canvas {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}	
	`]
})
export class StarfieldComponent implements OnInit {

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private ngZone : NgZone
	) {
		// requestAnimFrame shim
		window.requestAnimFrame = (function () {
			return window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				function (callback) {
					this._fallbackRaf = true;
					window.setTimeout(callback, 32);
				};
		})();

	}

	private _fallbackRaf = false;
	private canvas : HTMLCanvasElement;
	private _speed : number = 0.02;
	private _mouseX : number;
	private _mouseY : number;
	private _width : number;
	private _height : number;
	private context : CanvasRenderingContext2D;

	public set speed(value : number) {
		this._speed = Math.max(0.01, Math.min(0.5, value));
	}

	public get mouseY() {
		return this._mouseY;
	}

	public set mouseY(value) {
		this._mouseY = value;
	}

	public get mouseX() {
		return this._mouseX;
	}

	public set mouseX(value) {
		this._mouseX = value;
	}

	@Input()
	public isVisible : boolean = true;

	@Input()
	public get speed() {
		return this._speed;
	}

	private lowDensityUnits = 500;
	private highDensityUnits = 1000;

	private _units = this.highDensityUnits;
	private _stars = [];
	private _warpZ = 12;
	private _cycle = 0;
	private _destroyed = false;

	ngOnDestroy() {	
		this._destroyed = true;
		document.removeEventListener('resize', this.resizeHandler);
	}

	ngOnInit() {
		this.initializeCanvas();
		this.initializeStars();

		if (typeof window !== 'undefined') {
			if (window.innerWidth * window.devicePixelRatio > 1500) {
				this.ngZone.runOutsideAngular(() => this.startRendering());
			}
		}
	}

	private applyCanvasSize() {
		//console.log(this.canvas.offsetWidth, this.canvas.offsetHeight);
		this.canvas.width = this._width = this.canvas.offsetWidth * this.downsample;
		this.canvas.height = this._height = this.canvas.offsetHeight * this.downsample;
		this._mouseX = this._width / 2;
		this._mouseY = this._height / 2;
		//this.context = this.canvas.getContext("2d");
	}

	private setDownsampling(value : number) {
		if (value == this.downsample)
			return;

		this.downsample = value;
		this.applyCanvasSize();
	}

	private initializeCanvas() {
		let factor = 1;

		this.canvas = <HTMLCanvasElement> this.elementRef.nativeElement.querySelector('canvas');
		this.canvas.style.backgroundColor = 'black';
		this.context = this.canvas.getContext("2d");

		//this.canvas.width = this._width * factor;
		//this.canvas.height = this._height * factor;

		window.addEventListener('resize', this.resizeHandler = () => this.applyCanvasSize());

		this.resizeHandler();
	}

	private resizeHandler : any;

	private initializeStars() {		
		// initial star setup
		for (var i = 0, n; i < this._units; i++) {
			n = {};
			this.resetstar(n);
			this._stars.push(n);
		}
	}

	private startRendering() {
		var rf = () => {
			this.renderFrame();

			if (!this._destroyed)
				window.requestAnimFrame(rf);
		};
		window.requestAnimFrame(rf);
	}

	// function to reset a star object
	private resetstar(a) {
		a.x = (Math.random() * this._width - (this._width * 0.5)) * this._warpZ;
		a.y = (Math.random() * this._height - (this._height * 0.5)) * this._warpZ;
		a.z = this._warpZ;
		a.px = 0;
		a.py = 0;
	}


	lastFrameStart = 0;
	lastFrameEnd = 0;
	frameCount = 0;

	defaultSample = 1;
	lowSample = 0.5;

	private downsample = this.defaultSample;
	private fps30 = false;

	private renderFrame() {
		if (this.canvas.offsetWidth !== this.canvas.width || this.canvas.offsetHeight !== this.canvas.height) {
			this.applyCanvasSize();
		}

		let profiling = false;
		let rendering = true;

		if (this.fps30 && !this._fallbackRaf)
			rendering = this.frameCount % 2 == 0;


		if (rendering && this.frameCount > 0 && this.frameCount % 400) {
			this.lastFrameStart = performance.now()
			profiling = true;
		} else if (this.lastFrameStart >= 0) {
			let now = performance.now();
			let totalTime = now - this.lastFrameStart;
			let renderTime = this.lastFrameEnd - this.lastFrameStart;

			if (renderTime > 12) {
				console.warn(`(!!) starfield: rendered in ${renderTime}ms (${totalTime} f2f) -- too slow, switching to low-perf mode`);
				this.setDownsampling(this.lowSample)
				this._units = this.lowDensityUnits
				this.fps30 = true;
			} else if (renderTime > 5) {
				console.info(`(WW) starfield: rendered in ${renderTime}ms (${totalTime} f2f) -- high render time`);
			}

			this.lastFrameStart = -1;
		}

		this.frameCount++;
		
		if (!this.isVisible)
			return;
		
		if (rendering) {
			this.context.globalAlpha = 0.25 - 0.15 * Math.max(0, (this._speed - 0.01) / 0.5);

			// clear background
			this.context.fillStyle = "#000";
			this.context.fillRect(0, 0, this._width, this._height);
		}

		this.updateStars(rendering);

		if (profiling)
			this.lastFrameEnd = performance.now();
	}

	private updateStars(rendering : boolean) {
		// mouse position to head towards
		var cx = (this._mouseX - this._width / 2) + (this._width / 2),
			cy = (this._mouseY - this._height / 2) + (this._height / 2);

		// update all stars
		var sat = Math.floor(this._speed * 500);       // Z range 0.01 -> 0.5
		if (sat > 100)
			sat = 100;

		for (var i = 0; i < this._units; i++) {
			let star = this._stars[i],            // the star
				xx = star.x / star.z,          // star position
				yy = star.y / star.z,
				e = (1.0 / star.z + 1) * 2;   // size i.e. z

			if (star.px !== 0) {
				// hsl colour from a sine wave

				if (rendering) {
					this.context.strokeStyle = "hsl(" + ((this._cycle * i) % 360) + "," + sat + "%,80%)";
					this.context.lineWidth = e;
					this.context.beginPath();
					this.context.moveTo(xx + cx, yy + cy);
					this.context.lineTo(star.px + cx, star.py + cy);
					this.context.stroke();
				}
			}

			// update star position values with new settings
			star.px = xx;
			star.py = yy;
			star.z -= this._speed;

			// reset when star is out of the view field
			if (star.z < this._speed || star.px > this._width || star.py > this._height) {
				// reset star
				this.resetstar(star);
			}
		}

		// colour cycle sinewave rotation
		this._cycle += 0.01;
	}

}
