// © Copyright Astronaut Labs, LLC. All Rights Reserved.

// ???? after angular 8 less specific exports on the next two lines 
// (or indeed @/overview) do not work here

import { MonitorSource } from '@/shell-common/monitor-source';
import { HlsRenderer } from './hls-renderer';
import { NetworkRequest, NodeNetworkRequest } from '@/renderers/renderer';

/**
 * Fetch based loader
 */
export class HlsNetworkLoader {
    constructor (
      config : Hls.LoaderConfig, 
      private source? : MonitorSource, 
      private renderer? : HlsRenderer
    ) {
        this.abortController = new AbortController();
    }
  
    url : string;
    abortController : AbortController;
  
    destroy() {
      this.abort();
      this.networkRequests = [];
    }
   
    abort() {
      this.abortController.abort();
      this.networkRequests.forEach(r => {
        r.errored('All requests were aborted as the network loader was shut down.');
        this.renderer.networkRequestUpdated.next(r);
      });
      this.networkRequests = [];
    }

    networkRequests : NodeNetworkRequest[] = [];

    async load (context : Hls.LoaderContext, config : Hls.LoaderConfig, callbacks : Hls.LoaderCallbacks) {
      let stats : (Hls.LoaderStats & { retry: number }) = {
        tfirst: undefined,
        tload: undefined,
        loaded: undefined,
        total: undefined,
        bw: undefined,
        trequest: performance.now(),
        retry: 0
      };
   
      let targetURL = context.url;
      let request;
   
      this.url = targetURL;
      const headersObj = {};
   
      if (context.rangeEnd) {
        headersObj['Range'] = 'bytes=' + context.rangeStart + '-' + String(context.rangeEnd - 1);
      }
   
      const initParams : RequestInit = {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
          signal: this.abortController.signal,
          headers: new Headers(headersObj)
      };
   
      request = new Request(context.url, initParams);
   
      let responseData;
      let networkRequest = new NodeNetworkRequest(request);
      networkRequest.started();

      this.networkRequests.push(networkRequest);
      
      try {
          this.renderer.networkRequestUpdated.next(networkRequest);
          let response = await fetch(request);

          networkRequest.response = response;
          
          if (response.ok) {
              stats.tfirst = Math.max(stats.trequest, performance.now());
              targetURL = response.url;
              if (context.responseType === 'arraybuffer') {
                  responseData = await response.arrayBuffer();
              } else {
                  responseData = await response.text();
              }
              
              networkRequest.finished();

              this.renderer.networkRequestFinished(networkRequest);

              this.renderer.networkRequestUpdated.next(networkRequest);
          } else {
            networkRequest.errored(
              `Fetch error: Received ${response.status} ${response.statusText}`
            );
            this.renderer.networkRequestUpdated.next(networkRequest);

            callbacks.onError(
              { 
                code: response.status, 
                text: `fetch: ${response.status} ${response.statusText}` 
              }, 
              context
            );
          }
      } catch (error) {
        callbacks.onError({ code: 0, text: `fetch: generic error: ${error.message}` }, context);
      }
  
      if (!responseData)
          return;
      
      stats.tload = Math.max(stats.tfirst, performance.now());
      let len;
      if (typeof responseData === 'string') {
          len = responseData.length;
      } else {
          len = responseData.byteLength;
      }
  
      stats.loaded = stats.total = len;
      
      networkRequest.finished();
      this.renderer.networkRequestUpdated.next(networkRequest);

      let responseRepr = { url: targetURL, data: responseData };
      callbacks.onSuccess(responseRepr, stats, context);
    }
  }
  