// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { FAQ } from '@astronautlabs/chassis';

export const FAQS : FAQ[] = [
    {
      id: 'iframe-blocked',
      question: 'What websites can I host within Overview\'s Web View (iframe) host?',
      answer: `
        When using the web version of Overview, the Web View (iframe) monitor type has some limitations:
        - (Web Version) If a website explicitly disallows other websites from hosting it as an iframe, your browser will 
          obey that restriction. You may see an error indicating that a website cannot be monitored in an
          iframe in this case.
        - (Web Version) You can only monitor HTTPS URLs

        The desktop version of Overview does not have any of these restrictions.
      `
    },
    {
      id: 'recommended-browsers',
      question: 'What browsers are recommended?',
      answer: `
        We recommend (and fully support) using Overview with Blink-based web browsers such as:
        - Google Chrome
        - Microsoft Edge (Blink)
        - Opera
        - Brave

        In addition to those browsers, we also fully support the following non-Blink browsers:
        - Mozilla Firefox
        - Microsoft Edge (EdgeHTML)
        - Safari (iOS / macOS)
      `
    },
    {
      id: 'mse',
      question: 'Why do I need Media Source Extensions?',
      answer: `
        Your browser must support [_Media Source Extensions (MSE)_](https://en.wikipedia.org/wiki/Media_Source_Extensions) to enable all 
        features of Overview when using the web version. The desktop version of 
        Overview always supports these features.
        
        The following features may be unavailable when MSE support is not present.
        - Some media formats may not play out (HLS, DASH)
        - Inspection and logging of HLS/DASH network requests.
        - Collection of ancillary data such as SCTE35, OATCLS, CUE IN/OUT (HLS/DASH)
      `
    },
    {
      id: 'web-audio',
      question: 'Why do I need support for HTML5 Web Audio?',
      answer: `
        Your browser must support [_HTML5 Web Audio_](https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API) in order
        for Overview to intercept and analyze audio from media played out using 
        HTML5-compatible media sources, including HLS/DASH (via [MSE](https://en.wikipedia.org/wiki/Media_Source_Extensions)).
      `
    }
  ]