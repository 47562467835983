// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { LiveStreamRenderer } from './renderer';
import { MonitorSource } from '@/overview/monitors';

export interface RendererRegistryEntry {
    id? : string;
    name : string;

    /**
     * Renders a source into HTML
     */
    renderer? : { new(element : HTMLElement) : LiveStreamRenderer }
}

export interface RendererType {
    new (element : HTMLElement) : LiveStreamRenderer;
}

export interface RendererInterceptor {
    (entry : RendererRegistryEntry, chosenRendererClass : RendererType, source : MonitorSource) : RendererType;
}

export class RendererFactory {
    private _types : Map<string, RendererRegistryEntry> = new Map<string, RendererRegistryEntry>();

    get all() {
        return Array.from(this._types.values());
    }

    private _interceptors : RendererInterceptor[] = [ ];

    intercept(interceptor : RendererInterceptor) {
        this._interceptors.unshift(interceptor);
    }

    register(id : string, entry : RendererRegistryEntry) {
        entry.id = id;
        this._types.set(id, entry);
    }

    supports(id : string) {
        return !!this.all.find(x => x.id === id);
    }
    
    createRenderer(id : string, element : HTMLElement, source? : MonitorSource) : LiveStreamRenderer {
        let entry = this._types.get(id);

        if (!entry)
            throw new Error(`Cannot resolve monitor of type '${id}'`);

        let type = entry.renderer;
        for (let interceptor of this._interceptors) {
            type = interceptor(entry, type, source);
        }

        return new type(element);
    }
}