// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { NgModule, ErrorHandler } from '@angular/core';
import { ShellRoutingModule } from './shell-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@/material';
import { EntryJumpComponent } from './entry-jump/entry-jump.component';
import { AppShellHostComponent } from './app-shell-host.component';
import { ShellComponent } from './shell.component';
import { AppErrorHandler } from './error-handler.service';
import { ShellCommonModule } from '@/shell-common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '@/environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ChassisModule } from '@astronautlabs/chassis';
import { PRODUCT } from '@/content';
import { MONACO_PATH } from '@rezonant/ngx-monaco-editor';

@NgModule({
  declarations: [
    ShellComponent,
    AppShellHostComponent,
    NotFoundComponent,
    EntryJumpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    MarkdownModule.forRoot(),
    ChassisModule.configure(PRODUCT),

    ShellRoutingModule,
    FormsModule,
    ShellCommonModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    // Needed when running in Electron, probably due to app:// custom scheme
    //{ provide: MONACO_PATH, useValue: `${location.href.replace(/\/index.html.*$/, '')}/assets/monaco-editor/min/vs` }
  ],
  bootstrap: [ShellComponent]
})
export class ShellModule { }
