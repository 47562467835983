// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Sku } from '@astronautlabs/licensing';

export const SKUS : Sku[] = [
    {
        id: 'community',
        rank: 0,

        name: 'Community',
        summary: 'Free tools for all.',
        showInMatrix: true,
        support: `
            Community  
            _Connect with other users on our Forums_
        `,
        price: `
            Free
        `
    },
    {
        id: 'professional',
        rank: 1,

        name: 'Professional',
        summary: 'Swiss army knife for broadcast engineering',
        showInMatrix: true,
        support: `
            Email  
            _9AM-5PM PST, Mon-Fri_  
            _excluding US holidays_
        `,
        price: `
            $40/mo  
            _-or-_  
            $400/yr
        `
    },
    {
        id: 'enterprise',
        rank: 2,

        name: 'Enterprise',
        summary: 'Confidence monitoring for broadcast professionals',
        showInMatrix: true,
        support: `
            Email/Phone  
            _available 24/7_  
            _1 hour response time_
        `,
        price: `
            Contact us
        `
    }
]