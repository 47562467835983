// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable } from '@angular/core';

if (!window['AudioWorkletNode']) {
    window['AudioWorkletNode'] = <any> class { static UNSUPPORTED = true };
}

export function supportsAudioWorklet() {
    return typeof AudioWorkletNode !== 'undefined' && AudioWorkletNode && !AudioWorkletNode['UNSUPPORTED'];
}

// The code in the main global scope.
export class NamedAudioWorkletNode extends AudioWorkletNode {
    constructor(context, name : string) {
        super(context, name);
    }
}

let _nextAudioNodeId = 0;

export class WorkmanAudioNode extends AudioWorkletNode {
    static async create<T extends WorkmanAudioNode>(this : { new(...args : any[]) : T }, context : AudioContext, fn : Function): Promise<T> {
        let baseName = fn.name || 'audio-worklet';
        let name = `${baseName}__${_nextAudioNodeId++}`;

        await Workman.loadAudioWorklet(context, fn);

        let self : { new(...args : any[]) : WorkmanAudioNode } = this;

        class AudioWorkmanNodeInstance extends self {
            constructor(...args : any[]) {
                super(context, name);
            }
        }

        return new AudioWorkmanNodeInstance() as unknown as T;
    }

    protected constructor(context : AudioContext, name : string) {
        super(context, name);
    }
}

export class AudioAnalyzerNode extends WorkmanAudioNode {
    
}

/**
 * - Credit: https://github.com/start-javascript/ngx-web-worker/blob/master/web-worker.ts
 */
export class Workman {
    // tslint:disable-next-line
    private static workerFunctionToUrlMap = new WeakMap<Function, string>();
    private static promiseToWorkerMap = new WeakMap<Promise<any>, Worker>();
    private static _functionToWorkerMap = new WeakMap<Function, Worker>();
    
    public static createWorker(fn: Function): Worker {
        if (!this._functionToWorkerMap.has(fn)) {
            let url = this.resolveUrl(fn);
            let worker = new Worker(url);

            this._functionToWorkerMap.set(fn, worker);
            return worker;
        }

        return this._functionToWorkerMap.get(fn);
    }

    private static _audioWorkletId = 0;

    public static async createAudioWorkletNode(context : AudioContext, fn : Function, name : string) {
        await this.loadAudioWorklet(context, fn);
        return new NamedAudioWorkletNode(context, name);
    }
    
    public static async loadAudioWorklet(context : BaseAudioContext, fn: Function): Promise<void> {
        let id = this._audioWorkletId++;

        await context.audioWorklet.addModule(this.resolveUrl(fn));
    }

    // tslint:disable
    /**
     * <p>Method that allocates a <i>web worker</i> <i>ObjectURL</i> for the given function.
     * It's used to create caches for the <i>(function, workerUrl)</i> pairs in order to avoid
     * creating the urls more than once.</p>
     * @param fn function whose worker we want to allocate.
     */
    public static resolveUrl(fn: Function, transform? : (funcText : string) => string): string {

        if (transform) {
            return this.createWorkerUrl(fn, transform);
        }

        if (!this.workerFunctionToUrlMap.has(fn)) {
            const url = this.createWorkerUrl(fn);
            this.workerFunctionToUrlMap.set(fn, url);
            return url;
        }

        return this.workerFunctionToUrlMap.get(fn);
    }

    /**
     * <p>Method that creates a <i>web worker</i> <i>ObjectURL</i> from the given
     * <i>Function</i> object.</p>
     * @param resolve function the <i>web worker</i> will run.
     */
    private static createWorkerUrl(resolve: Function, transform? : (funcText : string) => string): string {
        const resolveString = resolve.toString();
        let content = `(${resolveString})()`;
        if (transform)
            content = transform(resolveString);

        const blob = new Blob([content], { type: 'text/javascript' });
        return URL.createObjectURL(blob);
    }
}