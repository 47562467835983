// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable, ComponentFactoryResolver, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LicenseRequiredDialogComponent } from '@astronautlabs/chassis';
import { LicensingService } from '@astronautlabs/licensing';

export interface AppStarter {
    appClass : Function;
    injector : Injector;
}

@Injectable()
export class ShellService {
    constructor(
        private matDialog : MatDialog,
        private licensing : LicensingService
    ) {

    }

    private _appStarter : AppStarter;
    private _appStarterChanged = new BehaviorSubject<AppStarter>(null);
    private _focusedItem : any = null;
    private _focusedItemChanged = new BehaviorSubject<any>(null);
    private _backActivated = new Subject<void>();
    private _pageVisibleChanged = new Subject<boolean>();

    private _unlicensed = new BehaviorSubject<boolean>(false);

    private _pageVisible = false;
    get pageVisible() {
        return this._pageVisible;
    }

    set pageVisible(v : boolean) {
        this._pageVisible = v;
        setTimeout(() => this._pageVisibleChanged.next(v));
    }
    get pageVisibleChanged(): Observable<boolean> {
        return this._pageVisibleChanged;
    }

    get unlicensed(): Observable<boolean> {
        return this._unlicensed;
    }

    get backActivated(): Observable<void> {
        return this._backActivated;
    }

    showLicensingDialog() {
        this.licensing.showActivationDialog({
            expired: false
        });
    }

    setUnlicensed() {
        setTimeout(() => this._unlicensed.next(true));
    }

    setLicensed() {
        setTimeout(() => this._unlicensed.next(false));
    }

    back() {
        this._backActivated.next();
    }
    
    set focusedItem(item : any) {
        this._focusedItem = item;
        setTimeout(() => this._focusedItemChanged.next(item));
    }

    get focusedItemChanged() : Observable<any> {
        return this._focusedItemChanged;
    }

    get appStarter() {
        return this._appStarter;
    }

    startApplication(appClass : Function, injector : Injector) {
        this._appStarter = { appClass, injector };
        setTimeout(() => this._appStarterChanged.next(this._appStarter));
    }

    get appStarterChanged() : Observable<AppStarter> {
        return this._appStarterChanged;
    }
}