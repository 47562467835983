// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Workman } from '../workman';
import { Observable, Subject } from 'rxjs';

declare let sampleRate;

export interface LoudnessState {
    shortTermLoudness : number;
    maximumShortTermLoudness : number;
    momentaryLoudness : number;
    momentaryLoudnessForIndividualChannels : number[];
    maximumMomentaryLoudness : number;
    integratedLoudness : number;
    loudnessRangeStart : number;
    loudnessRangeEnd : number;
    loudnessRange : number;
    measurementDuration : number;
    
}

/**
 * Worklet to analyze incoming multi-channel audio and produce an ITU-R BS.1770-2 
 * compliant LUFS (relative LKFS) value.
 * - Using all Float32, original implementation used Float64, investigate
 */
function worklet() {
    const SAMPLE_RATE_48k = 48000;
    const PROCESSOR_ID = 'itu-r-bs1770-2.loudness.processor';
    const LOG_KEY = '[ITU-R BS.1770-2 Loudness]';
    
    function jassert(assertion : () => boolean) {
        if (!assertion())
            throw new Error(`Assertion failed: ${assertion}`);
    }
    /** 
     * If there is no signal at all, the methods getShortTermLoudness() and
     * getMomentaryLoudness() would perform a log10(0) which would result in
     * a value -nan. To avoid this, the return value of this methods will be
     * set to minimalReturnValue.
     */
    const MINIMAL_RETURN_VALUE = -300.0;
    
    /** 
     * A gated window needs to be bigger than this value to
     * contribute to the calculation of the relative threshold.
     *    absoluteThreshold = Gamma_a = -70 LUFS.
     */
    const ABSOLUTE_THRESHOLD : number = -70.0;
    
    class BS1770LoudnessProcessor extends AudioWorkletProcessor {
        constructor() {
            super({
                numberOfInputs: 2
            });
        }

        numberOfChannels : number = 0;
        bufferSize = 128; // was 2048?

        sendUpdate() {

            
            // calculate the momentary loudness

            for (let k = 0; k != this.momentaryLoudnessForIndividualChannels.length; ++k) {
                let kthChannelMomentaryLoudness = MINIMAL_RETURN_VALUE;
                
                if (this.averageOfTheLast400ms[k] > 0.0) {
                    // This refers to equation (2) in ITU-R BS.1770-2
                    kthChannelMomentaryLoudness = Math.max (-0.691 + 10. * Math.log10(this.averageOfTheLast400ms[k]), MINIMAL_RETURN_VALUE);
                }

                this.momentaryLoudnessForIndividualChannels[k] = kthChannelMomentaryLoudness;
            }

            this.port.postMessage({
                type: 'update',
                state: <LoudnessState>{
                    integratedLoudness: this.integratedLoudness,
                    loudnessRange: this.loudnessRangeEnd - this.loudnessRangeStart,
                    loudnessRangeEnd: this.loudnessRangeEnd,
                    loudnessRangeStart: this.loudnessRangeStart,
                    measurementDuration: this.measurementDuration * 0.1,
                    maximumMomentaryLoudness: this.maximumMomentaryLoudness,
                    maximumShortTermLoudness: this.maximumShortTermLoudness,
                    shortTermLoudness: this.shortTermLoudness,
                    momentaryLoudness: this.momentaryLoudness,
                    momentaryLoudnessForIndividualChannels: this.momentaryLoudnessForIndividualChannels
                }
            })
        }

        prepareToPlay(numberOfInputChannels : number, estimatedSamplesPerBlock : number, expectedRequestRate : number) {
            this.bufferForMeasurement = [];

            this.numberOfChannels = numberOfInputChannels;

            for (let i = 0, max = numberOfInputChannels; i < max; ++i) {
                this.bufferForMeasurement.push(new Float32Array(this.bufferSize));
            }

            if (expectedRequestRate < 10)
                expectedRequestRate = 10;
            else
                expectedRequestRate = (Math.floor((expectedRequestRate-1) / 10) + 1) * 10;
            
            // It also needs to be a divisor of the samplerate for accurate
            // M and S values (the integrated loudness (I value) would not be
            // affected by this inaccuracy.

            while (Math.floor(sampleRate) % expectedRequestRate != 0) {
                expectedRequestRate += 10;
                
                if (expectedRequestRate > sampleRate/2) {
                    expectedRequestRate = 10;
                    console.error(
                        `${LOG_KEY} Not possible to make expectedRequestRate a ` + 
                        `multiple of 10 and a divisor of the samplerate.`
                    );
                    break;
                }
            }

            //console.info(`${LOG_KEY} expectedRequestRate = ${expectedRequestRate}`);

            // Figure out how many bins are needed.

            const timeOfAccumulationForShortTerm = 3; // seconds.
            //Needed for the short term loudness measurement.
            this.numberOfBins = expectedRequestRate * timeOfAccumulationForShortTerm;
            this.numberOfSamplesPerBin = Math.floor(sampleRate / expectedRequestRate);
            this.numberOfSamplesInAllBins = this.numberOfBins * this.numberOfSamplesPerBin;

            this.numberOfBinsToCover100ms = Math.floor(0.1 * expectedRequestRate);

            //console.debug(`${LOG_KEY} numberOfBinsToCover100ms = ${this.numberOfBinsToCover100ms}`);

            this.numberOfBinsToCover400ms = Math.floor(0.4 * expectedRequestRate);
            //console.debug(`${LOG_KEY} numberOfBinsToCover400ms = ${this.numberOfBinsToCover400ms}`);

            this.numberOfSamplesIn400ms = this.numberOfBinsToCover400ms * this.numberOfSamplesPerBin;

            this.currentBin = 0;
            this.numberOfSamplesInTheCurrentBin = 0;
            this.numberOfBinsSinceLastGateMeasurementForI = 1;
            // this.millisecondsSinceLastGateMeasurementForLRA = 0;
            this.measurementDuration = 0;

            // Initialize the bins.
            
            this.bin = [];
            for (let i = 0, max = numberOfInputChannels; i < max; ++i) {
                let binItem = new Float32Array(this.numberOfBins);
                binItem.fill(0.0);
                this.bin.push(binItem);
            }

            this.averageOfTheLast3s = new Float32Array(numberOfInputChannels);
            this.averageOfTheLast3s.fill(0.0);
            
            this.averageOfTheLast400ms = new Float32Array(numberOfInputChannels);
            this.averageOfTheLast400ms.fill(0.0);

            // Initialize the channel weighting.
            this.channelWeighting = [];
            
            for (let k = 0; k != numberOfInputChannels; ++k)
            {
                if (k == 3 || k == 4)
                    // The left and right surround channels have a higher weight
                    // because they seem louder to the human ear.
                    this.channelWeighting.push(1.41);
                else
                    this.channelWeighting.push(1.0);
            }

            // Momentary loudness for the individual channels.
            
            this.momentaryLoudnessForIndividualChannels = [];
            for (let i = 0, max = numberOfInputChannels; i < max; ++i)
                this.momentaryLoudnessForIndividualChannels.push(MINIMAL_RETURN_VALUE);

            this.reset();
        }

        private bufferForMeasurement : Float32Array[];
        private numberOfBins : number = 0;
        private numberOfSamplesPerBin : number = 0;
        private numberOfSamplesInAllBins : number = 0;
        private numberOfBinsToCover400ms : number = 0;
        private numberOfSamplesIn400ms : number = 0;
        private numberOfBinsToCover100ms : number = 0;
        private numberOfBinsSinceLastGateMeasurementForI : number = 1;

        // private millisecondsSinceLastGateMeasurementForLRA = 0;
        
        /** 
         * The duration of the current measurement. 
         *   duration * 0.1 = the measurement duration in seconds.
         */
        private measurementDuration : number = 0;
        
        /**
         After the samples are filtered and squared, they need to be
         accumulated.
         For the measurement of the short-term loudness, the previous
         3 seconds of samples need to be accumulated. For the other
         measurements shorter windows are used.
         
         This task could be solved using a ring buffer capable of 
         holding 3 seconds of (multi-channel) audio and accumulate the
         samples every time the GUI wants to display the measurement.
         
         But it's easier on the CPU and needs far less memory if not
         all samples are stored but only the summation of all the
         samples in a 1/(GUI refresh rate) s - e.g. 1/20 s - time window.
         If we then need to determine the sum of all samples of the previous
         3s, we only have to accumulate 60 values.
         The downside of this method is that the displayed measurement
         has an accuracy jitter of max. e.g. 1/20 s. I guess this isn't
         an issue since GUI elements are refreshed asynchron anyway.
         Just to be clear, the accuracy of the measurement isn't effected.
         But if you ask for a measurement at time t, it will be the
         accurate measurement at time t - dt, where dt \in e.g. [0, 1/20s].
         */
        bin : Float32Array[] = []; // TODO: possibly use TypedArray
        
        private currentBin : number;
        private numberOfSamplesInTheCurrentBin : number;
        
        /*
         The average of the filtered and squared samples of the last
         3 seconds.
         A value for each channel.
         */
        private averageOfTheLast3s : Float32Array;
        
        /*
         The average of the filtered and squared samples of the last
         400 milliseconds.
         A value for each channel.
         */
        private averageOfTheLast400ms : Float32Array;
        private channelWeighting : number[] = []; // TODO: possibly Float32Array
        
        /**
         * NOTE: specifically 32-bit float in original
         */
        private momentaryLoudnessForIndividualChannels : number[] = []; // TODO: possibly Float32Array
        
        private numberOfBlocksToCalculateRelativeThreshold : number = 0;
        private sumOfAllBlocksToCalculateRelativeThreshold : number = 0.0;
        private relativeThreshold : number = ABSOLUTE_THRESHOLD;
        private numberOfBlocksToCalculateRelativeThresholdLRA : number = 0;
        private sumOfAllBlocksToCalculateRelativeThresholdLRA : number = 0.0;
        private relativeThresholdLRA : number = ABSOLUTE_THRESHOLD;
        
        /** A lower bound for the histograms (for I and LRA).
            If a measured block has a value lower than this, it will not be
            considered in the calculation for I and LRA.
            Without the possibility to increase the pre-measurement-gain at any
            point after the measurement has started, this could have been set
            to the absoluteThreshold = -70 LUFS.
         */
        lowestBlockLoudnessToConsider : number = -100; // TODO: -100
        
        /** Storage for the loudnesses of all 400ms blocks since the last reset.
         
         Because the relative threshold varies and all blocks with a loudness
         bigger than the relative threshold are needed to calculate the gated
         loudness (integrated loudness), it is mandatory to keep track of all
         block loudnesses.
         
         Adjacent bins are set apart by 0.1 LU which seems to be sufficient.
         
         Key value = Loudness * 10 (to get an integer value).
         */
        private histogramOfBlockLoudness = new Map<number,number>();
        
        /** The main loudness value of interest. */
        private integratedLoudness: number = MINIMAL_RETURN_VALUE;
        private shortTermLoudness: number = MINIMAL_RETURN_VALUE;
        private maximumShortTermLoudness: number = MINIMAL_RETURN_VALUE;
        private momentaryLoudness: number = MINIMAL_RETURN_VALUE;
        private maximumMomentaryLoudness: number = MINIMAL_RETURN_VALUE;
        
        /** Like histogramOfBlockLoudness, but for the measurement of the
         loudness range.
         
         The histogramOfBlockLoudness can't be used simultaneous for the
         loudness range, because the measurement blocks for the loudness
         range need to be of length 3s. Vs 400ms.
         */
        private histogramOfBlockLoudnessLRA = new Map<number,number>();
        
        /**
         The return values for the corresponding get member functions.
         
         Loudness Range = loudnessRangeEnd - loudnessRangeStart.
         */
        private loudnessRangeStart: number = MINIMAL_RETURN_VALUE;
        private loudnessRangeEnd: number = MINIMAL_RETURN_VALUE;

        private freezeLoudnessRangeOnSilence: boolean = false;
        private currentBlockIsSilent: boolean = false;

        private round (d : number)
        {
            // For a negative d, int (d) will choose the next higher number,
            // therfore the - 0.5.
            return (d > 0.0) ? Math.floor(d + 0.5) : Math.floor(d - 0.5);
        }

        private reset() {
            for (let binItem of this.bin)
                binItem.fill(0.0);

            // To ensure the returned momentary and short term loudness are at its 
            // minimum, even if no audio is processed at the moment.

            this.averageOfTheLast3s.fill(0.0);
            this.averageOfTheLast400ms.fill(0.0);
            
            this.measurementDuration = 0;
            
            // momentary loudness for the individual tracks.
            this.momentaryLoudnessForIndividualChannels.fill(MINIMAL_RETURN_VALUE);
            
            // Integrated loudness
            this.numberOfBinsSinceLastGateMeasurementForI = 1;
            this.numberOfBlocksToCalculateRelativeThreshold = 0;
            this.sumOfAllBlocksToCalculateRelativeThreshold = 0.0;
            this.relativeThreshold = ABSOLUTE_THRESHOLD;
            
            this.histogramOfBlockLoudness.clear();
            
            this.integratedLoudness = MINIMAL_RETURN_VALUE;
            
            
            // Loudness range
            this.numberOfBlocksToCalculateRelativeThresholdLRA = 0;
            this.sumOfAllBlocksToCalculateRelativeThresholdLRA = 0.0;
            this.relativeThresholdLRA = ABSOLUTE_THRESHOLD;
            this.histogramOfBlockLoudnessLRA.clear();
            
            this.loudnessRangeStart = MINIMAL_RETURN_VALUE;
            this.loudnessRangeEnd = MINIMAL_RETURN_VALUE;

            // Short term loudness
            this.shortTermLoudness = MINIMAL_RETURN_VALUE;
            this.maximumShortTermLoudness = MINIMAL_RETURN_VALUE;

            // Momentary loudness
            this.momentaryLoudness = MINIMAL_RETURN_VALUE;
            this.maximumMomentaryLoudness = MINIMAL_RETURN_VALUE;
        }

        getMagnitude(buffer : Float32Array) {
            let max = 0;
            for (let i = 0, max = buffer.length; i < max; ++i) {
                let sample = Math.abs(buffer[i]);
                if (sample > max)
                    max = sample;
            }

            return max;
        }

        lastUpdated = 0;
        updateRate = (1000.0/60);

        process(
            inputs : Float32Array[][], 
            outputs : Float32Array[][], 
            parameters
        ) {
            this.realProcess(inputs, outputs, parameters);

            let now = Date.now();

            if (this.lastUpdated + this.updateRate < now) {
                this.lastUpdated = now;
                this.sendUpdate();
            }

            return true;
        }

        realProcess(
            inputs : Float32Array[][], 
            outputs : Float32Array[][], 
            parameters
        ) {
            let rawInput = inputs[0]; // RAW
            let filteredInput = inputs[1]; // FILTERED by STAGED-1

            if (this.numberOfChannels !== filteredInput.length) {
                //console.log(`Preparing to play (loudness)...`);
                this.prepareToPlay(filteredInput.length, 512, 20);
            }

            // -------------------------------------------------------------------
            
            this.bufferForMeasurement = filteredInput;
            
            if (this.freezeLoudnessRangeOnSilence) {
                // Detect if the block is silent.
                // ------------------------------
                const silenceThreshold = Math.pow (10, 0.1 * -120);
                    // -120dB -> approx. 1.0e-12

                // buffer.getMagnitude is get max sample value (liam)
                // the zero is channel, so this only checks the left channel for silence...

                const magnitude : number = this.getMagnitude(rawInput[0]);
                if (magnitude < silenceThreshold) {
                    this.currentBlockIsSilent = true;
                    console.debug (`${LOG_KEY} Silence detected.`);
                } else {
                    this.currentBlockIsSilent = false;
                }
            }
                                
            
            // STEP 1: K-weighted filter.
            
            // // Apply the pre-filter.
            // // Used to account for the acoustic effects of the head.
            // // This is the first part of the so called K-weighted filtering.
            // preFilter.processBlock (bufferForMeasurement);
            
            // // Apply the RLB filter (a simple highpass filter).
            // // This is the second part of the so called K-weighted filtering.
            // // Its name is in accordance to ITU-R BS.1770-2
            // // (In ITU-R BS.1770-3 it's called 'a simple highpass filter').
            // revisedLowFrequencyBCurveFilter.processBlock (bufferForMeasurement);
            
            // TEMP
            // Copy back the buffer to listen to the filtered audio.
            //   buffer = bufferForMeasurement;
            // END TEMP
            
            // STEP 2: Mean square.
            // --------------------
            for (let k = 0; k != this.bufferForMeasurement.length; ++k)
            {
                let theKthChannelData = this.bufferForMeasurement[k];
                
                for (let i = 0; i != this.bufferSize; ++i)
                    theKthChannelData[i] = theKthChannelData[i] * theKthChannelData[i];
            }

            // Intermezzo: Set the number of channels.
            // ---------------------------------------
            // To prevent EXC_BAD_ACCESS when the number of channels in the buffer
            // suddenly changes without calling prepareToPlay() in advance.
            const numberOfChannels = Math.min (
                this.bufferForMeasurement.length,
                this.bin.length,
                this.averageOfTheLast400ms.length,
                Math.min(
                    this.averageOfTheLast3s.length,
                    this.channelWeighting.length
                )
            );

            jassert (() => this.bufferForMeasurement.length === this.bin.length);
            jassert (() => this.bufferForMeasurement.length === this.averageOfTheLast400ms.length);
            jassert (() => this.bufferForMeasurement.length === this.averageOfTheLast3s.length);
            jassert (() => this.bufferForMeasurement.length === this.channelWeighting.length);
            
            // STEP 3: Accumulate the samples and put the sum(s) into the right bin(s).
            // ------------------------------------------------------------------------
            
            // If the new samples from the bufferForMeasurement can all be added
            // to the same bin.
            if (this.numberOfSamplesInTheCurrentBin + this.bufferSize < this.numberOfSamplesPerBin)
            {
                for (let k = 0; k != numberOfChannels; ++k) {
                    let bufferOfChannelK = this.bufferForMeasurement[k];

                    let theBinToSumTo = this.bin[k][this.currentBin] || 0;
                    
                    for (let i = 0, max = this.bufferSize; i < max; ++i)
                        theBinToSumTo += bufferOfChannelK[i];
                    
                    this.bin[k][this.currentBin] = theBinToSumTo;
                }
                
                this.numberOfSamplesInTheCurrentBin += this.bufferSize;
            }
            
            // If the new samples are split up between two (or more (which would be a
            // strange setup)) bins.
            else        
            {
                let positionInBuffer = 0;
                let bufferStillContainsSamples = true;
                
                while (bufferStillContainsSamples) {
                    // Figure out if the remaining samples in the buffer can all be
                    // accumulated to the current bin.
                    const numberOfSamplesLeftInTheBuffer : number = this.bufferSize-positionInBuffer;
                    let numberOfSamplesToPutIntoTheCurrentBin : number;
                    
                    if (numberOfSamplesLeftInTheBuffer < this.numberOfSamplesPerBin - this.numberOfSamplesInTheCurrentBin) {
                        // Case 1: Partially fill a bin (by using all the samples left in the buffer).
                        // ---------------------------------------------------------------------------
                        // If all the samples from the buffer can be added to the
                        // current bin.
                        numberOfSamplesToPutIntoTheCurrentBin = numberOfSamplesLeftInTheBuffer;
                        bufferStillContainsSamples = false;
                    } else {
                        // Case 2: Completely fill a bin (most likely the buffer will still contain some samples for the next bin).
                        // --------------------------------------------------------------------------------------------------------
                        // Accumulate samples to the current bin until it is full.
                        numberOfSamplesToPutIntoTheCurrentBin = this.numberOfSamplesPerBin - this.numberOfSamplesInTheCurrentBin;
                    }
                    
                    // Add the samples to the bin.
                    for (let k = 0; k != numberOfChannels; ++k) {
                        let bufferOfChannelK = this.bufferForMeasurement[k];
                        let theBinToSumTo : number = this.bin[k][this.currentBin];

                        for (let i = positionInBuffer, max = positionInBuffer + numberOfSamplesToPutIntoTheCurrentBin; i < max; ++i) {
                            theBinToSumTo += bufferOfChannelK[i];
                        }
                    }
                    
                    this.numberOfSamplesInTheCurrentBin += numberOfSamplesToPutIntoTheCurrentBin;
                    
                    // If there are some samples left in the buffer
                    // => A bin has just been completely filled (case 2 above).
                    if (bufferStillContainsSamples)
                    {
                        positionInBuffer = positionInBuffer
                                        + numberOfSamplesToPutIntoTheCurrentBin;
                        
                        // We have completely filled a bin.
                        // This is the moment the larger sums need to be updated.
                        for (let k = 0; k != numberOfChannels; ++k) {
                            let sumOfAllBins = 0.0;
                                // which covers the last 3s.
                            
                            for (let b = 0; b != this.numberOfBins; ++b)
                                sumOfAllBins += this.bin[k][b];

                            this.averageOfTheLast3s[k] = sumOfAllBins / this.numberOfSamplesInAllBins;

                            // Short term loudness
                            // ===================
                            {
                                let weightedSum : number = 0.0;

                                for (let k = 0; k != numberOfChannels; ++k)
                                    weightedSum += this.channelWeighting[k] * this.averageOfTheLast3s[k];
                                
                                if (weightedSum > 0.0)
                                    // This refers to equation (2) in ITU-R BS.1770-2
                                    this.shortTermLoudness = Math.max (-0.691 + 10.* Math.log10(weightedSum), MINIMAL_RETURN_VALUE);
                                else
                                    // Since returning a value of -nan most probably would lead to
                                    // a malfunction, return the minimal return value.
                                    this.shortTermLoudness = MINIMAL_RETURN_VALUE;

                                // Maximum
                                if (this.shortTermLoudness > this.maximumShortTermLoudness)
                                    this.maximumShortTermLoudness = this.shortTermLoudness;
                            }
                            
                            let sumOfBinsToCoverTheLast400ms = 0.0;

                            for (let d = 0; d != this.numberOfBinsToCover400ms; ++d)
                            {
                                // The index for the bin.
                                let b = this.currentBin - d;
                                    // this might be negative right now.
                                let n = this.numberOfBins;
                                b = (b % n + n) % n;
                                    // b = b mod n (in the mathematical sense).
                                    // Not negative anymore.
                                    //
                                    // Now 0 <= b < numberOfBins.
                                    // Example: b=-5, n=30
                                    //  b%n = -5
                                    //  (b%n +n)%n = 25%30 = 25
                                    //
                                    // Example: b=16, n=30
                                    //  b%n = 16
                                    //  (b%n +n)%n = 46%30 = 16
                                
                                sumOfBinsToCoverTheLast400ms += this.bin[k][b];
                            }

                            this.averageOfTheLast400ms[k] = sumOfBinsToCoverTheLast400ms / this.numberOfSamplesIn400ms;

                            // Momentary loudness
                            // ==================
                            {
                                let weightedSum = 0.0;

                                for (let k = 0, max = this.averageOfTheLast400ms.length; k < max; ++k)
                                    weightedSum += this.channelWeighting[k] * this.averageOfTheLast400ms[k];

                                if (weightedSum > 0.0)
                                    // This refers to equation (2) in ITU-R BS.1770-2
                                    this.momentaryLoudness = Math.max(-0.691 + 10. * Math.log10(weightedSum), MINIMAL_RETURN_VALUE);
                                else
                                    // Since returning a value of -nan most probably would lead to
                                    // a malfunction, return a minimal return value.
                                    this.momentaryLoudness = MINIMAL_RETURN_VALUE;

                                // Maximum
                                if (this.momentaryLoudness > this.maximumMomentaryLoudness)
                                    this.maximumMomentaryLoudness = this.momentaryLoudness;
                            }
                        }

                        // INTEGRATED LOUDNESS
                        // ===================
                        // For the integrated loudness measurement we have to observe a
                        // gating window of length 400ms every 100ms.
                        // We call this window 'gating block', according to BS.1770-3
                        if (this.numberOfBinsSinceLastGateMeasurementForI != this.numberOfBinsToCover100ms)
                            ++this.numberOfBinsSinceLastGateMeasurementForI;
                        else
                        {
                            //console.log('100ms I update');

                            // Every 100ms this section is reached.

                            // The next time the condition above is checked, one bin has already been filled.
                            // Therefore this is set to 1 (and not to 0).
                            this.numberOfBinsSinceLastGateMeasurementForI = 1;
                            
                            ++this.measurementDuration;
                            
                            // Figure out if the current 400ms gated window (loudnessOfCurrentBlock =) l_j > /Gamma_a
                            // ( see ITU-R BS.1770-3 equation (4) ).
                            
                            // Calculate the weighted sum of the current block,
                            // (in 120725_integrated_loudness_revisited.tif, I call
                            // this s_j)
                            let weightedSumOfCurrentBlock = 0.0;

                            for (let k = 0; k != numberOfChannels; ++k)
                            {
                                weightedSumOfCurrentBlock += this.channelWeighting[k] * this.averageOfTheLast400ms[k];
                            }
                            
                            // Calculate the j'th gating block loudness l_j
                            const loudnessOfCurrentBlock = -0.691 + 10. * Math.log10(weightedSumOfCurrentBlock);
                            
                            if (loudnessOfCurrentBlock > ABSOLUTE_THRESHOLD)
                            {
                                // Recalculate the relative threshold.
                                // -----------------------------------
                                ++this.numberOfBlocksToCalculateRelativeThreshold;
                                this.sumOfAllBlocksToCalculateRelativeThreshold += weightedSumOfCurrentBlock;
                                
                                // According to the definition of the relative
                                // threshold in ITU-R BS.1770-3, page 6.
                                this.relativeThreshold = -10.691 + 10.0 * Math.log10(
                                    this.sumOfAllBlocksToCalculateRelativeThreshold / this.numberOfBlocksToCalculateRelativeThreshold
                                );
                            }
                            
                            // Add the loudness of the current block to the histogram
                            if (loudnessOfCurrentBlock > this.lowestBlockLoudnessToConsider)
                            {
                                let key = this.round(loudnessOfCurrentBlock * 10.0);
                                let value = this.histogramOfBlockLoudness.get(key) || 0;
                                //console.log(`setting [${key}] = ${value + 1}`);

                                this.histogramOfBlockLoudness.set(key, value + 1);
                                // With the + 0.5 the value is rounded to the closest bin.
                                // With + 0.5: -22.26 ->
                            }
                            
                            
                            // Determine the integrated loudness.
                            // ----------------------------------
                            //
                            // It's here instead inside of the getIntegratedLoudness() function
                            // because here it's only calculated 10 times a second.
                            // getIntegratedLoudness() is called at the refreshrate of the GUI,
                            // which is higher (e.g. 20 times a second).

                            //console.log(`loudness histogram size: ${this.histogramOfBlockLoudness.size}`);
                            if (this.histogramOfBlockLoudness.size > 0)
                            {
                                let biggestEntryInHistogram = 
                                    Array
                                        .from(this.histogramOfBlockLoudness.entries())
                                        .reduce((pv : [ number, number ], cv) => !pv || pv[0] < cv[0] ? cv : pv);
                                ;

                                const biggestLoudnessInHistogram = biggestEntryInHistogram[0] * 0.1;
                                // DEB ("biggestLoudnessInHistogram = " + String(biggestLoudnessInHistogram))
                                //console.log(`rel: ${this.relativeThreshold} < ${biggestLoudnessInHistogram}`);
                                
                                if (this.relativeThreshold < biggestLoudnessInHistogram){
                                    let closestBinAboveRelativeThresholdKey = Math.floor(this.relativeThreshold * 10.0);
                                    while (!this.histogramOfBlockLoudness.has (closestBinAboveRelativeThresholdKey))
                                    {
                                        closestBinAboveRelativeThresholdKey++; // Go 0.1 LU higher
                                    }
                                    
                                    //debugger;

                                    let nrOfAllBlocks = 0;
                                    let sumForIntegratedLoudness = 0.0;
                                    let sortedKeys = Array.from(this.histogramOfBlockLoudness.keys()).sort();

                                    for (let key of sortedKeys) {
                                        if (key < closestBinAboveRelativeThresholdKey)
                                            continue;
                                        
                                        const nrOfBlocksInBin = this.histogramOfBlockLoudness.get(key) || 0;
                                        nrOfAllBlocks += nrOfBlocksInBin;

                                        const weightedSumOfCurrentBin = Math.pow (10.0, (key * 0.1 + 0.691) * 0.1);
                                        sumForIntegratedLoudness += nrOfBlocksInBin * weightedSumOfCurrentBin;
                                    }

                                    if (nrOfAllBlocks > 0) // nrOfAllBlocks > 0  =>  sumForIntegratedLoudness > 0.0
                                    {
                                        this.integratedLoudness = -0.691 + 10. * Math.log10 (sumForIntegratedLoudness / nrOfAllBlocks);
                                    }
                                    else
                                    {
                                        this.integratedLoudness = MINIMAL_RETURN_VALUE;
                                    }
                                }
                            }
                            
                            
                            // Loudness range
                            // ==============
                            // According to the specification, at least every 1000ms
                            // a new 3s long LRA block needs to be started.
                            //
                            // Here, an interval of 100ms is used.
                            // This makes measurement results equal (or very similar)
                            // to ffmpeg/ebur128 and Nugen VisLM2.

                            // if (millisecondsSinceLastGateMeasurementForLRA != 500)
                            //     millisecondsSinceLastGateMeasurementForLRA += 100;
                            // else
                            {
                                // Every second this section is reached.
                                // This results in an overlap of the 3s gates of exactly
                                // 2/3, the minimum requirement.

                            //    millisecondsSinceLastGateMeasurementForLRA = 100;
                                
                            
                                // This is very similar to the above code for the integrated loudness.
                                // (But distinct enough to not put it into a single function/object.)
                                
                                // Calculate the weighted sum of the current block,
                                // (in 120725_integrated_loudness_revisited.tif, I call
                                // this s_j)
                                // Using an analysis-window of 3 seconds, as specified in
                                // EBU 3342-2011.
                                let weightedSumOfCurrentBlockLRA = 0.0;
                                
                                for (let k = 0; k != numberOfChannels; ++k)
                                {
                                    weightedSumOfCurrentBlockLRA += this.channelWeighting[k] * this.averageOfTheLast3s[k];
                                }
                                
                                // Calculate the j'th gating block loudness l_j
                                const loudnessOfCurrentBlockLRA = -0.691 + 10.0 * Math.log10(weightedSumOfCurrentBlockLRA);
                                
                                if (loudnessOfCurrentBlockLRA > ABSOLUTE_THRESHOLD)
                                {
                                    // Recalculate the relative threshold for LRA
                                    // ------------------------------------------
                                    ++this.numberOfBlocksToCalculateRelativeThresholdLRA;
                                    this.sumOfAllBlocksToCalculateRelativeThresholdLRA += weightedSumOfCurrentBlockLRA;
                                    
                                    // According to the definition of the relative
                                    // threshold in ITU-R BS.1770-3, page 6.
                                    // -20 LU as described in EBU 3342-2011.
                                    this.relativeThresholdLRA = -20.691 + 10.0 * Math.log10(
                                        this.sumOfAllBlocksToCalculateRelativeThresholdLRA 
                                            / this.numberOfBlocksToCalculateRelativeThresholdLRA
                                    );
                                }
                                
                                // Add the loudness of the current block to the histogram
                                if (loudnessOfCurrentBlockLRA > this.lowestBlockLoudnessToConsider) {
                                    let key = this.round (loudnessOfCurrentBlockLRA * 10.0);
                                    let value = this.histogramOfBlockLoudnessLRA.get(key) || 0; // TODO: fallback to zero correct?
                                    this.histogramOfBlockLoudnessLRA.set(key, value + 1);
                                }
                                
                                // Determine the loudness range.
                                // -----------------------------
                                //
                                // It's here instead inside of the getter functions
                                // because here it's only calculated once a second.
                                // The getter functions are called at the refreshrate of the GUI,
                                // which is higher (e.g. 20 times a second).
                                
                                if (this.histogramOfBlockLoudnessLRA.size > 0) {
                                    let biggestEntryInHistogram = 
                                        Array
                                            .from(this.histogramOfBlockLoudnessLRA.entries())
                                            .reduce((pv : [ number, number ], cv) => !pv || pv[0] < cv[0] ? cv : pv);
                                    ;
    
                                    const biggestLoudnessInHistogramLRA = biggestEntryInHistogram[0] * 0.1;
                                    // DEB ("biggestLoudnessInHistogramLRA = " + String(biggestLoudnessInHistogramLRA))
                                    if (this.relativeThresholdLRA < biggestLoudnessInHistogramLRA)
                                    {
                                        let closestBinAboveRelativeThresholdKeyLRA = Math.floor(this.relativeThresholdLRA * 10.0);
                                     
                                        while (!this.histogramOfBlockLoudness.has (closestBinAboveRelativeThresholdKeyLRA))
                                            closestBinAboveRelativeThresholdKeyLRA++; // Go 0.1 LU higher

                                        // Figure out the number of blocks above the relativeThresholdLRA
                                        // --------------------------------------------------------------
                                        let sortedKeys = Array.from(this.histogramOfBlockLoudnessLRA.keys()).sort();

                                        let numberOfBlocksLRA = 0;
                                        let firstKey : number = undefined;
                                        for (let key of sortedKeys) {
                                            if (key < closestBinAboveRelativeThresholdKeyLRA)
                                                continue;
                                            
                                            if (firstKey === undefined)
                                                firstKey = key;

                                            numberOfBlocksLRA += this.histogramOfBlockLoudnessLRA.get(key) || 0;
                                        }

                                        // Figure out the lower bound (start) of the loudness range.
                                        // ---------------------------------------------------------

                                        let startBinLRA = firstKey;
                                        let numberOfBlocksBelowStartBinLRA = this.histogramOfBlockLoudnessLRA.get(startBinLRA) || 0;

                                        let keys = Array.from(this.histogramOfBlockLoudnessLRA.keys());
                                        let lastKey = keys[keys.length - 1];
                                        let lowestKey = keys[0];

                                        while (numberOfBlocksBelowStartBinLRA < 0.10 * numberOfBlocksLRA && startBinLRA <= lastKey) {
                                            let blocksInBin = this.histogramOfBlockLoudnessLRA.get(++startBinLRA);
                                            numberOfBlocksBelowStartBinLRA += blocksInBin;
                                        }
                                    
                                        // DEB("numberOfBlocks = " + String (numberOfBlocksLRA))
                                        // DEB("numberOfBlocksBelowStartBinLRA = " + String(numberOfBlocksBelowStartBinLRA))
                                        
        //                                ++startBinLRA;
                                    
                                        if (!(this.freezeLoudnessRangeOnSilence && this.currentBlockIsSilent))
                                            this.loudnessRangeStart = startBinLRA * 0.1;
                                            // DEB("LRA starts at " + String (loudnessRangeStart))
                                        // Else:
                                        // Holding the loudnessRangeStart on silence
                                        // helps reading it after the end of an audio
                                        // region or if the DAW has just been stopped.
                                        // The measurement does not get interrupted by
                                        // this! It's only a temporary freeze.

                                        // Figure out the upper bound (end) of the loudness range.
                                        // -------------------------------------------------------
                                        let endBinLRA = sortedKeys[sortedKeys.length - 1];
                                        let numberOfBlocksAboveEndBinLRA = this.histogramOfBlockLoudnessLRA.get(endBinLRA) || 0;
                                    
                                        while (numberOfBlocksAboveEndBinLRA < 0.05 * numberOfBlocksLRA && endBinLRA >= lowestKey)
                                            numberOfBlocksAboveEndBinLRA += this.histogramOfBlockLoudnessLRA.get(--endBinLRA) || 0;
                                    
                                        if (!(this.freezeLoudnessRangeOnSilence && this.currentBlockIsSilent))
                                            this.loudnessRangeEnd = endBinLRA * 0.1;
                                            // DEB("LRA ends at " + String (loudnessRangeEnd))
                                        // Else:
                                        // Holding the loudnessRangeEnd on silence
                                        // helps reading it after the end of an audio
                                        // region or if the DAW has just been stopped.
                                        // The measurement does not get interrupted by
                                        // this! It's only a temporary freeze.
                                        
                                        // DEB("LRA = " + String (loudnessRangeEnd - loudnessRangeStart))
                                    }
                                }
                            }
                        }
                        
                        // Move on to the next bin
                        this.currentBin = (this.currentBin + 1) % this.numberOfBins;

                        // Set it to zero.
                        for (let k = 0; k != numberOfChannels; ++k) {
                            this.bin[k][this.currentBin] = 0.0;
                        }
                        this.numberOfSamplesInTheCurrentBin = 0;
                    }
                }
            }
        }
    }

    registerProcessor(PROCESSOR_ID, BS1770LoudnessProcessor);
}

export class BS1770LoudnessNode extends AudioWorkletNode {
    constructor(context : AudioContext) {
        super(context, 'itu-r-bs1770-2.loudness.processor', {
            numberOfInputs: 2
        });

        this.port.onmessage = ev => {
            if (ev.data.type == 'update') {
                this._update.next(ev.data.state);
            }
        };
    }

    private _update = new Subject<LoudnessState>();

    get update(): Observable<LoudnessState> {
        return this._update;
    }
    
    static async create(context : AudioContext): Promise<BS1770LoudnessNode> {
        await Workman.loadAudioWorklet(context, worklet);
        return new BS1770LoudnessNode(context);
    }
}