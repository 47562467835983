// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'trustHtml'
})
export class TrustHtmlPipe {
    constructor(
        private domSanitizer : DomSanitizer
    ) {

    }

    transform(html : string) {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
}