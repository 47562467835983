// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { NgZone, Injectable } from '@angular/core';

export interface ValueRenderer {
    render() : string;
    elements : HTMLElement[];
    remove() : void;
    lastContent : string;
}

@Injectable()
export class ValueRenderingService {
    constructor(
        private ngZone : NgZone
    ) {
        ngZone.runOutsideAngular(() => requestAnimationFrame(() => this.render()));
    }

    private _renderers : ValueRenderer[] = [];

    private _updateFps : number = 30;
    private _updateInterval = 1000 / this._updateFps;
    private _renderInterval = 1000 / 60.0;

    private _timeSinceUpdate = 0;

    render() {
        this._timeSinceUpdate += this._renderInterval;
        if (this._timeSinceUpdate >= this._updateInterval) {
            this._timeSinceUpdate = 0;

            let defunct : ValueRenderer[] = [];

            for (let renderer of this._renderers) {

                // if (renderer.elements.length === 0) {
                //     defunct.push(renderer);
                // }

                let content = renderer.render();

                if (renderer.lastContent === content)
                    continue;

                for (let element of renderer.elements)
                    element.innerHTML = content;
            }

            for (let item of defunct) 
                item.remove();
        }

        requestAnimationFrame(() => this.render());
    }

    addRenderer(elements : HTMLElement[], render : () => string) : ValueRenderer {

        // This is wrong because some elements use addRenderer() to get a slice of the single 
        // performant RAF timer
        // if (elements.length === 0)
        //     return;

        let renderer : ValueRenderer;
        let self = this;

        renderer = {
            render, 
            elements,
            lastContent: null,

            remove() {
                self._renderers = self._renderers.filter(x => x !== renderer);
            }
        }; 

        this._renderers.push(renderer);
        return renderer;
    }
}