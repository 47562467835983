// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { MonitorSource } from '@/overview/monitors';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Trigger, Scte35Trigger, CueOutTrigger, 
         CueOutContTrigger, AudioController, bufferFromBase64 } from '@/overview/common';
import { Subject } from 'rxjs';

import * as Hls from 'hls.js';
import { HlsNetworkLoader } from './hls-network-loader';
import { LiveStreamRenderer, RendererSettings, RendererError, TimelineSpan, NetworkRequest, AudioMeterReading, TimelineTrack, RendererMediaManifest, EventEmitter } from '@/renderers/renderer';
import * as SCTE35 from '@astronautlabs/scte35';

export interface HlsMonitorSource extends MonitorSource {
    url : string;
}

let ALERTED_MISSING_MSE = false;

export class HlsRenderer implements LiveStreamRenderer {
    readonly id = 'hls';
    readonly name = 'HLS';

    constructor(
        private rootElement : HTMLElement
    ) {
        Object.assign(rootElement.style, {
            position: 'relative',
            display: 'flex',
            background: 'black',
            flexDirection: 'column',
            flexGrow: '1',
            flexShrink: '1'
        });
    }

    source : HlsMonitorSource;
    iframeUrl : SafeResourceUrl;
    readonly supportsStream = true;
    mediaStream : MediaStream = null;

    get duration() {
        return this.element.duration;
    }
    
    get position() {
        return this.element.currentTime;
    }

    get muted() {
        return this.audioController.muted;
    }
    
    focused() {
        this.element.controls = true;
    }

    unfocused() {
        this.element.controls = false;
    }

    async destroy() {
        if (this.audioController)
            this.audioController.shutdown();
        clearInterval(this._statsInterval);
        clearInterval(this._positionTrackerInterval);
        this.element.remove();
        this.element = null;
    }

    async mute() {
        if (this.audioController)
            this.audioController.mute();
        // this.element.volume = 0;
        // this.element.muted = true;

        this.muteChanged.next(true);
    }

    async unmute() {
        if (this.audioController)
            this.audioController.unmute();
        // this.element.volume = 1;
        // this.element.muted = false;
        
        this.muteChanged.next(false);
    }
    
    async seek(position : number) {
        this.element.currentTime = position;
    }

    renditionSpan : TimelineSpan;
    currentRenditionIdentity : string;
    currentRendition : any;

    segmentTimes : number[] = [];
    maxSegmentTime : number = 0;

    updateStats() {
        let averageSegmentTimeStr : string = '...';
        let maximumSegmentTimeStr : string = '...';
        let segmentTimes = this.segmentTimes;

        if (segmentTimes.length > 0) {
            let sum = segmentTimes.filter(x => !isNaN(x)).reduce((pv, cv) => pv + cv, 0);
            let averageSegmentTime = sum / segmentTimes.length;
            averageSegmentTime = Math.round(100*averageSegmentTime) / 100.0;
            averageSegmentTimeStr = `${averageSegmentTime}s`;

            let max = segmentTimes.reduce((pv, cv) => cv > pv ? cv : pv, 0);
            max = Math.round(100*max) / 100.0;
            maximumSegmentTimeStr = `${max}s`;
        }

        let playbackSection = '';

        if (this.levelInfo) {
            let bitrateInMbps = this.levelInfo.bitrate / 1000000;
            let bitrateStr = `${Math.round(bitrateInMbps * 100) / 100.0}Mbps`;
            let hasRendition = this.levelInfo.height !== undefined;
            let renditionIdentity = `${this.levelInfo.width}x${this.levelInfo.height}@${this.levelInfo.bitrate}bps,videoc=${this.levelInfo.videoCodec},audioc=${this.levelInfo.audioCodec}`;
            let currentRenditionEnded = !hasRendition || renditionIdentity !== this.currentRenditionIdentity;

            if (currentRenditionEnded) {
                if (this.renditionSpan) {
                    this.renditionSpan.length = Date.now() - this.renditionSpan.startsAt;
                    this.renditionSpan.unclosed = false;

                    this.renditionSpan = null;
                }
            }

            if (currentRenditionEnded && hasRendition) {
                this.currentRendition = this.levelInfo;
                this.currentRenditionIdentity = renditionIdentity;

                
                this.renditionSpan = {
                    id: `request-${++this.nextRenditionSpanId}`,
                    track: this.renditionsTrack,
                    label: `${this.levelInfo.height}p @ ${bitrateStr}`,
                    unclosed: true,
                    startsAt: Date.now(),
                    details: this.formatRendition(this.levelInfo, 'Rendition Changed')
                };
                
                this.logSpanUpdated.next(this.renditionSpan);
            }

            playbackSection = this.formatRendition(this.levelInfo, 'Active Rendition');
        }

        this.stats = `
            # Network

            **Segment Downloads**
            - **Average time:** ${averageSegmentTimeStr}
            - **Max time:** ${maximumSegmentTimeStr}

            ${playbackSection}
        `;

        this.statsUpdated.next(this.stats);
    }

    nextRenditionSpanId = 0;

    renditionsTrack : TimelineTrack = {
        id: 'renditions',
        label: 'Renditions'
    };

    networkRequestFinished(networkRequest : NetworkRequest) {
        if (networkRequest.extension === 'ts') {
            this.segmentTimes.push(networkRequest.time);
            while (this.segmentTimes.length > 5000)
                this.segmentTimes.shift();
            this.maxSegmentTime = this.segmentTimes.reduce((pv, cv, ci) => cv > pv ? cv : pv, 0);
        }
    }

    formatRendition(levelInfo : Hls.Level, title = 'Rendition') {
        let bitrateInMbps = levelInfo.bitrate / 1000000;
        let bitrateStr = `${Math.round(bitrateInMbps * 100) / 100.0}Mbps`;

        let content = '';

        if (levelInfo.width === undefined) {
            // TODO: show the rendition info anyway

            content += `## ${title}\n`;
            content += `This content does not have multiple renditions.`;
            return content;
        }

        content += `## ${title} (${levelInfo.height}p @ ${bitrateStr})\n`;

        if (levelInfo.name) {
            content += `- **Name:** ${levelInfo.name}\n`;
        }

        content += `- **Bitrate:** ${bitrateStr || 'Unknown'}\n`;
        content += `- **Video Codec:** ${levelInfo.videoCodec || 'Unknown'}\n`;
        content += `- **Audio Codec:** ${levelInfo.audioCodec || 'Unknown'}\n`;
        content += `- **Mode:** ${levelInfo.details.live ? 'Live' : 'VOD'}\n`;

        if (levelInfo.width || levelInfo.height) {
            content += `- **Resolution:** ${levelInfo.width} ⨉ ${levelInfo.height}\n`;
        }

        return content;
    }
    
    element : HTMLVideoElement;

    get audioController() {
        return this._audioController;
    }

    _audioController : AudioController;

    async startPlayback() {
        try {
            await this.element.pause();
            await this.element.play();
        } catch (e) {
            if (e.name == 'NotAllowedError') {
                // Requires user gesture to start.
                this.errors.next({
                    type: 'requires-user-gesture',
                    message: 'Playback requires user gesture'
                });
            }

            console.error(`[HLS] Caught error while trying to start playback:`);
            console.error(e);
        }
    }
    
    _statsInterval : any;
    stats : string = '_Loading..._';
    levelInfo : Hls.Level = null;

    seeked = new EventEmitter<number>();
    positionProgressed = new EventEmitter<number>();
    programSpanUpdated = new EventEmitter<TimelineSpan>();
    logSpanUpdated = new EventEmitter<TimelineSpan>();
    triggerUpdated = new EventEmitter<Trigger>();
    networkRequestUpdated = new EventEmitter<NetworkRequest>();
    statsUpdated = new EventEmitter<string>();
    audioMeterReadings = new EventEmitter<AudioMeterReading>();
    muteChanged = new EventEmitter<boolean>();
    errors = new EventEmitter<RendererError>();
    warnings = new EventEmitter<RendererError>();
    manifestChanged = new EventEmitter<RendererMediaManifest>();
    mediaStreamChanged = new EventEmitter<MediaStream>();
    
    start(source: HlsMonitorSource, settings : RendererSettings) {
        this.source = <HlsMonitorSource>source;

        if (this.element) {
            this.element.pause();
            this.element.src = undefined;
            this.element.remove();
            this.element = null;
        }

        let rootElement : HTMLElement = this.rootElement;

        this.element = document.createElement('video');
        Object.assign(this.element.style, {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        });

        this.element.autoplay = true;
        this.element.loop = true;

        //this.element.muted = true;
        rootElement.appendChild(this.element);

        this._audioController = new AudioController(this.element, settings.audioMeters);
        this._audioController.readings.subscribe(readings => this.audioMeterReadings.next(readings));
        
        if (!source.url || source.url === '') {
            this.errors.next({
                type: 'fault',
                message: 'You must specify an HLS manifest URL.'
            });
            return;
        }

        if (!source.url.match(/.+:\/\/[^\/]+/)) {
            this.errors.next({
                type: 'fault',
                message: `URL '${source.url}' is not valid.`
            })
            return;
        }

        let requiresHTTPS = window.location.protocol === 'https:'; // || window.location.hostname === 'localhost';

        if (requiresHTTPS && source.url.startsWith('http:')) {
            this.errors.next({
                type: 'fault',
                message: `HTTPS is required for sources loaded into Web Edition.`
            });
            return;
        }

        if (!Hls.isSupported() && !ALERTED_MISSING_MSE) {
            ALERTED_MISSING_MSE = true;
            this.errors.next({
                type: 'mse-missing',
                message: 'Media Source Extensions (MSE) is unavailable'
            });
        }

        if(Hls.isSupported()) {

            this._statsInterval = setInterval(() => this.updateStats(), 2000);

            let self = this;

            let hlsZone = Zone.current.fork({
                name: `HLS.js:${this.source.url}`,
                onHandleError(parent, currentZone, targetZone, error) {
                    //alert('Uncaught error in HLS.js');

                    console.error(`Uncaught error in HLS.js:`);
                    console.error(error);
                    console.dir(error);

                    return parent.handleError(targetZone, error);
                },
                properties: {
                    isHlsJs: true
                }
            });

            hlsZone.runGuarded(() => {
                class HlsNetworkLoaderInstance extends HlsNetworkLoader {
                    constructor(config) {
                        super(config, self.source, self);
                    }
                }

                let hlsOptions : Partial<Hls.Config> = {};

                if (settings.enableNetworkMonitoring) {
                    hlsOptions.loader = HlsNetworkLoaderInstance;
                }

                let hls = new Hls(hlsOptions);

                hls.on(Hls.Events.LEVEL_SWITCHED, (ev, data) => {
                    this.levelInfo = hls.levels[data.level];
                });

                hls.on(Hls.Events.MEDIA_ATTACHED, (ev, data) => {
                    console.log(`[HlsRenderer] Media has been attached to <video>`);
                });

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.startPlayback();

                    setTimeout(() => {
                        let video = this.element;
                        if (video) {
                            if (video['captureStream']) {
                                this.mediaStream = video['captureStream']();
        
                                if (this.mediaStream.getTracks().length === 0) {
                                    console.warn(`[HlsRenderer] WARNING: Acquired capture stream with zero tracks!`);
                                } else {
                                    console.log(`[HlsRenderer] Acquired capture stream with ${this.mediaStream.getTracks().length} tracks`);
                                }
        
                                this.mediaStreamChanged.next(this.mediaStream);
                            } else {
                                if (video['mozCaptureStream']) {
                                    console.warn(`MediaElement.mozCaptureStream() is available but will not be used. See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream#Firefox-specific_notes`);
                                }
        
                                console.warn(`MediaElement.captureStream() is unavailable, cannot expose MediaStream`);
                            }
                        } else {
                            console.warn(`Could not initiate media stream capture: Element is not yet available!`);
                        }

                    }, 5000);
                });
                
                hls.on(Hls.Events.ERROR, (ev, data) => {
                    console.log(`Monitor '${this.source.label}': HLS.js Error, type=${data.type}, fatal=${data.fatal}`);
                    console.log(` - Source URL : ${this.source.url}`);
                    console.log(` - Details    : ${data.details}`);
                    console.log(` - URL        : ${data.url}`);

                    let skip = false;

                    if (data && data.response && data.response.text.includes('fetch: generic error: The user aborted a request.'))
                        skip = true;
                    
                    if (!skip) {
                        let message = 'Generic error, see console.';

                        if (data && data.response && data.response.text) {
                            message = data.response.text;
                        } else if (data.type === 'mediaError') {
                            message = 'Media error (generic, see console)';

                            if (data.details == 'bufferStalledError') {
                                message = 'Buffer stalled.';
                            }
                        }

                        this.errors.next(<any>{
                            type: 'fault',
                            message: `HLS: Error in monitor '${this.source.label}': ${message}`
                        });
                    }
                })
                
                hls.on(Hls.Events.FRAG_PARSED, async (ev, data) => {
                    let timecode = data.frag.start;

                    for (let tag of data.frag.tagList) {
                        let [ name, value ] = tag;

                        if (name === 'EXT-X-CUE-OUT') {
                            // Ad break starts (leaving program content)
                            // value is "290.96"
                            // value is duration of break in seconds

                            this.triggerUpdated.next(<CueOutTrigger>{
                                type: 'cue-out',
                                data: { duration: parseFloat(value) },
                                timecode 
                            });

                        } else if (name === 'EXT-X-CUE-OUT-CONT') {
                            // Cue out (ad break) continued
                            // Value example:
                            // ElapsedTime=180.046,Duration=239.900,SCTE35=/DAlAAAAAAAAAAAAFAVAAADOf+/+XGzyRv4BSXPYAAAAAAAAkvP8hAk=
                            
                            this.triggerUpdated.next(<CueOutContTrigger>{
                                type: 'cue-out-cont',
                                data: undefined, // TODO
                                timecode 
                            });

                        } else if (name === 'EXT-X-CUE-IN') {
                            console.log('Cue In:');
                            console.dir(data);

                            // Ad break ends (returning to program content)
                            // value is "undefined"
                            
                            this.triggerUpdated.next({
                                type: 'cue-in',
                                data: undefined,
                                timecode 
                            });
                        } else if (name === 'EXT-OATCLS-SCTE35') {
                            // SCTE-35 marker
                            
                            let data = await SCTE35.SpliceInfoSection.deserialize(bufferFromBase64(value));
                            console.log('SCTE-35:');
                            console.dir(data);

                            this.triggerUpdated.next(<Scte35Trigger>{
                                type: 'scte-35',
                                data, timecode
                            });
                        }
                    }
                });

                hls.loadSource(this.source.url);
                hls.attachMedia(this.element);
            });
        } else if (this.element.canPlayType('application/vnd.apple.mpegurl')) {
            this.element.src = source.url;
            this.element.addEventListener('loadedmetadata', () => this.element.play());
            
            this.element.textTracks.addEventListener('addtrack', ev => {
                console.log('New text track');
                console.dir(ev);

                if (ev.track.kind === 'metadata') {
                    console.log('New metadata track!');
                    let metadataTrack = <TextTrack>ev.track;

                    metadataTrack.mode = "hidden";

                    metadataTrack.addEventListener('cuechange', ev => {
                        console.log('CUE CHANGE');
                        console.dir(ev);
                    });
                }
            });

        } else {
            alert('Cannot start HLS monitor: This browser does not support HLS');
        }
        
        this.element.addEventListener('timeupdate', ev => {
            if (!this.element)
                return;

            this.manifestChanged.next({
                channels: this.audioController ? this.audioController.channelCount : 0,
                duration: this.element.duration
            });
        });

        this._positionTrackerInterval = setInterval(
            () => this.positionProgressed.next(this.element.currentTime), 
            1000.0 / 20
        );

        this.element.addEventListener('seeked', ev => {
            if (!this.element)
                return;
            
            this.seeked.next(this.element.currentTime);
            this.positionProgressed.next(this.element.currentTime);
        });
    }

    _positionTrackerInterval;
}