// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { LoudnessMeter, LoudnessState } from './ITU-R_BS.1770-2/loudness-meter';
import { AudioMeterOptions } from './audio-meter-options';
import { AudioMeterNode } from './audio-meter.audioworklet';
import { AudioChannelState } from './audio-channel-state';
import { TruePeakState, TruePeakAudioWorkletNode } from './ITU-R_BS.1770-2/true-peak';
import { supportsAudioWorklet } from './workman';
import { AudioMeterReading } from '@/renderers/renderer';
import { Observable, Subject } from 'rxjs';

export interface AudioMeter {
    channels : AudioChannelState[];
    loudness : LoudnessState;
    truePeak : TruePeakState;
    sampleRate : number;
}

/**
 * Provides both audio control (mute/unmute) and audio metering for an HTML5 media 
 * source, such as a media element or stream.
 */
export class AudioController implements AudioMeter {
    constructor(
        element? : HTMLMediaElement | MediaStream,
        options? : AudioMeterOptions
    ) {
        if (element)
            this.init(element, options || {});
    }

    public static async createWithBufferNode(options? : AudioMeterOptions) {
        let controller = new AudioController();

        controller.loadOptions(options);
        controller.setupAudioContext();

        let sourceNode = controller.createBufferSource();
        
        controller._audioSource = sourceNode;
        controller.setupProductionPipeline();

        await controller.setupStandardMetering();
        await controller.setupLoudnessMetering();
        await controller.setupTruePeakMetering();

        return { controller, sourceNode };
    }

    public static async createWithCustomSource(factory : (ctx : AudioContext) => Promise<AudioNode>, options? : AudioMeterOptions) {
        let controller = new AudioController();

        controller.loadOptions(options);
        controller.setupAudioContext();

        controller._audioSource = await factory(controller._audioContext);
        controller.setupProductionPipeline();

        await controller.setupStandardMetering();
        await controller.setupLoudnessMetering();
        await controller.setupTruePeakMetering();

        return controller;
    }

    /**
     * Set up the audio controller asynchronously
     * 
     * @param element The element or stream that is to be controlled, metered and played out
     * @param options The metering options
     */
    async init(element : HTMLMediaElement | MediaStream, options : AudioMeterOptions) {
        this.loadOptions(options);
        this.setupAudioContext();

        this.acquireSource(element);
        this.setupProductionPipeline();

        await this.setupStandardMetering();
        await this.setupLoudnessMetering();
        await this.setupTruePeakMetering();
    }

    private _options : AudioMeterOptions;
    private _readings : Subject<AudioMeterReading>;
    private _readingsInterval;
    private _reading : AudioMeterReading = {} as any;

    get readings() : Observable<AudioMeterReading> {
        if (!this._readings) {
            this._readings = new Subject<AudioMeterReading>();
            this.setupReadings(this._options.fps || 20);
        }
        return this._readings;
    }

    private setupReadings(fps : number) {
        clearInterval(this._readingsInterval);
        this._readingsInterval = setInterval(() => {
            Object.assign(this._reading, {
                channels: this.channels,
                loudness: this.loudness,
                truePeak: this.truePeak,
                sampleRate: this.sampleRate,
                averaging: this.averaging
            });

            this._readings.next(this._reading);
        }, 1000.0 / fps);
    }

    /**
     * Construct a new audio context suitable for use by this controller.
     */
    private setupAudioContext() {
        let contextOptions : AudioContextOptions = {
            latencyHint: 'interactive'
        };
        if (this._desiredSampleRate && this._desiredSampleRate > 0)
            contextOptions.sampleRate = this._desiredSampleRate;
        this._audioContext = new AudioContext(contextOptions);
    }

    /**
     * Create the main pipe that takes in the main source material
     * and outputs it to the audio output device. This is needed in 
     * order to enable Overview's universal mute controls.
     */
    private setupProductionPipeline() {    
        this._audioGain = new GainNode(this._audioContext);
        this._audioGain.gain.value = 0;
        this._audioSource.connect(this._audioGain);
        this._audioGain.connect(this._audioContext.destination);
    }

    /**
     * Load the given options into this controller
     * @param options 
     */
    private loadOptions(options : AudioMeterOptions) {
        this._options = options;
        this._averaging = options.averaging || 0.95;
        this._clipLag = options.clipLag || 750;
        this._desiredSampleRate = options.sampleRate;
        this._clipLevel = options.clipLevel || 0.98;
        this._rmsWindowTime = options.rmsWindowTime !== undefined ? options.rmsWindowTime : 300;
        this._enableAudioMonitoring = options.enableAudioMonitoring === undefined ? true : options.enableAudioMonitoring;
        this._enableLUFS = options.enableLUFS === undefined ? true : options.enableLUFS;
        this._enableTruePeak = options.enableTruePeak === undefined ? true : options.enableTruePeak;
    }

    private createBufferSource() {
        let source = this._audioContext.createBufferSource();
        this._audioSource = source;

        return source;
    }
    /**
     * Create or assign the audio node that will act as the source material for this 
     * controller.
     * 
     * @param element 
     */
    private acquireSource(element : HTMLMediaElement | MediaStream) {
        this._audioSource = 
            'currentTime' in element 
                ? this._audioContext.createMediaElementSource(element)
                : this._audioContext.createMediaStreamSource(element)
        ;

        // ----------------- DEBUGGING
        // let osc = new OscillatorNode(this._audioContext, { type: 'square', frequency: 500 });
        // osc.start();
        // let oscGain = new GainNode(this._audioContext, { gain: 0.1 });
        // osc.connect(oscGain);
        // this._audioSource = oscGain;
        // ---------------------------
    }

    private supportsAudioWorklet() {
        return supportsAudioWorklet();
    }

    private _meteringContext : AudioContext;
    private _meteringSend : MediaStreamAudioDestinationNode;
    private _meteringReceive : MediaStreamAudioSourceNode;

    /**
     * Set up standard peak and RMS metering calculations. Only runs if standard audio monitoring 
     * is enabled. Without this process, 'peakLevel' and 'rms' of each channel state will be 
     * undefined.
     */
    private async setupStandardMetering() {
        if (!this._enableAudioMonitoring)
            return;

        if (!this.supportsAudioWorklet()) {
            console.warn('AudioController: Cannot start standard metering: No support for AudioWorklet');
            return;
        }

        // Construct another audio context where metering will live.
        this._meteringContext = new AudioContext({ sampleRate: this._audioContext.sampleRate, latencyHint: 'interactive' });
        this._meteringSend = this._audioContext.createMediaStreamDestination();
        this._audioSource.connect(this._meteringSend);

        this._meteringReceive = this._meteringContext.createMediaStreamSource(this._meteringSend.stream);

        try {
            let node = await AudioMeterNode.create(this._meteringContext, {
                averaging: this.averaging,
                clipLag: this.clipLag,
                clipLevel: this.clipLevel,
                rmsWindowTime: this.rmsWindowTime
            });

            node.updated.subscribe(() => {
                this._channels = node.channels
            });

            this._meteringReceive.connect(node);
            node.connect(this._meteringContext.destination);
        } catch (e) {
            console.error(`Caught error while loading audio processor worklet:`);
            console.error(e);
            alert(`Error: ${e}`);
            throw e;
        }
    }

    private async setupTruePeakMetering() {
        if (!this._enableTruePeak)
            return;

        if (!this.supportsAudioWorklet())
            return;
        
        if (this._audioContext.sampleRate !== 48000)
            return;
        
        let meter = await TruePeakAudioWorkletNode.create(this._meteringContext, this._averaging);
        meter.updates.subscribe(state => this._truePeak = state);
        this._meteringReceive.connect(meter);
        meter.connect(this._meteringContext.destination);
    }

    /**
     * Set up ITU-R BS.1770 loudness (LUFS) metering calculations. Only runs when enabled.
     * Without this process, the loudness state of the controller will not be defined.
     */
    private async setupLoudnessMetering() {
        if (!this._enableLUFS)
            return;
            
        if (!this.supportsAudioWorklet())
            return;
            
        this._loudnessMeter = await LoudnessMeter.create(this._meteringContext, this._meteringReceive);
        this._loudnessMeter.update.subscribe(state => this._loudness = state);
    }

    private _loudnessMeter : LoudnessMeter;
    private _audioSource : AudioNode;
    private _audioGain : GainNode;
    private _audioContext : AudioContext;
    private _averaging : number;
    private _clipLevel : number;
    private _clipLag : number;
    private _desiredSampleRate : number;
    private _rmsWindowTime : number;
    private _loudness : LoudnessState;
    private _enableLUFS : boolean = false;
    private _enableTruePeak : boolean = false;
    private _enableAudioMonitoring : boolean = false;
    private _muted : boolean = true;
    private _channels : AudioChannelState[] = [];
    private _channelCount : number;
    private _truePeak : TruePeakState;

    get sourceNode() {
        return this._audioSource;
    }
    
    get truePeak() {
        return this._truePeak;
    }

    /**
     * Whether loudness (LUFS) monitoring is enabled.
     */
    get enableLUFS() : boolean {
        return this._enableLUFS;
    }

    /**
     * Whether standard (peak/RMS) monitoring is enabled.
     */
    get enableAudioMonitoring() : boolean {
        return this._enableAudioMonitoring;
    }

    /**
     * The sample rate configured in Settings. Not necessarily the 
     * sample rate the controller is operating in.
     */
    get desiredSampleRate() { return this._desiredSampleRate; }

    /**
     * The sample rate the controller is operating in.
     */
    get sampleRate() { return this._audioContext.sampleRate; }

    /**
     * The audio context for this controller.
     */
    get audioContext() { return this._audioContext; }

    /**
     * The current loudness state object
     */
    get loudness() { return this._loudness; }

    /**
     * The averaging factor as defined in Settings.
     */
    get averaging() { return this._averaging; }

    /**
     * The clip lag as defined in Settings
     */
    get clipLag() { return this._clipLag; }

    /**
     * The clip level as defined in Settings
     */
    get clipLevel() { return this._clipLevel; }

    /**
     * The RMS window time (ms) as defined in Settings
     */
    get rmsWindowTime() { return this._rmsWindowTime; }

    async resume() {
        if (!this._audioContext)
            return;
        
        await this._audioContext.resume();
    }

    /**
     * Mute the audio stream controlled by this controller
     */
    mute() {
        this._muted = true;

        if (this._audioGain && this._audioGain.gain) {
            this._audioGain.gain.value = 0;
        }
    }

    /**
     * Unmute the audi ostream controlled by this controller
     */
    unmute() {
        this._muted = false;
        if (this._audioGain && this._audioGain.gain) {
            this._audioGain.gain.value = 1;
        }
    }

    /**
     * True if the audio stream controlled by this controller is muted
     */
    get muted() {
        return this._muted;
    }

    /**
     * Retrieve the current audio state of all channels
     */
    get channels() {
        return this._channels;
    }

    /**
     * Shut down this controller when you are done with it
     */
    shutdown() {
        clearInterval(this._readingsInterval);
        this._audioContext.close();
    }

    /**
     * Number of channels
     */
    get channelCount() {
        return this._channelCount;
    }
}
