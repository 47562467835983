// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, ViewContainerRef, Input, Output, ReflectiveInjector, Injector, ComponentFactoryResolver } from '@angular/core';
import { AppStarter } from '@/shell-common';

@Component({
    selector: 'app-shell-host',
    template: '',
    styles: [`
        :host {
            display: none;
        }
    `],
})
export class AppShellHostComponent {
    constructor(
        private viewContainerRef : ViewContainerRef,
        private injector : Injector,
        private componentResolver : ComponentFactoryResolver
    ) {
    }

    _appStarter : AppStarter = null;

    @Input()
    get appStarter() : AppStarter {
        return this._appStarter;
    }

    set appStarter(starter : AppStarter) {
        if (this._appStarter === starter)
            return;
        
        this.viewContainerRef.clear();

        if (!starter) {
            this._appStarter = null;
            return;
        }
        
        let injector = ReflectiveInjector.resolveAndCreate([], starter.injector);
        let componentResolver = starter.injector.get(ComponentFactoryResolver);
        let fac = componentResolver.resolveComponentFactory(starter.appClass as any);
        this.viewContainerRef.createComponent(fac, undefined, injector);
    }
}
