// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DefaultRendererFactory, LiveStreamRenderer, RENDERER_EVENTS, AVLocalSignaller } from './renderers';
import { Base64 } from 'js-base64';
import { ShellModule } from '@/shell';
import { environment } from './environments/environment';
import { Observable } from 'rxjs';
import { IpcRenderer } from 'electron';
import { AVPublisher, AVSignaller } from './rtc-ipc';
import { AVElectronSignaller } from './electron-ipc';
import { MonitorSource } from './overview/monitors';

function log(message : string) {
  if (typeof process !== 'undefined') {
    process.stdout.write(`${message}\n`);
  } else {
    console.log(message);
  }
}

export class Bootstrapper {
  constructor() {
    for (let queryItem of location.search.substr(1).split('&')) {
      let [ key, value ] = queryItem.split('=', 2);
      this.queryParams.set(decodeURIComponent(key), decodeURIComponent(value));
    }

    this.ipcMode = this.queryParams.get('ipc') || 'postMessage';
    this.sendToHost = this.sendToHostFrame;
    if (this.queryParams.has('render')) {
      log(`[RenderHost] Decoding source...`);
      try {
        this.renderSource = JSON.parse(Base64.decode(this.queryParams.get('render')));
      } catch (e) {
        console.error(`[RenderHost] Failed to parse passed render source: ${e}`);
        console.error(`[RenderHost] Raw Base64: '${this.queryParams.get('render')}'`);
        console.error(`[RenderHost] Raw JSON: '${Base64.decode(this.queryParams.get('render'))}'`);
      }

      this.channelId = `renderer:${this.renderSource.$uuid}`; //this.queryParams.get('electronChannelId');

      if (this.ipcMode === 'electron') {
        log(`[RenderHost] Setting up Electron IPC over channel '${this.channelId}'`);
  
        const electron = window['require']('electron');
        const ipcRenderer : IpcRenderer = electron.ipcRenderer;
  
        this.sendToHost = message => {
          ipcRenderer.send(this.channelId, message);
        };
      }
    }
  }

  private channelId : string;
  private renderSource : MonitorSource;
  private ipcMode : string;
  private sendToHost : (message : any) => void;
  private queryParams = new Map<string,string>();

  /**
   * Bootstrap this instance, depending on what environment we are in
   */
  bootstrap() {
    if (this.renderSource)
      this.bootstrapRenderHost();
    else
      this.bootstrapApp();
  }
  
  /**
   * Send a renderer message to the host using window.parent.postMessage
   * @param message 
   */
  private sendToHostFrame(message : any) {
    // console.log(`Delivering message to parent frame with channel ${this.channelId}:`);
    // console.dir(message);
    
    window.parent.postMessage({
      type: 'envelope', 
      channelId: this.channelId,
      message
    }, '*');
  }

  /**
   * Bootstrap the main Angular application
   */
  private bootstrapApp() {
    if (environment.production)
      enableProdMode();

    platformBrowserDynamic().bootstrapModule(ShellModule)
      .catch(err => console.error(err));
  }
  
  /**
   * Bootstrap this instance as a renderer host (no Angular)
   */
  private bootstrapRenderHost() {
    let options : any = {};
    if (this.queryParams.has('options')) {
      options = JSON.parse(Base64.decode(this.queryParams.get('options')));
    }

    log(`[Renderer] Renderer environment started (${this.renderSource.type})`);
    //console.dir(source);
  
    let renderer : LiveStreamRenderer;
    let hostElement : HTMLElement;
    
    // Construct a host element
  
    hostElement = document.createElement('div');
    hostElement.classList.add('renderer-host');
    document.body.appendChild(hostElement);
  
    // Construct a renderer 
  
    log(`[Renderer] Creating renderer of type ${this.renderSource.type}`);
  
    renderer = DefaultRendererFactory.createRenderer(this.renderSource.type, hostElement, this.renderSource);
    
    if (!renderer) {
      let errorMessage = `Failed to create renderer of type ${this.renderSource.type}`;
      console.error(errorMessage);
      alert(errorMessage);
    }
  
    // Start a session on the renderer with the given source material
  
    log(`[Renderer] Starting renderer for source ${this.renderSource.$uuid}`);
    //console.dir(source);
    renderer.start(this.renderSource, options);

    window.addEventListener('message', async ev => {
      let envelope = ev.data;

      if (envelope.type === 'envelope' && envelope.channelId === `renderer:${this.renderSource.$uuid}`) {
        let message = envelope.message;

        if (message.type === 'renderer-mute') {
          console.log('MUTE CALLED');
          await renderer.mute();
        } else if (message.type === 'renderer-unmute') {
          console.log('UNMUTE CALLED');
          await renderer.unmute();
        } else if (message.type === 'renderer-seek') {
          console.log('SEEK CALLED');
          await renderer.seek(message.position);
        } 
      }
    });
  
    // Subscribe to the appropriate events on the renderer 
    // and send them along to the host via the chosen 
    // IPC mechanism.
  
    RENDERER_EVENTS
      .map(name => ({ name, obs: <Observable<any>>renderer[name] }))
      .filter(({ obs }) => obs)
      .forEach(({ name, obs }) => {
        try {
          obs.subscribe(data => {
            //log(`[RenderHost] Sending '${name}' message to host data='${JSON.stringify(data)}'`);
            this.sendToHost({ type: 'renderer-event', name, data })
          })
        } catch (e) {
          console.error(`Failed to subscribe to render event '${name}': ${e.message}`);
        }
      });

    // Advertise an AVPublisher

    let enableCapture = false;
    if (this.queryParams.has('capture'))
      enableCapture = this.queryParams.get('capture') == '1';
    
    if (enableCapture) {
      if (renderer.supportsStream) {
        let signaller : AVSignaller;

        if (this.ipcMode === 'electron') {
          signaller = new AVElectronSignaller(`renderer-rtc:${this.renderSource.$uuid}`)
        } else {
          signaller = new AVLocalSignaller();
        }

        this.publisher = new AVPublisher(signaller);
        this.publisher.listen();
        renderer.mediaStreamChanged.subscribe(stream => {

          if (renderer.mediaStream) {
            log(`[Renderer] Starting AVPublisher with ID renderer-rtc:${this.renderSource.$uuid}`);

            this.publisher.setStream(renderer.mediaStream);
          } else {
            log(`[Renderer] Renderer type advertises MediaStream support, but no MediaStream is present. WebRTC is unavailable.`);
          }
        });
      } else {
        log(`[Renderer] ERROR: No MediaStream support, cannot capture renderer!`);
      }
    }

    log(`[Renderer] Sending 'ready' event to host`);

    this.sendToHost({ 
      type: 'renderer-ready', 
      channelId: this.channelId 
    });
  }

  private publisher : AVPublisher;
}
