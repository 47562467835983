// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { NgModule, ModuleWithProviders } from '@angular/core';
import { StarfieldComponent } from './starfield.component';
import { ShellService } from './shell.service';
import { SettingsService } from './settings.service';
import { UpdaterService } from './updater.service';
import { MaterialModule } from '@/material';
import { NotificationsService } from './notifications.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { OverviewLogoComponent } from './overview-logo.component';
import { OverviewIconComponent } from './overview-icon.component';
import { ResizerComponent } from './resizer.component';
import { PasswordFieldComponent } from './password-field.component';
import { TrustHtmlPipe } from './trust-html.pipe';

const DECLARATIONS = [
    StarfieldComponent,
    OverviewLogoComponent,
    OverviewIconComponent,
    ResizerComponent,
    PasswordFieldComponent,
    TrustHtmlPipe
];

@NgModule({
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    imports: [
        CommonModule,
        FormsModule,
        MarkdownModule.forRoot(),
        MaterialModule
    ]
})
export class ShellCommonModule {
    static forRoot(): ModuleWithProviders<ShellCommonModule> {
    return {
        ngModule: ShellCommonModule,
        providers: [
            SettingsService,
            ShellService,
            UpdaterService,
            NotificationsService
        ]
    };
}
}