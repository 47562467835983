// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { environment } from '../environments/environment';
import { EntryJumpComponent } from './entry-jump/entry-jump.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: EntryJumpComponent },
  { path: 'app', loadChildren: () => import('../overview/overview.module').then(m => m.OverviewModule) },
  { path: 'web', loadChildren: () => import('../website/website.module').then(m => m.WebsiteModule) },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: environment.isElectron
  })],
  exports: [RouterModule]
})
export class ShellRoutingModule { }
