// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { RendererFactory } from './renderer-registry';
import { HlsRenderer } from './hls/hls-renderer';

export const DefaultRendererFactory = new RendererFactory();

DefaultRendererFactory.register('hls', {
    id: 'hls',
    name: 'HLS',
    renderer: HlsRenderer
});