// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable } from '@angular/core';
import { MonitorSource } from './monitor-source';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

import * as uuidV4 from 'uuid/v4';
import { Bank } from './bank';
import { AuthenticationService, User, DataStore } from '@astronautlabs/chassis';
import { AngularFirestore } from '@angular/fire/firestore';
import { SubscriptionSet } from './subscription-set';
import { Base64 } from 'js-base64';

export interface OverviewSettings {
    workstationName : string;

    themeName : string;
    enableVideoBackdrop : boolean;
    videoBackdropUrl : string;
    enableVisualEffects : boolean;

    enableAudioMonitoring : boolean;
    audioMonitorAveraging : number;
    audioMonitorClipLevel : number;
    audioMonitorClipLag : number;
    clockUpdateSpeed: 'defer' | 'slow' | 'fast';
    enableAnimations : boolean;
    enableDropShadows : boolean;
    hideBackdropDuringDialogs : boolean;
    enableNetworkMonitoring : boolean;

    audioSampleRate : number;
    /**
     * RMS window size in milliseconds (ms)
     */
    rmsWindowTime : number;

    enableLUFS : boolean;
    enableTruePeak : boolean;

    audioMonitorLoudLevel : number;
    audioMonitorVeryLoudLevel : number;
  }

export const DEFAULT_SETTINGS : OverviewSettings = {
    audioSampleRate: 0,
    enableNetworkMonitoring: true,
    enableVideoBackdrop: false,
    enableVisualEffects: false,
    videoBackdropUrl: undefined,
    audioMonitorAveraging: 0.95,
    audioMonitorClipLag: 750,
    audioMonitorClipLevel: 0.98,
    enableAudioMonitoring: true,
    themeName: 'dark',
    workstationName: undefined,
    clockUpdateSpeed: 'defer',
    enableAnimations: true,
    enableDropShadows: true,
    hideBackdropDuringDialogs: false,
    rmsWindowTime: 300,
    enableLUFS: false,
    enableTruePeak: false,

    audioMonitorLoudLevel: -3,
    audioMonitorVeryLoudLevel: -1
};

@Injectable()
export class SettingsService {
    constructor(
        private auth : AuthenticationService,
        private datastore : DataStore
    ) {
        this.load();
        this._banksChanged.subscribe(banks => {
            if (this.currentBank) {
                let currentStillExists = banks.find(x => x.$uuid === this.currentBank.$uuid)
                if (!currentStillExists) {
                    setTimeout(() => {
                        this.switchToBank(banks[0]);
                    });
                }
            }
        });
        this.setupSync();
    }

    async setupSync() {
        await this.auth.ready;

        this.auth.signingOut.subscribe(() => {
            if (this.bankSync) {
                this.bankSync.unsubscribe();
                this.bankSync = null;
            }
            this._bankSubscriptions.unsubscribeAll();
            this._bankSubscriptions = null;
        });

        this.auth.userChanged.subscribe(user => {
            let oldUser = this._user;
            this._user = user;
            if (this._user) {
                this.syncFromCloud();
            } else if (oldUser) {
                this.resetAfterLogout();
            }
        });
    }

    private bankSync : Subscription;

    private syncFromCloud() {
        if (this.bankSync) {
            this.bankSync.unsubscribe();
            this.bankSync = null;
        }

        let user = this._user;

        if (!user)
            return;
        
        if (typeof this.datastore.watchAll !== 'function') {
            debugger;
        }

        this.bankSync = this.datastore.watchAll<Bank>(`/users/${user.account.uid}/banks`).subscribe(async banks => {
            if (!this.user)
                return;
            
            if (this._bankSubscriptions) {
                this._bankSubscriptions.unsubscribeAll();
                this._bankSubscriptions = null;
            }
            
            let bankSubs = new SubscriptionSet();

            await this.doWithoutCloudSync(async () => {
                for (let bank of banks) {
                    if (this.isCurrentBank(bank)) {
                        this._monitors = bank.monitors;
                    }
                }
                this._banks = <Bank[]>banks;
                this._banksChanged.next(this._banks);
                await this.save();
            });

            this._monitorsChanged.next(this._monitors);

            for (let existingBank of banks) {

                // let isNew = !this._banks.find(x => x.$uuid === existingBank.$uuid);

                // if (isNew) {
                //     this.doWithoutCloudSync(async () => await this.addBank(existingBank));
                // } else {
                //     if (this.isCurrentBank(existingBank)) {
                //         this._monitors = existingBank.monitors;
                //         this._monitorsChanged.next(this._monitors);
                //     }

                //     this.doWithoutCloudSync(async () => await this.editBank(existingBank, existingBank));
                // }

                let first = true;

                bankSubs.add(this.datastore.watch<Bank>(`/users/${user.account.uid}/banks/${existingBank.$uuid}`).subscribe(async newBank => {
                    if (first) {
                        first = false;
                        return;
                    }

                    this.doWithoutCloudSync(async () => await this.editBank(existingBank, newBank));
                }));
            }

            this._bankSubscriptions = bankSubs;
        });
    }

    private async doWithoutCloudSync(callback : Function) {
        let previousValue = this._allowCloudSync;

        this._allowCloudSync = false;
        try {
            await callback();
        } finally {
            this._allowCloudSync = previousValue;
        }
    }

    private _bankSubscriptions = new SubscriptionSet();

    private _user : User;
    private _monitors : MonitorSource[] = [];
    private _monitorsChanged : BehaviorSubject<MonitorSource[]> = new BehaviorSubject<MonitorSource[]>([]);
    private _settings : OverviewSettings = undefined;

    get user() {
        return this._user;
    }

    _settingsChanged : BehaviorSubject<OverviewSettings> = new BehaviorSubject<OverviewSettings>(DEFAULT_SETTINGS);

    resetAfterLogout() {
        this.banks = [];
        this.addBank({
            name: 'Bank 1',
            monitors: []
        });
    }
  
    private _allowCloudSync = true;

    async syncToCloud() {
        if (!this._allowCloudSync)
            return;
        
        await this.auth.ready;
        let user = this.auth.user;

        if (!user)
            return;
        
        this.setCloudBanks(this._banks);
    }

    async setCloudBanks(banks : Bank[]) {
        await this.auth.ready;
        let user = this.auth.user;

        if (!user)
            return;

        for (let bank of banks) {
            if (!bank.monitors)
                bank.monitors = [];
        }
        
        let existingBanks = await this.datastore.listAll<Bank>(`/users/${user.account.uid}/banks`);
        
        let deletionActions : Promise<void>[] = [];

        for (let existingBank of existingBanks) {
            let wasDeleted = !banks.find(x => x.$uuid === existingBank.$uuid);
            if (!wasDeleted)
                continue;

            deletionActions.push(this.datastore.delete(`/users/${user.account.uid}/banks/${existingBank.$uuid}`));
        }

        await Promise.all(banks.map(async bank => {
            if (!bank.$uuid) {
                bank.$uuid = this.generateUUID();
            }
            
            try {
                await this.datastore.set(`/users/${user.account.uid}/banks/${bank.$uuid}`, bank);
            } catch (e) {
                debugger;
            }
        }));

        await Promise.all(deletionActions);
    }

    get settingsChanged(): Observable<OverviewSettings> {
        return this._settingsChanged;
    }

    get settings(): OverviewSettings {
        if (this._settings)
            return this._settings;
        
        if (localStorage[`ov:settings`]) {
            try {
                this._settings = JSON.parse(localStorage[`ov:settings`]);
            } catch (e) {
                console.error(`Failed to parse settings JSON:`);
                console.error(e);
                this._settings = undefined;
            }
        }

        if (!this._settings)
            this._settings = DEFAULT_SETTINGS;

        this._settingsChanged.next(this._settings);
        
        return this._settings;
    }

    set settings(value : OverviewSettings) {
        this._settings = value;

        this.saveSettings();
    }

    _banksChanged = new BehaviorSubject<Bank[]>([]);
    
    get banksChanged(): Observable<Bank[]> {
        return this._banksChanged;
    }

    _banks : Bank[] = [];

    get banks() : Bank[] {
        return JSON.parse(JSON.stringify(this._banks));
    }

    set banks(set) {

        // ensure all banks have monitors arrays
        for (let bank of set) {
            if (!bank.monitors)
                bank.monitors = [];
        }

        this._banks = set;
        this._banksChanged.next(this._banks);
        this.save();
    }

    get monitorsChanged(): Observable<MonitorSource[]> {
        return this._monitorsChanged;
    }
    
    get monitors() {
        return this._monitors;
    }

    set monitors(set : MonitorSource[]) {
        this._monitors = set;
        this._monitorsChanged.next(this._monitors);
        this.save();
    }

    addToPreviousMonitors(source : MonitorSource) {
        let monitors : MonitorSource[] = this.previousMonitors;
        monitors.unshift(source);

        while (monitors.length > 100)
            monitors.pop();

        this.saveSettingItem('previousMonitors', monitors);
    }

    get previousMonitors() {
        return this.loadSettingItem('previousMonitors', []);
    }

    async editBank(original : Bank, edited : Bank) {
        let existingIndex = this._banks.findIndex(x => x.$uuid === original.$uuid)

        if (existingIndex < 0) {
            console.error(`Could not find bank to edit!`);
            console.dir(original);
            return;
        }

        this._banks[existingIndex] = this.clone(edited);
        this._banksChanged.next(this._banks);
        await this.save();

        if (this._currentBank && this._currentBank.$uuid === original.$uuid) {
            this._currentBank = edited;
            this._currentBankChanged.next(edited);
        }
    }

    async editMonitorInBank(bank : Bank, original : MonitorSource, edited : MonitorSource) {

        let monitors = bank.monitors;
        if (this.isCurrentBank(bank))
            monitors = this._monitors;

        let originalIndex = monitors.findIndex(x => x === original);
        if (originalIndex < 0) {
            console.error(`Could not find monitor to edit!`);
            console.dir(original);
            return;
        }
        
        // Update the version tag so that we know whether to reload it or not
        edited.$version = this.generateUUID();

        monitors[originalIndex] = this.clone(edited);

        if (this.isCurrentBank(bank))
            this._monitorsChanged.next(this._monitors);

        await this.save();

        return this.clone(edited);
    }

    async editMonitorInCurrentBank(original : MonitorSource, edited : MonitorSource) {
        await this.editMonitorInBank(this.currentBank, original, edited);
    }

    async removeBank(bank : Bank) {
        this.banks = this.banks.filter(x => x.$uuid !== bank.$uuid);
    }

    async removeMonitorFromCurrentBank(monitor : MonitorSource) {
        await this.removeMonitorFromBank(this.currentBank, monitor);
    }

    generateUUID() {
        return uuidV4();
    }

    async addBank(bank : Partial<Bank>) {
        bank.$uuid = this.generateUUID();
        if (!bank.monitors)
            bank.monitors = [];

        this._banks.push(bank as Bank);
        this._banksChanged.next(this._banks);
        await this.save();

        return bank;
    }

    getBankById(id : string) {
        return this.clone(this._banks.find(x => x.$uuid === id));
    }

    async removeMonitorFromBank(bank : Bank, monitorSource : MonitorSource) {
        if (!bank)
            throw new Error(`Passed invalid bank`);
        
        let existingBank = this._banks.find(x => x.$uuid === bank.$uuid);

        if (!existingBank)
            throw new Error(`The given bank is not registered`);
        
        if (this.isCurrentBank(bank)) {
            this._monitors = this._monitors.filter(x => x.$uuid !== monitorSource.$uuid);
            this._monitorsChanged.next(this._monitors);
        } else {
            existingBank.monitors = existingBank.monitors.filter(x => x.$uuid !== monitorSource.$uuid);
        }

        await this.save();
    }

    async addMonitorToCurrentBank(monitorSourceData : MonitorSource) {
        return await this.addMonitorToBank(this.currentBank, monitorSourceData);
    }

    async addMonitorToBank(bank : Bank, monitorSourceData : MonitorSource) {
        if (!bank)
            throw new Error(`Passed invalid bank`);
        
        let monitorSource = this.prepareMonitorSource(monitorSourceData);

        if (this.isCurrentBank(bank)) {
            this._monitors.push(monitorSource);
            this._monitorsChanged.next(this._monitors);
        } else {
            let existingBank = this._banks.find(x => x.$uuid === bank.$uuid);

            if (!existingBank)
                throw new Error(`The given bank is not registered`);

            existingBank.monitors.push(monitorSource);
        }

        await this.save();

        return this.clone(monitorSource);
    }

    private clone<T>(t : T) {
        if (typeof t === 'object')
            return JSON.parse(JSON.stringify(t));

        return t;
    }

    private prepareMonitorSource(monitorSourceStarter : MonitorSource) {
        let monitorSource = this.clone(monitorSourceStarter);
        if (!monitorSource.$uuid)
            monitorSource.$uuid = this.generateUUID();
        monitorSource.$version = this.generateUUID();

        return monitorSource;
    }

    isCurrentBank(bank : Bank) {
        if (!bank || !this._currentBank)
            return false;

        return this._currentBank.$uuid === bank.$uuid;
    }

    public getSetting<T = any>(key, defaultValue? : T): T {
        return this.loadSettingItem(key, defaultValue);
    }

    public changeSetting<T = any>(key : string, value : T) {
        return this.saveSettingItem(key, value);
    }

    private saveSettingItem(key : string, value : any) {
        window.localStorage[`ov:${key}`] = Base64.encode(JSON.stringify(value));
    }

    private loadSettingItem(key, defaultValue?) {
        let value = window.localStorage[`ov:${key}`];
        if (value)
            return JSON.parse(Base64.decode(value));
        return defaultValue;
    }

    _currentBank : Bank;
    _currentBankChanged = new BehaviorSubject<Bank>(null);
    
    get currentBank() : Bank {
        return JSON.parse(JSON.stringify(this._currentBank));
    }

    set currentBank(value) {
        this._currentBank = value;
        this._currentBankChanged.next(value);
    }

    get currentBankChanged() {
        return this._currentBankChanged;
    }

    async saveSettings() {
        localStorage[`ov:settings`] = JSON.stringify(this.settings);
        this._settingsChanged.next(this._settings);
    }

    async saveBanks() {
        if (this.currentBank) {
            let bank = this._banks.find(x => x.$uuid == this.currentBank.$uuid);
            if (bank)
                bank.monitors = this._monitors;
        }

        this.saveSettingItem('banks', this._banks);
    }
    
    async saveMonitors() {    
        await this.saveBanks();
        //this.saveSettingItem('monitors', this._monitors);
    }

    async save() {
        await this.saveSettings();
        await this.saveBanks();

        await this.syncToCloud();
    }

    load() {
        let banks : Bank[] = this.loadSettingItem('banks', <Bank[]>[
            {
                $uuid: this.generateUUID(),
                monitors: [],
                name: 'Bank 1'
            }
        ]);
        this._banks = banks;
        this._banksChanged.next(this._banks);

        if (banks.length == 0) {
            // Either this is a fresh installation or it's from
            // an old version of Overview that does not support banks.
            // If we have any monitors in the old "monitors" slot, 
            // put them into the new default bank.

            console.log(`Banks: No previously saved banks. Initializing...`);
            let monitors : MonitorSource[] = this.loadSettingItem('monitors', []);
            this.addBank({
                name: 'Bank 1',
                monitors
            })
        }

        
        let currentBankID = this.loadSettingItem('currentBank');
        let currentBank = banks[0];

        if (currentBankID && banks.find(x => x.$uuid === currentBankID))
            currentBank = banks.find(x => x.$uuid === currentBankID);

        this.switchToBank(currentBank);
    }

    switchToBank(bank : Bank) {
        if (!bank)
            return;
        
        this._currentBank = bank;
        this._currentBankChanged.next(bank);

        this.saveSettingItem('currentBank', bank.$uuid);

        let monitors : MonitorSource[] = bank.monitors || [];

        // Make sure that all monitors have UUIDs (in case they were created in
        // an older version of Overview)
        let unidentifiedMonitors = monitors.filter(x => !x.$uuid);
        if (unidentifiedMonitors.length > 0) {
            unidentifiedMonitors.forEach(x => x.$uuid = this.generateUUID());
            this.saveBanks();
        }

        this._monitors = monitors;
        this._monitorsChanged.next(this._monitors);
    }
}