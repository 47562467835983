// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Input, Component, ViewChild, ElementRef, ɵbypassSanitizationTrustUrl, QueryList, ViewChildren, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ValueRenderingService, ValueRenderer } from './value-rendering.service';

interface ContinuousTimestampProvider {
    position : number;
}

@Component({
    selector: 'ov-timestamp',
    template: 
        `
            <canvas *ngIf="renderMode === 'canvas'" #canvas></canvas>
            <span #domNode *ngIf="renderMode === 'dom'"></span>
        `,
    styles: [`
        :host {
            display: inline-block;
            padding: 1px;
            color: #ccc;
            font-family: monospace;
            font-size: 17pt;
        }

        canvas {
            height: 2em;
            width: 6.4em;
        }
        :host ::ng-deep .tc-ms {
            color: #888;
        }

        :host ::ng-deep .tc-colon {
            color: #888;
        }
    `]
})
export class TimestampComponent {
    constructor(
        private ngZone : NgZone,
        private domSanitizer : DomSanitizer,
        private valueRendering : ValueRenderingService
    ) {
    }

    private _value : number;
    private _destroyed : boolean = false;

    private _renderMode : "dom" | "canvas" = "dom";
    private _provider : ContinuousTimestampProvider = null;

    @ViewChild('domNode')
    domNode : ElementRef<HTMLElement>;

    @Input()
    get provider() {
        return this._provider;
    }

    set provider(value) {
        this._provider = value;
    }

    @Input()
    get renderMode() {
        return this._renderMode;
    }

    set renderMode(value) {
        this._renderMode = value;
        this._kickRendering();
    }

    private _kickRendering() {
        if (this.renderMode === 'dom') {
            if (!this.domNode)
                return;
            
            if (!this.valueRenderer) {
                this.valueRenderer = this.valueRendering.addRenderer(
                    [ this.domNode.nativeElement ], 
                    () => this.compute()
                );
            }
        } else if (this.renderMode === 'canvas') {
            this.stopDomRendering();
            if (!this._frameScheduled)
                this.renderFrame();
        }
    }

    valueRenderer : ValueRenderer;

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._kickRendering();
    }

    destroy() {
        this._destroyed = true;
        this.stopDomRendering();
    }
    
    stopDomRendering() {
        if (this.valueRenderer) {
            this.valueRenderer.remove();
            this.valueRenderer = null;
        }
    }

    ngOnDestroy() {
        this.destroy();
    }

    @ViewChildren('canvas')
    canvas : QueryList<ElementRef<HTMLCanvasElement>>;

    @Input()
    get value() {
        return this._value;
    }

    _frameScheduled : boolean = false;

    renderFrame() {
        if (this._destroyed || this.renderMode === 'dom') {
            this._frameScheduled = false;
            return;
        }

        if (this._renderMode === 'canvas') {
            try {
                this.renderCanvas();
            } catch (e) {
                console.error(`Caught error while rendering timestamp frame: ${e.message || e}`);
                console.dir(e);

                this._frameScheduled = false;
                throw e;
            }
        }

        requestAnimationFrame(this.renderFrame.bind(this));
        this._frameScheduled = true;
    }

    renderCanvas() {
        let canvasRef = this.canvas.first;

        if (!canvasRef)
            return;

        let canvas = canvasRef.nativeElement;
        
        if (canvas.width != canvas.offsetWidth || canvas.height != canvas.offsetHeight) {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            this._canvasContext = null;
        }

        if (!this._canvasContext) {
            this._canvasContext = canvas.getContext('2d');
            
            let ctx = this._canvasContext;
            ctx.font = 'bold 22pt monospace';
            ctx.textBaseline = "top";
            ctx.textAlign = "left";
        }
        
        let ctx = this._canvasContext;
        let w = canvas.width;
        let h = canvas.height;

        ctx.clearRect(0, 0, w, h);

        // render text

        let y = 12;
        let cursor = 0;
        let separatorStyle = '#777';
        let partStyle = 'white';

        let renderAndAdvance = (text) => {
            let measure = ctx.measureText(text);
            ctx.fillText(text, cursor, y);
            cursor += measure.width;
        }

        let renderSeparator = text => {
            ctx.fillStyle = separatorStyle;
            renderAndAdvance(text);
        };

        let renderPart = (text, color?) => {
            if (color)
                ctx.fillStyle = color;
            else
                ctx.fillStyle = partStyle;
            renderAndAdvance(text);
        };

        if (this.hours !== null) {
            renderPart(this.hours);
            renderSeparator(':');
        }

        renderPart(this.minutes);
        renderSeparator(':');

        renderPart(this.seconds);
        renderSeparator('.');
        renderPart(this.millis, separatorStyle);
    }

    private _canvasContext : CanvasRenderingContext2D;
    private _renderingStarted : boolean = false;
    private _showMS : boolean = true;
    
    @Input()
    get showMS() {
        return this._showMS;
    }

    set showMS(value : boolean) {
        this._showMS = value;
        this.compute();
    }

    set value(timecode : number) {
        this._value = timecode;
        this.compute();
    }

    zeroPad(number : number | string, digits : number) {
        let numStr = ''+number;

        while (numStr.length < digits)
            numStr = '0' + numStr;

        return numStr;
    }

    rightZeroPad(number : number | string, digits : number) {
        let numStr = ''+number;

        while (numStr.length < digits)
            numStr = numStr + '0';

        return numStr;
    }

    compute() {
        let value = this._value;
        if (this.provider)
            value = this.provider.position;
        
        this.transform(value, this._showMS);
        return this.html;
    }

    html : any;
    hours : string;
    minutes : string;
    seconds : string;
    millis : string;

    transform(timecode : number, showMS = true) {
        if (timecode as any === false || timecode === undefined || isNaN(timecode)) {
            this.html = '';
            return;
        }
            
        let hours = Math.floor(timecode / (60*60));
        timecode -= hours*60*60;

        let minutes = Math.floor(timecode / 60);
        timecode -= minutes*60;

        let seconds = Math.floor(timecode);
        timecode -= seconds;

        let ms = Math.floor(timecode * 1000.0) / 1000.0;

        this.seconds = `${this.zeroPad(seconds, 2)}`;
        this.millis = this.rightZeroPad((''+ms).replace(/^0\./, ''), 3);
        this.minutes = `${this.zeroPad(minutes, 2)}`;

        if (hours > 0)
            this.hours = `${this.zeroPad(hours, 2)}`;
        else
            this.hours = null;

        let colon = `<span class="tc-colon">:</span>`;
        this.html = `${this.hours ? `${this.hours}${colon}` : ``}${this.minutes}${colon}${this.seconds}`;
        if (this.showMS)
            this.html += `<span class="tc-ms">.${this.millis}</span>`;
    }
}