// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import 'zone.js';
import 'reflect-metadata';

import { Bootstrapper } from './bootstrapper';
import { ShellModule } from './shell';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Needed for loading resources dynamically (particularly for ngx-monaco-editor)
//window.__dirname = `${document.location.href.replace(/\/index.html.*/, '')}`;

// This statement intentionally does nothing.
// (Ensures that angular build can detect the root module)
if (window['__NONEXISTENT__'])
     platformBrowserDynamic().bootstrapModule(ShellModule);

new Bootstrapper().bootstrap();