// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Feature } from '@astronautlabs/licensing';

export const FEATURES : Feature[] = [
    {
        id: 'core',
        entitlementType: 'simple',
        name: 'Monitor any HTML5-compatible media source',
        summary: 'Including webcams, desktop/window capture, iframes, and HLS/MPEG-DASH manifests',
        skus: ['community', 'professional', 'enterprise'],
        iconUrl: '/assets/html5.svg',
        description: `
            <p>Overview supports any capture source that is normally available through HTML5, including 
            <code>&lt;video&gt;</code>, <code>&lt;audio&gt;</code>, webcams and generic capture devices (<code>getUserMedia()</code>), WebRTC, 
            and screen/window sharing (<code>getDisplayMedia()</code>).</p>

            <p>For any of these sources, Overview will show you audio levels (even while muted),
                provide black-frame and stuck-frame alerting, and on some editions even show you ancillary data
                like ad break status, SCTE-35 and HLS metadata tags.</p>
        `
    },
    {
        id: 'cloud-sync',
        entitlementType: 'simple',
        name: 'Sync Overview settings to the cloud',
        summary: 'Use your Overview account to sync preferences and monitor banks between your devices automatically.',
        skus: ['community', 'professional', 'enterprise'],
        iconName: 'cloud',
        description: `
        
            <p>Sync your settings across device using Overview's cloud storage. Supports end-to-end encryption to protect access to your 
                organization's resources.</p>

        `
    },
    {
        id: 'core-ancillary',
        entitlementType: 'simple',
        name: 'Ancillary capture',
        summary: `Monitor and inspect embedded metadata, triggers, and cues in HLS/DASH and more.	`,
        skus: ['community', 'professional', 'enterprise'],
        iconName: 'code',

        description: `
            <p>Overview will show ad breaks prominently within the monitor user interface giving you at-a-glance awareness of 
            the execution of your advertising triggers.</p>
        `
    },
    {
        id: 'extensions',
        entitlementType: 'simple',
        name: 'Extensions support',
        summary: 'Install, develop and debug Overview extensions using Typescript with a built-in IDE based on Monaco (the editor tech of VS Code).	',
        skus: ['professional', 'enterprise'],
        iconName: 'extension',

        description: `
            <p>Overview provides a complete Typescript API accessible from extensions that can be built directly inside the user interface.
                Develop with full intellisense and deploy your extensions to other users in your organization or publish it to the Overview 
                extension marketplace.</p>
        `
    },
    {
        id: 'pro-media',
        entitlementType: 'simple',
        name: 'Professional media sources',
        summary: `
            <ul>
                <li>
                    <strong>Blackmagic DeckLink™</strong><br/>
                    Use with any capture-capable DeckLink product*
                    <br/><br/>
                </li>
                <li>
                    <strong>SMPTE 2110</strong><br/>
                    <span class="badge">Coming soon</span> 
                    <br/>
                    Receive audio/video from any SMPTE 2110 compatible source
                </li>
                <li>
                    <strong>Sony NMI</strong><br/>
                    <span class="badge">Coming soon</span> 
                    <br/>
                    Receive audio/video from any Sony NMI compatible source
                </li>
                <li>
                    <strong>ASPEN</strong><br/>
                    <span class="badge">Coming soon</span> 
                    <br/>
                    Receive audio/video from any Evertz ASPEN compatible source
                </li>
                <li>
                    <strong>NewTek NDI™ support</strong> 
                    <span class="badge">Coming soon</span> 
                    <br/>
                    Play back any detected <a target="_blank" href="https://ndi.newtek.com/">NewTek NDI™</a> source on your network
                    <br/><br/>
                </li>
                <li>
                    <strong>Audinate Dante support</strong>
                    <span class="badge">Coming soon</span> 
                    <br/>
                    Play back any detected Dante source on your network
                    <br/><br/>
                </li>
            </ul>
        `,
        skus: ['professional', 'enterprise'],
        iconName: 'graphic_eq',

        description: `
        
            <p>DeckLink is one of the most trusted brands for professional capture and playback hardware devices. 
            Use any capture-capable DeckLink-compatible Blackmagic product to monitor HD-SDI, HDMI or any other supported
            input within Overview.</p>
        `,

        finePrint: 'Available only in the desktop version. DeckLink support limited to configurations officially supported by the official DeckLink drivers.'
    },
    {
        id: 'pro-ancillary',
        entitlementType: 'simple',
        name: 'Professional ancillary data',
        summary: `Monitor and inspect embedded SDI VANC packets, SCTE 35 PIDs, arbitrary HLS tags and more.	`,
        skus: ['professional', 'enterprise'],
        iconName: 'code',

        description: `
            <p>Inspect arbitrary broadcast-tier stream metadata including SCTE 104/35</p>
            <p>The following standards are supported:</p>

            <ul>
                <li>
                    SDI VANC, SMPTE 291 (raw packet capture)
                    <ul>
                        <li>SCTE 104</li>
                    </ul>
                </li>
                <li>
                    MPEG transport stream PIDs
                    <ul>
                        <li>SCTE 35</li>
                    </ul>
                </li>
                <li>
                    HLS
                    <ul>
                        <li>EXT-X-CUE-IN/CUE-OUT/CUE-OUT-CONT</li>
                        <li>EXT-X-SCTE35/OATCLS-SCTE35/ASSET</li>
                        <li>Raw capture for all custom HLS tags</li>
                    </ul>
                </li>
                <li>
                    DASH
                    <ul>
                        <li>SCTE 35 carriage as defined in SCTE 214-1</li>
                    </ul>
                </li>
            </ul>
        `
    },
    {
        id: 'desktop',
        entitlementType: 'simple',
        name: 'Desktop version',
        summary: 'Better performance, better <iframe> integration and support for other features not possible on the web.',
        skus: ['professional', 'enterprise'],
        iconName: 'cloud_download'
    },
    {
        id: 'pro-audio',
        entitlementType: 'simple',
        name: 'Professional Audio',
        summary: `True peak (dbTP) and loudness (LUFS) meters in addition to sample peak meters`,
        description: `
            <p>The following standards are supported:</p>
            <ul>
                <li>True peak metering (dbTP) and loudness metering (LUFS) as per ITU-R BS.1770-4 and EBU R128</li>
            </ul>
        `,
        skus: ['professional', 'enterprise'],
        iconName: 'audiotrack'
    },
    {
        id: 'chat-integration',
        entitlementType: 'simple',
        name: 'Chat monitoring',
        summary: `Keep track of chat on multiple platforms. Post messages to all of your chats at once. Monitor and control your moderation bots from with Overview (supports Nightbot and Moobot)`,
        skus: ['professional', 'enterprise'],
        iconName: 'chat'
    },
    {
        id: 'obs-integration',
        entitlementType: 'simple',
        name: 'OBS integration',
        summary: `Monitor your OBS program feed, stream status and metrics. Control streaming, recording and scene changes from within Overview.`,
        skus: ['professional', 'enterprise'],
        iconName: 'videocam'
    },
    {
        id: 'e2e-encryption',
        entitlementType: 'simple',
        name: 'End-to-end encryption',
        summary: `Encrypt all content stored in the cloud. Access and manage your encryption settings using industry-standard WebAuthn authenticators.`,
        skus: ['enterprise'],
        iconName: 'enhanced_encryption'
    },
    {
        id: 'journalling',
        entitlementType: 'simple',
        name: 'Journalling',
        summary: `Record and annotate observations (including video and ancillary data) for review later`,
        skus: ['enterprise'],
        iconName: 'book'
    },
    {
        id: 'gracenote-support',
        entitlementType: 'simple',
        name: 'Gracenote support',
        summary: `Monitor Gracenote-based EPG delivery alongside your regular program feeds. Can be driven by a configured channel TMS ID or via program TMS ID embedded within ancillary data.	`,
        skus: ['enterprise'],
        iconName: 'music_note'

    },
    {
        id: 'fcc-compliance',
        entitlementType: 'simple',
        name: 'FCC compliance',
        summary: `24x7 recording of all input feeds, including ancillary data with ability to rewind and playback.	`,
        skus: ['enterprise'],
        iconName: 'aspect_ratio'
    },
    {
        id: 'monitors',
        entitlementType: 'complex',
        name: 'Monitor multiple sources at once',
        summary: `
            See [System Requirements](/web/requirements) for minimum and 
            recommended hardware requirements.
        `,
        showInFeatures: false,
        showInMatrix: true,
        
        skus: {
            community: {
                value: { monitorsPerBank: 6, banks: 2 },
                description: 'Up to 6 monitors per bank, 2 banks'
            },
            professional: {
                value: { monitorsPerBank: 0, banks: 0 },
                description: 'Unlimited'
            },
            enterprise: {
                value: { monitorsPerBank: 6, banks: 2 },
                description: 'Unlimited'
            }
        }
    }
];