// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable } from '@angular/core';
import { SettingsService, DEFAULT_SETTINGS } from '@/shell-common';
import { AudioController } from './audio-controller';
import { AudioMeterOptions } from './audio-meter-options';

@Injectable()
export class AudioService {
    constructor(
        private settingsService : SettingsService
    ) {

    }

    public getControllerOptions(): AudioMeterOptions {
        let settings = this.settingsService.settings;
        let resolveValue = (settingName) => {
            if (settings[settingName] !== undefined)
                return settings[settingName];
            return DEFAULT_SETTINGS[settingName];
        }

        //console.dir(settings);

        return {
            sampleRate: resolveValue('audioSampleRate'),
            averaging: resolveValue('audioMonitorAveraging'),
            clipLag: resolveValue('audioMonitorClipLag'),
            clipLevel: resolveValue('audioMonitorClipLevel'),
            enableAudioMonitoring: resolveValue('enableAudioMonitoring'),
            enableLUFS: resolveValue('enableLUFS'),
            enableTruePeak: resolveValue('enableTruePeak'),
            rmsWindowTime: resolveValue('rmsWindowTime'),
        };
    }

    public createController(element : HTMLMediaElement | MediaStream) {
        return new AudioController(element, this.getControllerOptions());
    }

    public createControllerWithBufferSource() {
        return AudioController.createWithBufferNode(this.getControllerOptions());
    }

    public async createControllerWithCustomSource(factory : (ctx : AudioContext) => Promise<AudioNode>) {
        return await AudioController.createWithCustomSource(factory, this.getControllerOptions());
    }
}