// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'password-field',
    template: `
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>{{label}}</mat-label>
            <input matInput [attr.type]="revealed ? 'text' : 'password'" [(ngModel)]="value" [attr.name]="name" />
            <button type="button" (click)="revealed = !revealed" matSuffix mat-icon-button>
                <mat-icon>{{ revealed ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
        </mat-form-field>
    `,
    styles: [`
    
    `]
})
export class PasswordFieldComponent {
    revealed = false;

    @Input()
    name : string;

    @Input()
    label : string = 'Password';

    @Input()
    value : string;

    @Output()
    valueChange = new EventEmitter<string>();
}