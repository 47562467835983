// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { ISpliceInfoSection } from '@rezonant/scte35';
import { Subject, Observable } from 'rxjs';
import { environment } from '@/environments/environment';
import { Monitor } from '../monitors/monitor';
import { Span } from '../timeline-data';
import * as SCTE35 from '@astronautlabs/scte35';

export interface Trigger {
    /**
     * ID of this trigger. Can be undefined, which 
     * means the trigger cannot be updated by an incoming
     * event
     */
    id? : string,
    type : 'scte-35' | 'cue-in' | 'cue-out' | 'cue-out-cont';
    timecode : number;
    data : any;
}

export interface Scte35Trigger extends Trigger {
    type : 'scte-35';
    data : SCTE35.SpliceInfoSection;
}

export interface CueOutTrigger extends Trigger {
    type : 'cue-out';
    data : CueOutData;
}

export interface CueOutContTrigger extends Trigger {
    type : 'cue-out-cont';
    data : CueOutContData;
}

export interface CueOutContData {
    
}

export interface Scte35Data {
    
}

export interface CueOutData {
    duration : number;
}

export interface Segment<T = Trigger> {
    identity : string;
    spliceStart : number;
    spliceEnd? : number;
    trigger : T;
}

export class TriggerState {
    activeSegments : Segment[];

    /**
     * Optional one-time (non-segment) trigger
     * which should fire when this state is entered.
     */
    oneTimeTriggers : Trigger[];
    timecodeStart : number;
    timecodeEnd? : number;
}

export class CueState {
    segment : Segment;
    timecodeStart : number;
    timecodeEnd? : number;
}

export class TriggerDispatcher {
    constructor(
        private monitor : Monitor
    ) {

        if (environment.alwaysInAdMode) {
            this.injectTrigger({
                type: 'cue-out',
                timecode: 0,
                data: { duration: 60 * 4 }
            });
        }

        // DEBUG: inject a fake scte-35
        if (environment.injectFakeScte35) {

            this.injectTrigger({
                type: 'scte-35',
                timecode: 0,
                data: {
                    stuff: 123
                }
            });
        }
    }

    _position : number;

    set position(position: number) {
        this._position = position;
    }

    get position() {
        return this._position;
    }

    _stateChanged : Subject<TriggerState> = new Subject<TriggerState>();

    get stateChanged() : Observable<TriggerState> {
        return this._stateChanged;
    }

    _triggerFired : Subject<Trigger> = new Subject<Trigger>();    

    get triggerFired() : Observable<Trigger> {
        return this._triggerFired;
    }
    
    triggers : Trigger[] = [];
    states : TriggerState[];
    
    currentState : TriggerState;
    currentStateIndex : number;
    nextState : TriggerState;

    plannedTriggers : Trigger[];

    findLastSegmentWithIdentity(segments : Segment[], identity : string) {
        if (segments.length == 0)
            return undefined;
        
        for (let i = segments.length - 1; i >= 0; i--) {
            let segment = segments[i];
            if (segment.identity === identity)
                return i;
        }

        return undefined;
    }

    cueStates : CueState[];

    compute() {
        
        //console.log(`Computing timeline for ${this.triggers.length} total triggers...`);

        let pos = this.position;
        let timeSlice = () => new Promise((resolve, reject) => setTimeout(() => resolve()));
        
        let lastState : TriggerState = { 
            timecodeStart: 0,
            oneTimeTriggers: [],
            activeSegments: []
        };

        this.states = [ lastState ];
        this.cueStates = [ ];

        let cueStateStack = [];

        let activeSegments : Segment[] = [];
        for (let trigger of this.triggers) {
            //await timeSlice();
            let identity : string;
            let isSectionTrigger : boolean = false;
            let sectionType : 'start' | 'end' = 'start';

            if (trigger.type === 'cue-in') {
                isSectionTrigger = true;
                identity = 'hls:cue';
                sectionType = 'end';
            } else if (trigger.type === 'cue-out') {
                isSectionTrigger = true;
                identity = 'hls:cue';
                sectionType = 'start';
            } else if (trigger.type === 'cue-out-cont') {
                if (this.findLastSegmentWithIdentity(activeSegments, 'hls:cue') === undefined) {
                    // likely the CUE_OUT event happened before we started
                    // tracking but thats what cue-out-cont is for (I think)
                    // - subsequent cue-out-conts will find the active segment
                    //   and skip creating one
                    // - TODO: this is not right in the case where multiple 
                    //   cues were active before loading. 

                    isSectionTrigger = true;
                    identity = 'hls:cue';
                    sectionType = 'start';
                }
            }

            if (isSectionTrigger) {
                let stateChanged = false;
                if (sectionType === 'start') {
                    stateChanged = true;
                    activeSegments.push({
                        identity,
                        spliceStart: trigger.timecode,
                        spliceEnd: undefined,
                        trigger
                    });
                } else if (sectionType === 'end') {
                    stateChanged = true;
                    let activeSegmentIndex = this.findLastSegmentWithIdentity(activeSegments, identity);
                    activeSegments.splice(activeSegmentIndex, 1);
                }

                if (stateChanged) {
                    let stateSegments = activeSegments.slice();
    
                    if (lastState) {
                        lastState.timecodeEnd = trigger.timecode;
                    }
    
                    let newState : TriggerState = {
                        activeSegments: stateSegments,
                        timecodeStart: trigger.timecode,
                        oneTimeTriggers: [ trigger ]
                    };
    
                    if (sectionType === 'end') {
                        let endedCue = cueStateStack.pop();

                        // It is possible to not have a corresponding cue on 
                        // the stack at this point. Consider the case when
                        // the monitor starts during an ad break- we missed
                        // CUE_OUT, and now we are observing CUE_IN from it.
                        //
                        // TODO: This isn't an error, but we should explore
                        // smarter ways to handle it.

                        if (endedCue) {
                            endedCue.timecodeEnd = trigger.timecode;
                        }

                    } else if (sectionType === 'start') {
                        let cueState = {
                            segment: stateSegments[stateSegments.length - 1],
                            timecodeStart: trigger.timecode
                        }

                        this.cueStates.push(cueState);
                        cueStateStack.push(cueState);
                    }

                    this.states.push(newState);

                    lastState = newState;
                }
            } else {
                let stateSegments = activeSegments.slice();
                let newState : TriggerState = {
                    activeSegments: stateSegments,
                    timecodeStart: trigger.timecode,
                    oneTimeTriggers: [ trigger ]
                };

                this.states.push(newState);
                lastState = newState;
            }
        }

        if (this.monitor && this.monitor.programTimeline) {

            let cueTrack = this.monitor.programTimeline.tracks.find(x => x.id === 'cues');

            cueTrack.spans = cueTrack.spans.filter(x => !x.id.startsWith('cue-state:'));

            for (let state of this.cueStates) {
                let segment = state.segment;

                let triggerJsonBlock = "\n```\n" + JSON.stringify(state.segment.trigger) + "\n```\n";
                let cueSpan : Span = {
                    id: `cue-state:${state.timecodeStart}`,
                    type: 'cue',
                    label: `${segment.identity}`,
                    length: state.timecodeEnd === undefined ? 0 : state.timecodeEnd - state.timecodeStart,
                    unclosed: state.timecodeEnd === undefined,
                    startsAt: state.timecodeStart
                };

                cueSpan['_segment'] = segment;

                this.monitor.programTimeline.addSpan(`cues`, cueSpan);
                
            }
        }

        //console.log(`Produced timeline with ${this.states.length} distinct timeline states.`);
        this.computeStateAfterSeek();
    }

    // TODO: this should have its own outside-angular timing mechanism, not be dependent on calls from outside
    progressPlayback(timecode : number) {
        this.computeIfNeeded();
        this._position = timecode;
        let nextState = this.nextState;

        if (nextState) {
            if (nextState.timecodeStart <= timecode) {
                let newState = nextState;

                for (let trigger of newState.oneTimeTriggers)
                    this._triggerFired.next(trigger);
                
                this.currentStateIndex = this.currentStateIndex + 1;
                this.currentState = nextState;
                this.nextState = this.states[this.currentStateIndex + 1];

                this._stateChanged.next(this.currentState);

                //console.log(`Transitioned to state ${this.currentStateIndex} at timecode ${this._position}`);
                // if (this.nextState) {
                //     console.log(` - Next state is at ${this.nextState.timecodeStart}`);
                // } else {
                //     console.log(` - No state follows this`);
                // }
            }
        }
    }

    computeIfNeeded() {
        if (!this.states)
            this.compute();
    }

    seek(timecode : number, emitEvents : boolean = true) {
        // if (!emitEvents)
        //     console.log(`Recentering state on timecode ${timecode}`);
        // else
        //     console.log(`Seek to ${timecode}`);

        this.computeIfNeeded();
        this._position = timecode;

        this.computeStateAfterSeek();

        if (emitEvents)
            this._stateChanged.next(this.currentState);
    }

    computeStateAfterSeek() {
        //console.log(`Computing state after seek for position ${this._position}...`);
        let nextIndex = this.states.findIndex(x => x.timecodeStart > this._position);
        let currentIndex = nextIndex > 0 ? nextIndex - 1 : 0;
        this.currentStateIndex = currentIndex;
        this.currentState = this.states[this.currentStateIndex];
        this.nextState = (this.currentStateIndex + 1 < this.states.length) 
            ? this.states[this.currentStateIndex + 1]
            : undefined
        ;

        //console.log(`Current state: ${this.currentStateIndex} / ${this.states.length} started at ${this.currentState.timecodeStart} (duration: ${this.currentState.timecodeEnd ? (this.currentState.timecodeEnd - this.currentState.timecodeStart) : 'unknown'})`);
        // if (this.nextState) {
        //     console.log(`- Next state starts at ${this.nextState.timecodeStart}`);
        // } else {
        //     console.log(`- No state follows this.`);
        // }
    }

    injectTrigger(trigger : Trigger) {

        //console.log(`Injecting trigger @ timestamp ${trigger.timecode}`);

        if (this.monitor.programTimeline) {
            let span : Span = {
                id: `${trigger.type}:${trigger.timecode}`,
                type: 'trigger',
                label: `Trigger: ${trigger.type}`,
                startsAt: trigger.timecode,
                length: 0
            };

            span['_trigger'] = trigger;
            
            this.monitor.programTimeline.addSpan('events', span);
        }

        this.triggers.push(trigger);
        this.triggers.sort((a, b) => a.timecode - b.timecode);
        this.compute();
    }
}