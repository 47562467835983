// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { LiveStreamRenderer } from './renderer';
import { EventEmitter, TimelineSpan, Trigger, NetworkRequest, RendererMediaManifest, 
    AudioMeterReading, RendererError, RendererSettings, 
    RENDERER_EVENTS } from './renderer';
import { Base64 } from 'js-base64';
import * as uuidv4 from 'uuid/v4';
import { Observable, Subject } from 'rxjs';
import { AVSession, RTCConnectMessage, AVSignaller, AVSignalSession, IPCMessage, IPCRenderEventMessage } from '@/rtc-ipc';

import { MonitorSource } from '@/overview/monitors';
import { RendererRegistryEntry, RendererType } from './renderer-registry';
import { DefaultRendererFactory } from './default-factory';


export class AVLocalSignalSession implements AVSignalSession {
    constructor(
        readonly id : string,
        readonly remote : Window
    ) {
        this._handler = ev => {
            let envelope : any = ev.data;

            // console.log(`[AVLocalSignalSession] Received message:`);
            // console.dir(envelope);
            
            if (envelope.type === 'envelope') {
                if (envelope.channelId == this.id)
                    this._received.next(envelope.message);
            }
        };

        window.addEventListener('message', this._handler);
    }

    private _handler : (ev : MessageEvent) => void;
    private _received = new Subject<IPCMessage>();

    destroy() {
        window.removeEventListener('message', this._handler);
    }

    send(message: IPCMessage) {
        this.remote.postMessage({
            type: 'envelope',
            channelId: this.id,
            message
        }, '*');
    }

    get received(): Observable<IPCMessage> {
        return this._received;
    }
}

export class AVLocalSignaller implements AVSignaller {
    constructor(
        readonly hostFrame? : HTMLIFrameElement
    ) {
    }

    private _accepted = new Subject<AVSignalSession>();

    listenForSessions() {
        console.log('[AVLocalSignaller] Listening for sessions...');
        window.addEventListener('message', ev => {
            let envelope = ev.data;
            let message = envelope.message;

            if (envelope.type === 'envelope' && message && message.type === 'connect') {
                console.log('[AVLocalSignaller] Received connect request...');
                let connectMsg = <RTCConnectMessage>envelope;
                let channelId = connectMsg.channelId;
                let session = new AVLocalSignalSession(channelId, parent)
                session.send({ type: 'accept' });

                this._accepted.next(session);
            }
        });
    }
    
    async connectToHost(): Promise<AVSignalSession> {
        let channelId = uuidv4();
        
        console.log(`[AVLocalSignaller] Connecting to host`);
        let acceptResolve : Function, acceptReject : Function;
        let acceptPromise = new Promise((resolve, reject) => {
            acceptResolve = resolve;
            acceptReject = reject;
        });

        let signalSession = new AVLocalSignalSession(channelId, this.hostFrame.contentWindow);

        
        // Handle acceptance

        let alreadyAccepted = false;
        signalSession.received.subscribe(message => {
            if (alreadyAccepted)
                return;
            
            if (message.type === 'accept') {
                signalSession.send({ type: 'accepted' });
                alreadyAccepted = true;
                acceptResolve();
            }
        });
        
        signalSession.send(<RTCConnectMessage>{ 
            type: 'connect', 
            channelId
        });

        console.log(`[AVSession] Waiting for AVPublisher to accept...`);
        await acceptPromise;

        console.log(`[AVSession] AVPublisher accepted new session. Sending 'accepted' message...`);

        signalSession.send({ type: 'accepted' });
        
        return signalSession;
    }

    get accepted(): Observable<AVSignalSession> {
        return this._accepted;
    }

    
}

class IsolatedRenderer implements LiveStreamRenderer {
    constructor(
        private element : HTMLElement
    ) {
    }
    
    id = 'local-renderer';
    name = 'Local Renderer (WebRTC)';
    tag: string;

    readonly programSpanUpdated = new EventEmitter<TimelineSpan>();
    readonly logSpanUpdated = new EventEmitter<TimelineSpan>();
    readonly triggerUpdated = new EventEmitter<Trigger>();
    readonly networkRequestUpdated = new EventEmitter<NetworkRequest>();
    readonly manifestChanged = new EventEmitter<RendererMediaManifest>();
    readonly positionProgressed = new EventEmitter<number>();
    readonly statsUpdated = new EventEmitter<string>();
    readonly audioMeterReadings = new EventEmitter<AudioMeterReading>();
    readonly muteChanged = new EventEmitter<boolean>();
    readonly errors = new EventEmitter<RendererError>();
    readonly warnings = new EventEmitter<RendererError>();
    readonly seeked = new EventEmitter<number>();
    readonly supportsStream = true;
    readonly mediaStreamChanged = new EventEmitter<MediaStream>();
    
    private _mediaStream : MediaStream;
    get mediaStream() {
        return this._mediaStream;
    }

    async mute(): Promise<void> {
        this.iframe.contentWindow.postMessage({
            type: 'envelope',
            channelId: `renderer:${this.source.$uuid}`,
            message: {
                type: 'renderer-mute'
            }
        }, '*');
    }

    async unmute(): Promise<void> {
        this.iframe.contentWindow.postMessage({
            type: 'envelope',
            channelId: `renderer:${this.source.$uuid}`,
            message: {
                type: 'renderer-unmute'
            }
        }, '*');
    }

    async seek(position: number): Promise<void> {
        this.iframe.contentWindow.postMessage({
            type: 'envelope',
            channelId: `renderer:${this.source.$uuid}`,
            message: {
                type: 'renderer-seek',
                position
            }
        }, '*');
    }

    source : MonitorSource;
    rendererEntry : RendererRegistryEntry;
    videoElement : HTMLVideoElement;
    canvasElement : HTMLCanvasElement;
    canvasContext : CanvasRenderingContext2D;
    iframe : HTMLIFrameElement;

    private _messageHandler : (ev : MessageEvent) => void;

    async start(source: MonitorSource, settings: RendererSettings) {
        let enableCapture = false;
        let enableCapturePlayout = false;
        
        console.log(`** Starting IsolatedRenderer/WebRTC for ${source.type}/${source.$uuid}`);

        this.source = source;
        this.rendererEntry = DefaultRendererFactory.all.find(x => x.id === source.type);
        this.name = `${this.rendererEntry.name} [Isolated]`;
        this.id = `${this.rendererEntry.id}/isolated`;

        // Common parent element 

        let parentElement = document.createElement('div');
        parentElement.classList.add('remote-renderer-webrtc');
        Object.assign(parentElement.style, {
            position: 'relative'
        });
        this.element.appendChild(parentElement);

        this._contextId = `renderer:${source.$uuid}`;

        // Iframe

        let encodedSource = Base64.encode(JSON.stringify(this.source));
        this.iframe = document.createElement('iframe');
        Object.assign(this.iframe.style, {
            width: '100%',
            height: '100%',
            border: 'none'
        });
        this.iframe.src = `?render=${encodeURIComponent(encodedSource)}&capture=${enableCapture ? 1 : 0}`;

        let readyPromise = new Promise((resolve, reject) => {
            this._messageHandler = ev => {
                if (ev.data.type === 'envelope' && ev.data.channelId === this._contextId) {
                    let envelope = ev.data;
                    let message = envelope.message;

                    if (message.type === 'renderer-ready') {
                        resolve();
                    } else if (message.type === 'renderer-event') {
                        let renderEvent = <IPCRenderEventMessage>message;

                        if (!RENDERER_EVENTS.includes(renderEvent.name))
                            return;

                        if (this[renderEvent.name])
                            this[renderEvent.name].next(renderEvent.data);
                    }
                }
            };
            
            window.addEventListener('message', this._messageHandler);
        });
        
        this.element.appendChild(this.iframe);

        console.log(`[IsolatedRenderer] Waiting for context ${this._contextId} to be ready...`);
        await readyPromise;
        console.log(`[IsolatedRenderer] Context ${this._contextId} is ready.`);

        // the renderer should be ready now

        if (enableCapture) {
            
            if (enableCapturePlayout) {
                this.iframe.style.display = 'none';
    
                // Video 
        
                this.videoElement = document.createElement('video');
                Object.assign(this.videoElement.style, {
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                });
                parentElement.appendChild(this.videoElement);
            }

            console.log(`[IsolatedRenderer/WebRTC] Establishing AVSession...`);
            let session = new AVSession(new AVLocalSignaller(this.iframe));

            session.renderEvents.subscribe(ev => {
                console.log(`[IsolatedRenderer/WebRTC] Received event ${ev.name}: ${JSON.stringify(ev.data)}`);

                // Ensure we allow this event
                if (!RENDERER_EVENTS.includes(ev.name))
                    return;
                
                if (this[ev.name]) {
                    this[ev.name].next(ev.data);
                } 
            });

            session.streamChanged.subscribe(stream => {
                if (stream) {
                    console.log(`[IsolatedRenderer/WebRTC] Received new MediaStream from AVSession`);

                    if (enableCapturePlayout) {
                        this.videoElement.srcObject = session.stream;
                        this.videoElement.onloadedmetadata = () => {
                            this.videoElement.play();
                        };
                    }
                }

                this._mediaStream = stream;
                this.mediaStreamChanged.next(this._mediaStream);
            });

            console.log(`[IsolatedRenderer/WebRTC] Connecting to AVSession...`);
            await session.connect();

            console.log(`[IsolatedRenderer/WebRTC] Connected to AVSession`);
        }
    }

    private framesRendered = 0;
    private secondStarted = 0;

    private _contextId : string;
    private _destroyed = false;

    async destroy(): Promise<void> {
        if (this._destroyed)
            return;

        window.removeEventListener('message', this._messageHandler);
        console.log(`Destroying remote renderer with source ${this.source.$uuid}`);
        this._destroyed = true;
        this.canvasContext = null;
        this.videoElement.remove();
        this.videoElement = null;

        throw new Error('TODO');
    }

    focused() {

    }

    unfocused() {

    }
}

export function LocalRendererInterceptor(entry : RendererRegistryEntry, chosenRendererClass : RendererType, source : MonitorSource) {
    if (source.isolationMode === 'isolated' && entry.renderer)
        return IsolatedRenderer;
    
    return chosenRendererClass;
}
