
<mat-menu #appMenu="matMenu">
  <button *ngFor="let bank of banks" mat-menu-item (click)="switchToBank(bank)">
    <mat-icon><i class="fas fa-th-large"></i></mat-icon>
    {{bank.name}}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/app/banks">
    <mat-icon>settings</mat-icon>
    Manage Banks
  </button>
</mat-menu>
  
<header>
  <div class="back-action">
    <a *ngIf="focusedItem" href="javascript:;" mat-button (click)="unfocus()">
      <mat-icon><i class="fas fa-arrow-left"></i></mat-icon>
      {{currentBank?.name || 'All'}}
    </a>
    <ng-container *ngIf="!focusedItem">
      <a *ngIf="isOverview" mat-button [matMenuTriggerFor]="appMenu">
        <mat-icon><i class="fas fa-th-large"></i></mat-icon>
        {{currentBank?.name || 'All'}}
      </a>
      <a *ngIf="!isOverview" mat-button routerLink="/app">
        <mat-icon><i class="fas fa-arrow-left"></i></mat-icon>
        App
      </a>
    </ng-container>
  </div>

  <span class="logo-container">
    <a *ngIf="isOverview" href="javascript:;" (click)="unfocus()">
      <img class="icon" src="assets/icon2.svg" />
      <ov-logo class="logo"></ov-logo>
    </a>
    <a *ngIf="!isOverview" routerLink="/app">
      <img class="icon" src="assets/icon2.svg" />
      <ov-logo class="logo"></ov-logo>
    </a>
  </span>
  
  <span class="upper-right-c2a">
    <ala-account-status>
      <button mat-menu-item routerLink="/app/settings">
        <mat-icon><i class="fas fa-cog"></i></mat-icon>
        <span>Settings</span>
      </button>
      <button mat-menu-item routerLink="/app/extensions">
        <mat-icon><i class="fas fa-puzzle-piece"></i></mat-icon>
        <span>Extensions</span>
      </button>
      <button class="after" mat-menu-item routerLink="/app/about">
        <mat-icon><i class="fas fa-circle-notch"></i></mat-icon>
        About Overview
      </button>
    </ala-account-status>
    <ng-container *ngIf="!user">
      <button mat-icon-button [matMenuTriggerFor]="anonymousMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-container>
  </span>

  <mat-menu #anonymousMenu="matMenu">
    <button mat-menu-item routerLink="/app/settings">
      <mat-icon><i class="fas fa-cog"></i></mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item routerLink="/app/extensions">
      <mat-icon><i class="fas fa-puzzle-piece"></i></mat-icon>
      <span>Extensions</span>
    </button>
    <button class="after" mat-menu-item routerLink="/app/about">
      <mat-icon><i class="fas fa-circle-notch"></i></mat-icon>
      About Overview
    </button>
  </mat-menu>

</header>
<main>
  <app-shell-host [appStarter]="appStarter"></app-shell-host>
  
  <div *ngIf="unlicensed" class="license-shield">
    <video src="https://overview-media.astrocdn.com/earth.720p.mp4" loop muted autoplay>
    </video>
    
    <ala-license-required [expired]="true"></ala-license-required>
  </div>
  <div #pageView class="page-view" [class.visible]="pageVisible" [class.web]="isWeb" [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet></router-outlet>
  </div>
</main>

<slvr-starfield *ngIf="hasStarfieldBackdrop" [speed]="0.2"></slvr-starfield>


<div id="error-page" *ngIf="unhandledError">
  <div class="error-dialog">
    <h1>An error has occurred</h1>
    <mat-tab-group>
      <mat-tab label="Summary">
        <p class="message">{{unhandledErrorMessage}}</p>
      </mat-tab>
      <mat-tab label="Details">
        <pre class="details">{{unhandledErrorDetails}}</pre>
      </mat-tab>
      <mat-tab label="Properties">
        <div class="kv-container" *ngFor="let key of unhandledErrorKeys">
          <label class="key">{{key}}</label>
          <pre>{{getJsonValue(unhandledError[key], key)}}</pre>
        </div>
      </mat-tab>
    </mat-tab-group>

    <hr/>

    <div style="text-align: right;">
      <a mat-raised-button color="primary" href="javascript:;" (click)="dismissError()">
        Report
      </a>

      &nbsp;

      <a mat-button href="javascript:;" (click)="dismissError()">
        Dismiss
      </a>
    </div>
  </div>

</div>
