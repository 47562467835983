// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Subject, Observable } from 'rxjs';

/**
 * Handle failures related to lack of user gesture in a central place.
 */
export class UserGestureService {
    constructor() {

    }

    private _awaitingUserGestures : Function[] = [];
    private _needsUserGesture : Subject<void> = new Subject<void>();

    get needsUserGesture(): Observable<void> {
        return this._needsUserGesture;
    }

    private _isReported = false;
    private _isFulfilling = false;

    fulfill() {
        this._isReported = false;
        this._isFulfilling = true;

        let current = this._awaitingUserGestures;
        this._awaitingUserGestures = [];
        current.forEach(x => x());

        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                this._isFulfilling = false;
                resolve();

                if (this._awaitingUserGestures.length > 0) {
                    alert("Could not perform actions requiring a user-gesture! Please report this bug.");
                    this._needsUserGesture.next();
                }
            }, 1000);
        });
    }

    reportGestureFailure(e : DOMException, callback : Function) {
        this._awaitingUserGestures.push(callback);

        if (!this._isFulfilling && !this._isReported) {
            this._isReported = true;
            this._needsUserGesture.next();
        }
    }
}