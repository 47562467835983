// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, ElementRef } from '@angular/core';

@Component({
    selector: 'ov-logo',
    template: ``,
    styleUrls: ['./overview-logo.component.scss']
})
export class OverviewLogoComponent {
    constructor(
        private elementRef : ElementRef<HTMLElement>
    ) {
        this.elementRef.nativeElement.innerHTML = `
        <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   sodipodi:docname="logo.svg"
   inkscape:version="1.0 (4035a4fb49, 2020-05-01)"
   id="svg3802"
   version="1.1"
   viewBox="0 0 285.38977 94.98542"
   height="94.98542mm"
   width="285.38977mm">
  <defs
     id="defs3796" />
  <sodipodi:namedview
     inkscape:window-maximized="1"
     inkscape:window-y="-11"
     inkscape:window-x="3829"
     inkscape:window-height="2066"
     inkscape:window-width="3840"
     fit-margin-bottom="0"
     fit-margin-right="0"
     fit-margin-left="0"
     fit-margin-top="0"
     showgrid="false"
     inkscape:document-rotation="0"
     inkscape:current-layer="layer1"
     inkscape:document-units="mm"
     inkscape:cy="358.39278"
     inkscape:cx="617.39334"
     inkscape:zoom="0.98994949"
     inkscape:pageshadow="2"
     inkscape:pageopacity="0.0"
     borderopacity="1.0"
     bordercolor="#666666"
     pagecolor="#ffffff"
     id="base" />
  <metadata
     id="metadata3799">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     transform="translate(76.946575,-73.847653)"
     id="layer1"
     inkscape:groupmode="layer"
     inkscape:label="Layer 1">
    <g
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;line-height:1.25;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;letter-spacing:0px;word-spacing:0px;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke:none;stroke-width:1.26213"
       id="text3742-5"
       aria-label="overview">
      <path
         id="path4382"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 44.618491,134.13807 q -4.74559,0 -7.26984,-3.43298 -2.473766,-3.43298 -2.473766,-9.94555 0,-6.56305 2.473766,-10.097 2.52425,-3.53395 7.26984,-3.53395 4.695105,0 7.118385,3.53395 2.473765,3.48347 2.473765,10.097 0,6.56305 -2.42328,9.99603 -2.42328,3.3825 -7.16887,3.3825 z m -0.10097,-3.63492 q 2.675705,0 3.93783,-2.3728 1.31261,-2.42328 1.31261,-7.47178 0,-5.0485 -1.31261,-7.47178 -1.262125,-2.42328 -3.93783,-2.42328 -2.675705,0 -3.93783,2.42328 -1.21164,2.3728 -1.21164,7.47178 0,5.0485 1.21164,7.47178 1.262125,2.3728 3.93783,2.3728 z" />
      <path
         id="path4384"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 61.972211,133.93613 q -1.61552,0 -1.867945,-0.80776 l -6.209655,-24.2328 q -0.10097,-0.50485 -0.10097,-0.70679 0,-0.85825 1.968915,-0.85825 h 0.656305 q 1.61552,0 1.81746,0.80776 l 3.483465,14.74162 q 0.353395,1.41358 1.060185,5.30093 l 0.20194,1.21164 h 0.30291 l 0.353395,-2.27183 q 0.10097,-0.70679 0.30291,-2.0194 0.252425,-1.31261 0.454365,-2.22134 l 3.533951,-14.74162 q 0.20194,-0.80776 1.867945,-0.80776 h 0.60582 q 1.81746,0 1.81746,0.85825 0,0.10097 -0.10097,0.70679 l -6.209655,24.2328 q -0.20194,0.80776 -1.817461,0.80776 z" />
      <path
         id="path4386"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 82.433125,134.13807 q -5.35141,0 -7.97663,-3.28153 -2.574735,-3.28152 -2.574735,-10.04651 0,-6.71451 2.52425,-10.19797 2.52425,-3.48347 7.421295,-3.48347 4.392195,0 6.613535,3.3825 2.22134,3.33201 2.22134,9.79409 0,0.45436 -0.60582,1.26212 -0.60582,0.80776 -1.060185,0.80776 H 76.42541 q 0.252425,4.29123 1.61552,6.20966 1.363095,1.91843 4.34171,1.91843 1.81746,0 3.0291,-0.50485 1.21164,-0.50485 1.968915,-1.06019 0.757275,-0.55533 0.90873,-0.55533 0.50485,0 1.11067,1.0097 0.656305,1.0097 0.656305,1.51455 0,0.60582 -1.060185,1.41358 -1.0097,0.75727 -2.776675,1.31261 -1.766975,0.50485 -3.786375,0.50485 z m 3.73589,-15.39793 q 0,-7.97663 -4.493165,-7.97663 -4.84656,0 -5.25044,7.97663 z" />
      <path
         id="path4388"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 94.291599,133.93613 q -2.0194,0 -2.0194,-1.11067 v -17.82121 q 0,-3.33201 -0.30291,-4.74559 -0.30291,-1.46406 -0.30291,-1.61552 0,-0.60582 0.959215,-0.85824 0.959215,-0.30291 1.766975,-0.30291 0.70679,0 1.0097,0.75727 0.353395,0.75728 0.50485,1.61552 0.20194,0.80776 0.252425,1.06019 2.42328,-3.78638 5.300926,-3.78638 1.26212,0 1.91843,0.55534 0.70679,0.55533 0.70679,1.41358 0,0.85824 -0.30291,1.81746 -0.30291,0.90873 -0.70679,0.90873 -0.25243,0 -0.90873,-0.25243 -0.65631,-0.30291 -1.56504,-0.30291 -1.312606,0 -2.524246,1.11067 -1.161155,1.06019 -1.565035,1.96892 v 18.47751 q 0,1.11067 -2.0194,1.11067 z" />
      <path
         id="path4390"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 113.91881,133.93613 q -1.61552,0 -1.86794,-0.80776 l -6.20966,-24.2328 q -0.10097,-0.50485 -0.10097,-0.70679 0,-0.85825 1.96892,-0.85825 h 0.6563 q 1.61552,0 1.81746,0.80776 l 3.48347,14.74162 q 0.35339,1.41358 1.06018,5.30093 l 0.20194,1.21164 h 0.30291 l 0.3534,-2.27183 q 0.10097,-0.70679 0.30291,-2.0194 0.25242,-1.31261 0.45436,-2.22134 l 3.53395,-14.74162 q 0.20194,-0.80776 1.86795,-0.80776 h 0.60582 q 1.81746,0 1.81746,0.85825 0,0.10097 -0.10097,0.70679 l -6.20966,24.2328 q -0.20194,0.80776 -1.81746,0.80776 z" />
      <path
         id="path4392"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 128.24134,103.19076 q -2.22134,0 -2.22134,-1.11067 v -2.574731 q 0,-1.11067 2.32231,-1.11067 2.22134,0 2.22134,1.11067 v 2.574731 q 0,1.11067 -2.32231,1.11067 z m -0.10097,30.74537 q -2.0194,0 -2.0194,-1.11067 V 108.4412 q 0,-1.11067 2.0194,-1.11067 h 0.20194 q 2.0194,0 2.0194,1.11067 v 24.38426 q 0,1.11067 -2.0194,1.11067 z" />
      <path
         id="path4394"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 142.70806,134.13807 q -5.35141,0 -7.97663,-3.28153 -2.57474,-3.28152 -2.57474,-10.04651 0,-6.71451 2.52425,-10.19797 2.52425,-3.48347 7.4213,-3.48347 4.39219,0 6.61353,3.3825 2.22134,3.33201 2.22134,9.79409 0,0.45436 -0.60582,1.26212 -0.60582,0.80776 -1.06018,0.80776 h -12.57077 q 0.25243,4.29123 1.61552,6.20966 1.3631,1.91843 4.34171,1.91843 1.81746,0 3.0291,-0.50485 1.21164,-0.50485 1.96892,-1.06019 0.75727,-0.55533 0.90873,-0.55533 0.50485,0 1.11067,1.0097 0.6563,1.0097 0.6563,1.51455 0,0.60582 -1.06018,1.41358 -1.0097,0.75727 -2.77668,1.31261 -1.76697,0.50485 -3.78637,0.50485 z m 3.73589,-15.39793 q 0,-7.97663 -4.49317,-7.97663 -4.84656,0 -5.25044,7.97663 z" />
      <path
         id="path4396"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:50.485px;font-family:'Asap Condensed';-inkscape-font-specification:'Asap Condensed, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;fill:#0059b1;fill-opacity:1;stroke-width:1.26213"
         d="m 158.11827,133.93613 q -1.666,0 -1.81746,-0.80776 l -5.35141,-23.92989 q -0.15145,-0.90873 -0.15145,-1.0097 0,-0.85825 2.06988,-0.85825 2.17086,0 2.32231,0.80776 l 2.82716,16.10472 0.25243,1.21164 0.75727,4.13977 h 0.25243 q 0.15145,-1.0097 1.0097,-5.4019 l 3.07958,-16.00374 q 0.20194,-0.85825 1.76698,-0.85825 h 0.95921 q 1.61552,0 1.81746,0.85825 l 3.13007,16.00374 0.30291,1.46407 q 0.50485,2.37279 0.70679,3.88734 h 0.25243 l 0.55533,-3.07958 q 0.30291,-1.16116 0.45437,-2.22134 l 2.77667,-16.10472 q 0.15146,-0.80776 2.32231,-0.80776 1.96892,0 1.96892,0.85825 l -0.10097,0.85824 -5.4019,24.08135 q -0.15145,0.80776 -1.81746,0.80776 h -1.71649 q -1.666,0 -1.81746,-0.80776 l -3.0291,-14.89308 q -0.55533,-2.92813 -0.75727,-4.49316 h -0.20194 q -0.15146,1.56503 -0.75728,4.49316 l -3.07958,14.89308 q -0.20194,0.80776 -1.81746,0.80776 z" />
    </g>
    <path
       id="path5065-5-0"
       d="m -32.440953,79.15516 -3.128236,0.956056 c -6.530877,1.99534 -11.990298,5.28941 -16.967456,10.237726 -6.689825,6.650934 -10.221696,13.317478 -12.280846,23.181148 -0.559251,2.67941 -0.465158,11.65292 0.155759,14.87085 2.194701,11.37424 8.693941,21.03518 18.674336,27.75676 4.834863,3.25625 11.21111,5.60569 17.641347,6.50048 3.08706,0.42954 10.815265,0.11712 14.053906,-0.56831 8.2506553,-1.74617 15.13247577,-5.51852 21.2469621,-11.64703 6.1659359,-6.18005 10.0019009,-13.23575 11.6738419,-21.46961 0.934776,-4.60818 0.930599,-12.66173 -0.0099,-17.02497 C 16.799437,103.50933 13.177058,96.898022 6.935628,90.625745 1.8167371,85.481595 -3.5422685,82.206152 -10.215047,80.141978 c -3.304766,-1.022491 -4.385226,-1.012105 -5.744973,0.05749 -0.05625,0.04425 -0.114836,0.104704 -0.173143,0.161149 a 35.862852,35.862852 0 0 1 26.871567,34.671063 35.862852,35.862852 0 0 1 -35.863055,35.86311 35.862852,35.862852 0 0 1 -35.863168,-35.86311 35.862852,35.862852 0 0 1 29.817967,-35.346985 z"
       style="fill:#0059b1;fill-opacity:1;stroke-width:0.291589"
       inkscape:connector-curvature="0" />
  </g>
</svg>

        `;
    }
}