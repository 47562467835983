// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AudioController } from './audio-controller';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService, SubscriptionSet, OverviewSettings, DEFAULT_SETTINGS } from '@/shell-common';

@Component({
    selector: 'ov-volume-meter',
    template: `
        <canvas #canvas></canvas>
    `,
    styles: [`
        :host {
            display: block;
            width: 3px;
            position: relative;
            border-bottom: 3px solid white;
            pointer-events: none;
        }

        canvas {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .indicator {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 20%;
            background: yellow;
            z-index: 1;
        }
    `]
})
export class VolumeMeterComponent {
    constructor(
        private domSanitizer : DomSanitizer,
        private ngZone : NgZone,
        private settingsService : SettingsService
    ) {
        this.subscriptions.subscribe(this.settingsService.settingsChanged, settings => this.settings = settings);
    }

    settings : OverviewSettings;
    subscriptions = new SubscriptionSet();

    @Input()
    controller : AudioController;


    get effectiveAveraging() {
        if (this.controller) {
            return this.controller.averaging;
        }

        return this.averaging;
    }

    @Input()
    mode : string;

    @Input()
    pollTime : number = 250;

    @Input()
    channel : number;

    @Input()
    big : boolean = false;

    @ViewChild('canvas')
    canvas : ElementRef<HTMLCanvasElement>;
    
    private interval : any;

    canvasContext : CanvasRenderingContext2D;

    get loudLevel() : number {
        let def = DEFAULT_SETTINGS.audioMonitorLoudLevel;

        if (!this.settings)
            return def;
        
        return this.dbfsToSample(this.settings.audioMonitorLoudLevel || def);
    }

    get veryLoudLevel() : number {
        let def = DEFAULT_SETTINGS.audioMonitorVeryLoudLevel;

        if (!this.settings)
            return def;

        return this.dbfsToSample(this.settings.audioMonitorVeryLoudLevel || def);
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => this.renderFrame());
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    peakLevel : number = 0;
    maxPeakLevel : number = 0;

    private drawKMeter(
        context : CanvasRenderingContext2D,
        x : number, 
        y : number, 
        width : number, 
        height : number,
        level? : number, 
        options : { 
            anchorPoint? : number, 
            loud? : number, 
            veryLoud? : number, 
            quietStyle? : string,
            loudStyle? : string,
            veryLoudStyle? : string
        } = {}
    ) {

        let stack = [ 
            { style: options.quietStyle || 'green', max: options.loud || this.loudLevel },
            { style: options.loudStyle || 'yellow', max: options.veryLoud - options.loud || this.veryLoudLevel },
            { style: options.veryLoudStyle || 'red', max: 1 }
        ];

        let cursor = y + height;
        let remainingLevel = level;
        let previousTier = 0;

        for (let section of stack) {
            let contribution = Math.min(section.max - previousTier, remainingLevel);
            remainingLevel -= contribution;

            let sectionHeight = height * contribution;
            let y = cursor - sectionHeight;

            context.fillStyle = section.style;
            context.fillRect(x, y, width, sectionHeight);

            cursor = y;
            previousTier = section.max;

            if (remainingLevel <= 0)
                break;
        }
    }

    volumeToDBFS(volume : number) {
        return 20 * Math.log10(Math.abs(volume));
    }

    dbfsToVolume(dbfs : number) {
        return 0;
    }

    holdMaxPeakUntil : number = 0;

    @Input() 
    averaging : number = 0.95;

    rms : number;

    renderCanvas() {
        if (!this.canvas)
            return;

        let peakLevel = 0;
        let rms = 0;
        let unit = 'dB FS';
        let peakUnit = unit;

        // Pull basic peak/RMS 

        if (this.mode === 'loudness') {
            unit = 'LU FS';
            peakUnit = 'LU FS';

            if (this.controller && this.controller.loudness) {
                peakLevel = this.dbfsToSample(this.controller.loudness.shortTermLoudness);
                rms = this.dbfsToSample(this.controller.loudness.integratedLoudness);
            }
        } else {
            let state = this.controller.channels[this.channel];

            if (!state)
                return;

            rms = state.rms;

            if (this.controller.truePeak) {
                peakLevel = this.controller.truePeak.channels[this.channel];
                peakUnit = 'dB TP';
            } else {
                peakLevel = state.peakLevel;
            }
        }

        // Peak Level 

        this.peakLevel *= this.effectiveAveraging;
        if (peakLevel > this.peakLevel)
            this.peakLevel = peakLevel;

        this.rms = rms;

        // Max Peak Level 

        let now = Date.now();
        if (this.holdMaxPeakUntil < now) {
            if (this.maxPeakLevel > 0) {
                this.maxPeakLevel -= 0.005;
            }
        }

        if (peakLevel > this.maxPeakLevel) {
            this.holdMaxPeakUntil = now + 2000;
            this.maxPeakLevel = peakLevel;
        }

        let canvas = this.canvas.nativeElement;
        if (canvas.width != canvas.offsetWidth || canvas.height != canvas.offsetHeight) {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            this.canvasContext = null;
        }

        let w = canvas.width;
        let h = canvas.height;

        let notchHeight = 3;
        
        if (!this.canvasContext)
            this.canvasContext = this.canvas.nativeElement.getContext('2d');

        let ctx = this.canvasContext;
        ctx.fillStyle = "black";
        ctx.font = '12pt sans-serif';
        ctx.fillRect(0, 0, w, h);

        let peakHeight = this.peakLevel * h;
        
        let x = 0;
        let barWidth = w;
        let lineHeight = 25;
        let widgetWidth = w;
        let scaleWidth = 30;
        let meterCenter = scaleWidth + w / 6;
        let rmsWidth = 30;
        let y = 0;

        if (this.big) {
            y += lineHeight;
            h -= lineHeight*2 - 5;

            w = rmsWidth;
            //h -= lineHeight * 3;

            barWidth = rmsWidth;
            x = meterCenter - barWidth / 2.0;
            
            this.drawKMeter(ctx, x, y, barWidth, h, 1, {
                quietStyle: 'rgb(0, 24, 0)',
                loudStyle: 'rgb(24, 24, 0)',
                veryLoudStyle: 'rgb(24, 0, 0)'
            });
            // ctx.fillStyle = '#080808';
            // ctx.fillRect(x, 0, barWidth, h);

            this.drawKMeter(ctx, x, y, barWidth, h, this.rms, {
                quietStyle: 'rgb(0, 54, 0)',
                loudStyle: 'rgb(92, 92, 0)',
                veryLoudStyle: 'rgb(65, 0, 0)',
            });

            let peakWidth = 10;
            x = meterCenter - peakWidth / 2;
            barWidth = peakWidth;


            let dbScaleBottom = -20;
            let dbScaleMax = 0;
            let notchCount = 10;
            let notchSize = 2;

            ctx.textAlign = 'right';
            ctx.fillStyle = '#555';
            let scaleX = meterCenter - rmsWidth / 2 - 5;

            let steps = [ -48, -21, -15, -12, -9, -6, -3, -1, 0 ]

            for (let step = 0, max = steps.length; step <= max; ++step) {
                let i = steps[step];
                let sampleRep = this.dbfsToSample(i);
                let dbStr : string;

                dbStr = i+'';

                ctx.fillText(dbStr, scaleX, y + h - h*sampleRep, scaleWidth);
            }
            ctx.textAlign = 'left';

            
            this.drawKMeter(ctx, x, y, barWidth, h, 1, {
                quietStyle: 'rgb(0, 48, 0)',
                loudStyle: 'rgb(48, 48, 0)',
                veryLoudStyle: 'rgb(48, 0, 0)'
            });

            // ctx.fillStyle = '#111';
            // ctx.fillRect(x, 0, barWidth, h);

        }

        // clip max notch
        // ctx.fillStyle = 'maroon';
        // ctx.fillRect(x, h - h * this.controller.clipLevel, barWidth, notchHeight);

        this.drawKMeter(ctx, x, y, barWidth, h, this.peakLevel, {
            quietStyle: 'rgb(0, 187, 0)',
            loudStyle: 'rgb(176, 179, 0)'
        });

        // max peak fading notch 

        let maxPeakHeight = this.maxPeakLevel * h;
        ctx.fillStyle = this.colorPeakLevel(this.maxPeakLevel);
        ctx.fillRect(x, y + h - maxPeakHeight, barWidth, notchHeight);

        if (this.big) {
            let indent = rmsWidth / 2 - 3;
            let maxPeakLevelY = Math.max(y, y + h - maxPeakHeight + 2);
            ctx.fillText(Math.round(this.dbfs(this.maxPeakLevel)) + ' ' + peakUnit, x + barWidth + indent, maxPeakLevelY);
            
            if (this.rms > 0) {
                ctx.fillStyle = this.colorPeakLevel(this.rms);
                ctx.fillText(Math.round(this.dbfs(this.rms)) + ' ' + unit, x + barWidth + indent, y + h - this.rms*h + 2);
            }

            ctx.textAlign = 'center';

            let label = '(ch ' + (this.channel + 1) + ')';

            if (this.mode === 'loudness') {
                label = '(lufs)';
            }

            ctx.fillText(label, meterCenter, y + h + lineHeight - 8);
            ctx.textAlign = 'left';

            // ctx.fillStyle = this.colorPeakLevel(this.peakLevel);
            // ctx.fillText(this.twoPlaces(this.dbfs(this.peakLevel)) + ' dB', x - 20, h + lineHeight*2);
        }
    }

    dbfsToSample(dbfs : number) {
        return Math.pow(10, dbfs / 20.0);
    }

    dbfs(sample : number) {
        return 20*Math.log10(Math.abs(sample));
    }

    twoPlaces(i : number) {
        return Math.round(i * 100.0) / 100.0;
    }

    colorPeakLevel(peakLevel) {
        if (peakLevel > 0.95)
            return 'red';
        else if (peakLevel > 0.75) 
            return 'yellow';
        else
            return 'green';
    }

    renderFrame() {
        this.renderCanvas();
        requestAnimationFrame(this.renderFrame.bind(this));
    }
    
    _indicatorColor : string;

    get indicatorColor() {
        return this._indicatorColor;
    }
}