// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { AVSignaller, AVSignalSession, IPCMessage, RTCConnectMessage, IPCRequest } from './rtc-ipc';
import { Subject, Observable } from 'rxjs';

import * as uuidv4 from 'uuid/v4';
import { IpcRenderer } from 'electron';

export class IPCChannelSender {
    constructor(private id : string) {
        this.ipcRenderer = window['require']('electron').ipcRenderer;
    }

    private ipcRenderer : IpcRenderer;

    async sendRequest<TRequest, TResponse>(request : TRequest): Promise<TResponse> {
        return await new Promise<TResponse>((resolve, reject) => {
            let requestId = uuidv4();
            let responseHandler : (...args: any[]) => void; 

            responseHandler = (ev, response : TResponse) => {
                resolve(response);
                this.ipcRenderer.removeListener(`response:${requestId}`, responseHandler);
            };

            this.ipcRenderer.addListener(`response:${requestId}`, responseHandler);

            this.sendBroadcast({
                type: 'request',
                requestId,
                request: request
            });
        });
    }

    async sendBroadcast<TMessage extends IPCMessage>(message : TMessage) {
        this.ipcRenderer.send('peer-to-peer', {
            type: 'message',
            channelId: this.id,
            message
        });
    }
}

export class IPCChannelReceiver {
    private ipcRenderer : IpcRenderer;

    constructor(readonly id : string) {
        this.ipcRenderer = window['require']('electron').ipcRenderer;

        this.ipcRenderer.on(id, async (ev, message) => {
            //console.log(`IPC/recv(${id}): ${message.type}`);
            if (message.type === 'request') {
                let request = <IPCRequest<any>>message;
                let response = await this.handleRequest(request.request);
                ev.sender.send(`response:${message.requestId}`, response);
                return;
            }

            this._broadcastReceived.next(message);
        });

        console.log(`[IPC/renderer] Subscribing to channel ${id}...`);
        this.ipcRenderer.send('peer-to-peer', {
            type: 'subscribe',
            channelId: id
        });
    }

    destroy() {
        console.log(`[IPC/renderer] Unsubscribing from channel ${this.id}...`);
        this.ipcRenderer.send('peer-to-peer', {
            type: 'unsubscribe',
            channelId: this.id
        });
    }

    private _broadcastReceived = new Subject<IPCMessage>();
    get broadcastReceived(): Observable<IPCMessage> {
        return this._broadcastReceived;
    }

    public handleRequest : (request : IPCMessage) => Promise<IPCMessage>;
}

export class AVElectronSignaller implements AVSignaller {
    constructor(readonly id : string) {

    }

    private _accepted = new Subject<AVSignalSession>();

    get accepted() : Observable<AVSignalSession> {
        return this._accepted;
    }

    async connectToHost() {
        let sessionId = uuidv4();

        console.log(`[AVSession] Connecting to AVPublisher with ID '${this.id}'`);
        let acceptResolve : Function, acceptReject : Function;
        let acceptPromise = new Promise((resolve, reject) => {
            acceptResolve = resolve;
            acceptReject = reject;
        });

        let signalSession = new AVElectronSignalSession(`av-session:${sessionId}`, `av-session-host:${sessionId}`);

        // Handle acceptance

        let alreadyAccepted = false;
        signalSession.received.subscribe(message => {
            if (alreadyAccepted)
                return;
            
            if (message.type === 'accept') {
                signalSession.send({ type: 'accepted' });
                alreadyAccepted = true;
                acceptResolve();
            }
        });
        
        // Request a new session 

        new IPCChannelSender(this.id)
            .sendBroadcast(<RTCConnectMessage>{
                type: 'connect',
                channelId: sessionId
            })
        ;

        console.log(`[AVSession -> ${this.id}] Waiting for AVPublisher to accept...`);
        await acceptPromise;

        console.log(`[AVSession -> ${this.id}] AVPublisher accepted new session. Sending 'accepted' message...`);

        signalSession.send({
            type: 'accepted'
        });

        return signalSession;
    }

    listenForSessions() {
        let receiver = new IPCChannelReceiver(this.id);

        receiver.broadcastReceived.subscribe(async message => {
            if (message.type === 'connect') {
                let connectMessage = <RTCConnectMessage> message;
                console.log(`[AVPublisher] Received connect request for channel ID ${connectMessage.channelId}`);
                let session = new AVElectronSignalSession(`av-session-host:${connectMessage.channelId}`, `av-session:${connectMessage.channelId}`);

                let receivedAcceptedPromise = new Promise((resolve, reject) => {
                    session.received.subscribe(message => {
                        if (message.type === 'accepted') {
                            resolve();
                        }
                    })
                });
                
                session.send({ type: 'accept' });
                await receivedAcceptedPromise;

                this._accepted.next(session);
            }
        });
    }
}

export class AVElectronSignalSession implements AVSignalSession {
    constructor(id : string, destinationId : string) {
        this._ipcSessionReceiver = new IPCChannelReceiver(id);
        this._ipcSessionReceiver.broadcastReceived.subscribe(message => this._received.next(message));
        this._ipcHostSender = new IPCChannelSender(destinationId);
    }

    private _ipcHostSender : IPCChannelSender;
    private _ipcSessionReceiver : IPCChannelReceiver;
    private _received = new Subject<IPCMessage>();

    get received() : Observable<IPCMessage> {
        return this._received;
    }

    send(message : IPCMessage) {
        this._ipcHostSender.sendBroadcast(message);
    }
}
