// © Copyright Astronaut Labs, LLC. All Rights Reserved.

export class FormatUtility {
    
    static zeroPad(number : number | string, digits : number) {
        let numStr = ''+number;

        while (numStr.length < digits)
            numStr = '0' + numStr;

        return numStr;
    }

    static formatTime(time : number, includeMS = false) {
        let label : string;

        // TODO
        let date = new Date(time);

        let hours = date.getHours();
        let ampm = hours >= 12 ? 'PM' : 'AM';

        if (hours == 0)
            hours = 12;
         else if (hours > 12)
            hours -= 12;

        if (date.getSeconds() > 0) {
            label = `${this.zeroPad(hours, 1)}:${this.zeroPad(date.getMinutes(), 2)}:${this.zeroPad(Math.floor(date.getSeconds()), 2)}`;
        } else if (date.getMinutes() > 0) {
            label = `${this.zeroPad(hours, 1)}:${this.zeroPad(date.getMinutes(), 2)}`;
        } else {
            label = `${this.zeroPad(hours, 1)}`;
        }

        if (includeMS) {
            label += `.${this.zeroPad(date.getMilliseconds(), 3)}`;
        }

        if (!includeMS)
            label += ` ${ampm}`;

        return label;
    }

    static positionToHMS(position : number, format : 'h:m:s' | '%h%m%s', includeMS = false) {
        
        let seconds = position;
        let hours = Math.floor(seconds / (60*60));
        seconds -= hours*60*60;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes*60;

        let fractionalSeconds = seconds;
        seconds = Math.floor(seconds);

        let ms = Math.floor((fractionalSeconds - seconds) * 1000);

        let label : string;

        if (format == 'h:m:s') {

            label = ''+seconds;

            if (hours > 0) {
                label = `${hours}:${this.zeroPad(minutes, 2)}:${this.zeroPad(seconds, 2)}`;
            } else if (minutes > 0) {
                label = `${this.zeroPad(minutes, 2)}:${this.zeroPad(seconds, 2)}`;
            } else {
                label = `00:${this.zeroPad(seconds, 2)}`
            }

            if (includeMS) {
                label += `.${this.zeroPad(ms, 3)}`;
            }

        } else if (format == '%h%m%s') {
            label = '';

            if (hours > 0)
                label += `${hours}h`;
            if (minutes > 0)
                label += `${minutes}m`;
            if (seconds > 0)
                label += `${seconds}s`;
            if (includeMS)
                label += `${ms}ms`;

            if (label === '') {
                if (ms > 0) {
                    label = `${ms}ms`;
                } else {
                    label = '0s';
                }
            }
        }

        return label;
    }

}