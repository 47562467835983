// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { version } from './version';
import { buildNumber } from './build-number';
import { commit } from './commit';

export const environment = {
  version,
  commit,
  buildNumber,
  name: "prod",
  isElectron: false,
  production: true,
  grantAutomaticTrial: false,
  stripePubKey: 'pk_test_51Hu5rHHPUdwlevBDNIA5O6L5sXURcnNTfkg1KXha0EiCMwAagpKWaxQzNcgSAIdSPhVxV5s7nZRMwNpNZ0XIBz4E00Fc4ZtdjB',
  alServiceUrl: 'https://us-central1-astronautlabs.cloudfunctions.net/api',
  
  // --------------------------------------------
  // CAUTION: these are development flags, 
  // they should definitely be off in production
  // for usage, see default environment
  enableDebugTools: false, 
  startWithNoLicenses: false,
  mimickDesktop: false,
  useDummyDeckLink: false,
  useDummyNDI: false,
  simulateDeckLinkDriversMissing: false,
  simulateNDIDriversMissing: false,
  injectFakeScte35: false,
  alwaysInAdMode: false,
  // --------------------------------------------

  firebase: {
    apiKey: "AIzaSyD2X0deq5lDA5b2WpMpKsbok1H67cXqCis",
    authDomain: "overview-38b1c.firebaseapp.com",
    databaseURL: "https://overview-38b1c.firebaseio.com",
    projectId: "overview-38b1c",
    storageBucket: "overview-38b1c.appspot.com",
    messagingSenderId: "402793874563"
  }
};
