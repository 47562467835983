// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Component, ViewChild, ElementRef, HostBinding, ErrorHandler } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService, OverviewSettings, ShellService, AppStarter, SafeJSON, Bank } from '@/shell-common';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import * as Hls from 'hls.js';
import { trigger, transition, animate, style } from '@angular/animations';
import { AppErrorHandler } from './error-handler.service';
import { environment } from '@/environments/environment';
import { User, AuthenticationService } from '@astronautlabs/chassis';

declare const GOOGLE_UA_ID;
declare const gtag : any;

@Component({
  selector: 'ov-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [trigger('routeAnimations', [
    transition('* <=> *', [
      animate('1s ease-out', style({ }))
    ])
  ])]
})
export class ShellComponent {
  constructor(
    private settingsService : SettingsService,
    private router : Router,
    private appService : ShellService,
    private elementRef : ElementRef<HTMLElement>,
    private auth : AuthenticationService,
    errorHandler : ErrorHandler
  ) {
    this.appErrorHandler = errorHandler as AppErrorHandler;

    this.settingsService.banksChanged.subscribe(banks => this.banks = banks);
    this.settingsService.currentBankChanged.subscribe(bank => this.currentBank = bank);

    this.auth.userChanged.subscribe(user => this.user = user);
  }

  user : User;
  currentBank : Bank;
  banks : Bank[];

  private appErrorHandler : AppErrorHandler;

  @ViewChild('pageView')
  pageView : ElementRef<HTMLElement>;

  pageVisible : boolean;
  currentUrl : string;
  
  @ViewChild('outlet')
  outlet : RouterOutlet;

  @HostBinding('class.mat-dark-theme')
  darkMode = true;

  @HostBinding('class.enable-drop-shadows')
  enableDropShadows : boolean = false;

  @HostBinding('class.enable-animations')
  enableAnimations : boolean = false;

  @HostBinding('class.enable-visual-effects')
  enableVisualEffects : boolean = false;

  @HostBinding('class.hide-backdrop-during-dialogs')
  hideBackdropDuringDialogs : boolean = false;

  prepareRoute(outlet: RouterOutlet) {
    if (!outlet || !outlet.activatedRoute)
      return 'none';
    
    if (outlet.activatedRoute.snapshot.url[0].path === '/app')
      return 'app';
    else
      return 'web';
  }
  
  @HostBinding('class.ov-has-video-backdrop')
  hasVideoBackdrop: boolean = false;

  backdropVideoUrl : string;

  get isOfficialWeb() {
    if (environment.isElectron)
      return false;

    return true;
  }

  get hasStarfieldBackdrop() {
    return (this.backdropVideoUrl === 'ov://starfield');
  }

  @HostBinding('class.is-web-content')
  get isWeb() {
    if (this.currentUrl && this.currentUrl.startsWith('/web'))
      return true;
    
    // if (this.settingsService.monitors && this.settingsService.monitors.length > 0)
    //   return false;

    return false;
  }

  get isOverview() {
    if (this.currentUrl && this.currentUrl === '/app')
      return true;
    return false;
  }

  unfocus() {
    this.appService.back();
  }
  
  focusedItem : any = null;

  dismissError() {
    document.getElementById('error-page').style.display = 'none';
  }

  switchToBank(bank : Bank) {
    this.settingsService.switchToBank(bank);
  }

  setBackdrop(videoUrl : string) {
    if (videoUrl === this.backdropVideoUrl)
      return;
    
    this.backdropVideoUrl = videoUrl;
    let previousBackdrops = (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('video.ov-backdrop');
    Array.from(previousBackdrops).forEach(x => x.remove());

    if (!videoUrl) {
      return;
    }

    // Built-in backgrounds

    if (videoUrl.startsWith('ov://')) {
      if (videoUrl === 'ov://earth')
        videoUrl = `https://overview-media.astrocdn.com/earth.720p.mp4`;
      else
        return;
    }

    let video = document.createElement('video');
    video.classList.add('ov-backdrop');
    video.autoplay = true;
    video.loop = true;
    video.muted = true;

    (<HTMLElement>this.elementRef.nativeElement).appendChild(video);

    if (videoUrl.endsWith('.m3u8')) {
      
      if(Hls.isSupported()) {

        var hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.hasVideoBackdrop = true;
          video.play();
        });

      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        
        video.src = videoUrl;
        video.addEventListener('loadedmetadata', () => {
          this.hasVideoBackdrop = true;
          video.play();
        });

      } else {
        alert('Cannot set backdrop: Browser does not support HLS');
      }
    } else {
      video.src = videoUrl;
    }
  }

  appStarter : AppStarter;
  unhandledError : any;
  unhandledErrorJson : string;

  get unhandledErrorKeys() {
    return Object.keys(this.unhandledError);
  }

  get unhandledErrorMessage() {
    if (!this.unhandledError)
      return 'Unknown error (NONE)';
    
    return this.unhandledError.message || this.unhandledError.error || ''+this.unhandledError || 'Unknown error - (GEN_ERR)';
  }

  get unhandledErrorDetails() {
    if (!this.unhandledError)
      return null;
    
    return this.unhandledError.stack || this.unhandledError;
  }

  getJsonValue(value : any, key : string = null) {

    if (key == 'ngDebugContext') {
      return '(Check console)';
    }

    if (typeof value === 'string') {
      return `(string length=${value.length})\n${value}`;
    }

    if (typeof value === 'undefined') {
      return 'undefined';
    }

    if (value === 'null') {
      return 'null';
    }

    return ''+value;
  }

  ngOnInit() {

    console.info(
      `%c                                                                 `, 
      `
        font-size: 300%; 
        padding: 1em 0 0 0; 
        background-image: rgb(39, 39, 39); 
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        color: #fff; 
        background-image: url(https://images.astrocdn.com/overview/logo2.svg);`);

    let parts = [
      environment.version,
      `© 2018-${new Date().getFullYear()} Astronaut Labs, LLC`
    ]
    
    if (environment.buildNumber)
      parts.push(environment.buildNumber);
    if (environment.commit)
      parts.push(environment.commit);
    
    parts = parts.concat([
      `admin@astronautlabs.com`,
      `careers@astronautlabs.com`
    ]);
    
    console.info(`%c${parts.join(' | ')}`, 'font-size: 80%; padding: 0 0 2em 0');
    
    console.log(
      `%cWARNING: Inspector/DevTools can significantly reduce performance. ` 
      + `If you are having performance issues, make sure that DevTools is closed!`, 
      `
        display: block;
        padding: 0.5em;
        margin: 0.5em;
        font-size: 18pt; 
        font-family: sans-serif;
        font-weight: 100;
        color: hsl(350deg 100% 90%); 
        background: rgba(100,0,0,1);
        border-radius: 5px;
      `
    );

    this.appErrorHandler.unhandledErrorCaught.subscribe(error => this.unhandledError = error);

    this.appService.appStarterChanged.subscribe(starter => this.appStarter = starter);
    this.appService.focusedItemChanged.subscribe(item => this.focusedItem = item);
    this.appService.unlicensed.subscribe(unlicensed => this.unlicensed = unlicensed);

    this.settingsService.settingsChanged.subscribe(settings => this.applySettings(settings));
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url;
        this.pageVisible = ev.url !== '/app';
        this.pageView.nativeElement.scrollTo({ top: 0 });
        this.appService.pageVisible = this.pageVisible;

        gtag('config', GOOGLE_UA_ID, { page_path: ev.url });
      }
    });

    this.applySavedSettings();
    
    //if (1) throw new Error("There are reasons");
  }

  private _unlicensed : boolean = false;

  applySavedSettings() {
    this.applySettings(this.settingsService.settings);
  }

  get unlicensed() {
    return this._unlicensed;
  }

  set unlicensed(value : boolean) {
    this._unlicensed = value;

    if (value) {
      document.body.classList.add('unlicensed');
      this.setBackdrop(null);
    } else {
      document.body.classList.remove('unlicensed');
      this.applySavedSettings();
    }
  }

  applySettings(settings : OverviewSettings) {

    this.enableDropShadows = false;
    this.enableAnimations = false;
    this.enableVisualEffects = false;
    this.hideBackdropDuringDialogs = false;

    document.body.classList.remove('enable-visual-effects');

    let videoBackdropEnabled = false;

    if (settings.enableVisualEffects) {  
      this.enableVisualEffects = true;
      document.body.classList.add('enable-visual-effects');

      if (settings.enableVideoBackdrop) {
        if (settings.videoBackdropUrl) {
          videoBackdropEnabled = true;
          this.setBackdrop(settings.videoBackdropUrl);

          if (settings.enableDropShadows) {
            this.enableDropShadows = true;
          }

          if (settings.enableAnimations) {
            this.enableAnimations = true;
          }

          if (settings.hideBackdropDuringDialogs) {
            this.hideBackdropDuringDialogs = true;
          }
        }
      }
    }
    
    if (!videoBackdropEnabled)
      this.setBackdrop(undefined);
  }

  async logout() {
    await this.auth.signOut();
  }
}
