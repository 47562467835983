// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'ovTimestamp'
})
export class TimestampPipe {
    constructor(
        private domSanitizer : DomSanitizer
    ) {

    }

    zeroPad(number : number | string, digits : number) {
        let numStr = ''+number;

        while (numStr.length < digits)
            numStr = '0' + numStr;

        return numStr;
    }

    rightZeroPad(number : number | string, digits : number) {
        let numStr = ''+number;

        while (numStr.length < digits)
            numStr = numStr + '0';

        return numStr;
    }

    transform(timecode : number, showMS = true) {
        let hours = Math.floor(timecode / (60*60));
        timecode -= hours*60*60;

        let minutes = Math.floor(timecode / 60);
        timecode -= minutes*60;

        let seconds = Math.floor(timecode);
        timecode -= seconds;

        let ms = Math.floor(timecode * 1000.0) / 1000.0;
        let msStr = this.rightZeroPad((''+ms).replace(/^0\./, ''), 3);

        let str = `${this.zeroPad(seconds, 2)}`;
        
        if (showMS)
            str = `${str}.${msStr}`;

        str = `${this.zeroPad(minutes, 2)}:${str}`;

        if (hours > 0)
            str = `${this.zeroPad(hours, 2)}:${str}`;
 
        return str;
    }
}