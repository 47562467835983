// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { NgModule, ModuleWithProviders } from '@angular/core';
import { UserGestureService } from './user-gesture.service';
import { TimestampComponent } from './timestamp.component';
import { TimestampPipe } from './timestamp.pipe';
import { VolumeMeterComponent } from './volume-meter.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AudioService } from './audio.service';
import { ValueRenderingService } from './value-rendering.service';
import { SpectrumMeterComponent } from './spectrum-meter.component';

const COMPONENTS = [
    TimestampComponent,
    TimestampPipe,
    VolumeMeterComponent,
    SpectrumMeterComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class OverviewCommonModule {
    static forRoot(): ModuleWithProviders<OverviewCommonModule> {
    return {
        ngModule: OverviewCommonModule,
        providers: [
            UserGestureService,
            AudioService,
            ValueRenderingService
        ]
    };
}
}