// © Copyright Astronaut Labs, LLC. All Rights Reserved.

/// <reference path="../../node_modules/zone.js/dist/zone.js.d.ts" />

import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@/environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    private _unhandledErrorCaught = new Subject<any>();

    get unhandledErrorCaught() {
        return this._unhandledErrorCaught;
    }

    handleError(error: any): void {

        // Filter out some known errors that are caused by bugs in 
        // frameworks.

        // Hls.js references 'url' on a network error before the network loader 
        // is connected
        let suppressed = false;
        let zone : Zone = null;

        if (error && error.zone)
            zone = error.zone;

        let isHlsJsNetworkErrorBug = 
            error && error.rejection 
            && error.rejection.message === `Cannot read property 'url' of undefined`
            && zone && zone.get('in-hls-js')
        ;

        if (isHlsJsNetworkErrorBug) {
            suppressed = true;
            debugger;
        }
        

        setTimeout(() => {
            if (suppressed) {
                console.groupCollapsed(`Suppressed Error: ${error ? error.message : ''}`);
            } else {
                console.groupCollapsed(`Unhandled Error: ${error ? error.message : ''}`);
            }

            if (error instanceof Error) {
                if (error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError: ')) {
                    console.error(`Expression changed after it has been checked:`);
                    console.dir((error as any).ngDebugContext);
                    console.dir(error);
                } else {
                    console.error(`Uncaught error:`);
                    console.error(error);
                    console.dir(error);
                }
            } else {
                console.error(`Uncaught throwable:`);
                console.error(error);
                console.dir(error);
            }
            
            console.groupEnd();

            if (!suppressed)
                this._unhandledErrorCaught.next(error);

            // let el : HTMLElement = document.getElementById('error-page');
            // el.style.display = 'flex';
    
            // let messageEl : HTMLElement = el.querySelector('.message');
            // let detailsEl : HTMLElement = el.querySelector('.details');
            
            // messageEl.innerText = error.message || error.error || `${error}`
            // detailsEl.innerText = error.stack || error;
            //debugger;
        }, 1000);
    }
}