// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationsService {
    constructor(
        private matSnackBar : MatSnackBar
    ) {
        
    }

    notice(message : string, action? : string, callback? : Function) {
        if (!action)
            action = 'Dismiss';
        
        let ref = this.matSnackBar.open(
            message, action, 
            {
                horizontalPosition: "right",
                verticalPosition: "bottom",
                duration: 5000
            }
        );

        ref.onAction().subscribe(() => {
            if (callback) {
                callback();
            } else {
                ref.dismiss();
            }
        });
    }
}