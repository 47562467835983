// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Subject, Observable, BehaviorSubject } from 'rxjs';
import * as uuidv4 from 'uuid/v4';
import * as EventEmitter from 'events';

export interface IPCRequest<T> {
    type: 'request',
    requestId : string;
    request : T;
}

export interface IPCMessage {
    type : string;
}

export interface IPCRenderEventMessage extends IPCMessage {
    name : string;
    data : any;
}

export interface RTCCandidateMessage extends IPCMessage {
    type : 'candidate';
    candidate : string;
}

export interface RTCConnectMessage extends IPCMessage {
    type : 'connect';
    channelId : string;
}

export interface RTCNegotiationMessage extends IPCMessage {
    type : 'negotiation';
    desc : string;
}

export interface AVSignalSession {
    send(message : IPCMessage);
    readonly received : Observable<IPCMessage>;
}

export interface AVSignalPair {
    host : AVSignalSession;
    session : AVSignalSession;
}

export interface AVSignaller {
    listenForSessions();
    connectToHost() : Promise<AVSignalSession>;

    readonly accepted : Observable<AVSignalSession>;
}

export class AVSession {
    constructor(
        private signaller : AVSignaller
    ) {
    }

    private _acceptResolve : Function;
    private _acceptReject : Function;
    private _acceptPromise : Promise<void>;

    private _destinationId : string;
    get destinationId() {
        return this._destinationId;
    }

    private _renderEvents = new Subject<IPCRenderEventMessage>();
    get renderEvents(): Observable<IPCRenderEventMessage> {
        return this._renderEvents;
    }

    private async handleMessage(message : IPCMessage) {
        if (message.type === 'accept') {
            this._acceptResolve();
            return;
        } else if (message.type === 'renderer-event') {
            let renderEvent = <IPCRenderEventMessage> message;
            this._renderEvents.next(renderEvent);
        } else if (message.type === 'candidate') {
            if (!this.peerConnection) {
                console.error(`[AVSession] Received offer but peerConnection is not started!`);
                return;
            }
            let candidateMessage = <RTCCandidateMessage>message;
            let candidate = JSON.parse(candidateMessage.candidate);

            //console.log(`[AVSession] Adding ICE candidate '${candidateMessage.candidate}'`);

            if (candidate) {
                try {
                    this.peerConnection.addIceCandidate(candidate);
                } catch (e) {
                    console.log(`[AVSession] Error while adding ICE candidate to peer connection: ${e}`);
                }
            } else {
                console.log(`[AVSession] WARNING: Outdated peer sent a null ICE candidate, indicating end of candidates. This is not supported! Skipping!`);
            }

        } else if (message.type === 'negotiation') {
            if (!this.peerConnection) {
                console.error(`[AVSession] Received offer but peerConnection is not started!`);
                return;
            }

            let negotiationMsg = <RTCNegotiationMessage>message;
            let desc = JSON.parse(negotiationMsg.desc);

            //console.log(`[AVSession] Received negotiation event: '${JSON.stringify(negotiationMsg)}'`);

            if (['offer', 'answer'].includes(desc.type)) {

                console.log(`[AVSession] Setting remote description...`);
                await this.peerConnection.setRemoteDescription(desc);

                if (desc.type === 'offer') {
                    console.log(`[AVSession] Processing offer from peer...`);

                    try {
                        await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());
                    } catch (e) {
                        console.error(`[AVSession] ERROR: Caught exception during setLocalDescription: ${e}`);
                        throw e;
                    }

                    this.signalSession.send(<RTCNegotiationMessage>{
                        type: 'negotiation',
                        desc: JSON.stringify(this.peerConnection.localDescription)
                    });
                }
            } else {
                console.warn(`Unknown SDP type ${desc.type}`);
            }

        }

        this._received.next(message);
    }

    peerConnection : RTCPeerConnection;
    signalSession : AVSignalSession;

    async connect() {
        this.signalSession = await this.signaller.connectToHost();
        this.signalSession.received.subscribe(message => this.handleMessage(message));

        console.log(`[AVSession] Starting WebRTC session...`);

        this.peerConnection = new RTCPeerConnection();

        this.peerConnection.onicecandidateerror = ev => {
            console.log(`[AVSession] ERROR: Received ICE candidate error code=${ev.errorCode}, text=${ev.errorText}`);
        };

        this.peerConnection.onconnectionstatechange = ev => {
            console.log(`[AVSession] Connection state change: ${this.peerConnection.connectionState}`);

            if (this.peerConnection.connectionState === 'connected') {
                this._streamChanged.next(this._stream);
            }
        }

        this.peerConnection.onicecandidate = ev => this.sendIceCandidate(ev.candidate);
        
        this.peerConnection.onnegotiationneeded = async () => {
            console.log(`[AVSession] Received WebRTC negotiation request...`);
            //console.log(` - Description: ${JSON.stringify(this.peerConnection.localDescription)}`);
            try {
                await this.peerConnection.setLocalDescription(await this.peerConnection.createOffer());
                // send the offer to the other peer
                this.signalSession.send(<RTCNegotiationMessage>{
                    type: 'negotiation',
                    desc: JSON.stringify(this.peerConnection.localDescription)
                });
            } catch (err) {
                console.error(err);
            }
        };

        this.peerConnection.ontrack = (event) => {
            console.log(`[AVSession] Received ${event.streams.length} WebRTC stream(s)`);
            this._stream = event.streams[0];
            //this._streamChanged.next(this._stream);
        };
    }

    private _stream : MediaStream;
    private _streamChanged = new BehaviorSubject<MediaStream>(null);

    get streamChanged(): Observable<MediaStream> {
        return this._streamChanged;
    }

    get stream() {
        return this._stream;
    }

    sendIceCandidate(candidate) {
        if (!candidate) {
            console.log(`[AVSession] End of ICE candidates`);
            return;
        }

        console.log(`[AVSession] Relaying ICE candidate to AVPublisher: '${JSON.stringify(candidate)}'...`);

        this.signalSession.send(<RTCCandidateMessage>{ 
            type: 'candidate',
            candidate: JSON.stringify(candidate)
        });
    }

    private _received = new Subject<IPCMessage>();
    get received() : Observable<IPCMessage> {
        return this._received;
    }
}

export class AVPublisherSession {
    constructor(
        readonly publisher : AVPublisher,
        readonly signalSession : AVSignalSession
    ) {
        if (!publisher.stream)
            console.warn("WARNING: Publisher does not have a MediaStream, yet has accepted a session!");
        
        // this.sender = new IPCChannelSender(`av-session:${this.channelId}`);
        // this.receiver = new IPCChannelReceiver(`av-session-host:${this.channelId}`);
        // this.receiver.broadcastReceived.subscribe(async message => this.handleBroadcast(message));

        signalSession.received.subscribe(async message => await this.handleBroadcast(message));

        this.startRTC();
    }

    private peerConnection : RTCPeerConnection = null;
    private _wasDestroyed = false;
    private _destroyed = new Subject<void>();

    get destroyed() : Observable<void> {
        return this._destroyed;
    }

    destroy() {
        if (!this._wasDestroyed)
            return;

        this._wasDestroyed = true;
        this.peerConnection.close();
        this.peerConnection = null;

        this._destroyed.next();
    }

    private publishTracks() {
        console.log(`[AVPublisher] Adding ${this.publisher.stream.getTracks().length} tracks to WebRTC session...`);
        this.publisher.stream.getTracks().forEach(track => this.peerConnection.addTrack(track, this.publisher.stream));
    }

    private async handleBroadcast(message : IPCMessage) {
        console.warn(`[AVPublisherSession] Received message of type ${message.type}`);

        let handlers = {
            candidate: 'handleCandidate',
            negotiation: 'handleNegotiation',
            closed: 'handleClosed'
        };

        if (!handlers[message.type]) {
            console.warn(`[AVPublisherSession] Warning: No handler for broadcast message of type ${message.type}!`);
            return;
        }

        console.warn(`[AVPublisherSession] Handling message of type ${message.type}`);
        let handlerName = handlers[message.type];
        await this[handlerName](message);
    }
    
    private async startRTC() {
        console.log(`[AVPublisher] AV session is confirmed as accepted`);

        // connection accepted, start webrtc

        this.peerConnection = new RTCPeerConnection({
            iceServers: []
        });

        this.peerConnection.onicecandidateerror = ev => {
            console.log(`[AVPublisherSession] ERROR: Received ICE candidate error code=${ev.errorCode}, text=${ev.errorText}`);
        };

        this.peerConnection.onconnectionstatechange = ev => {
            console.log(`[AVPublisherSession] Connection state change: ${this.peerConnection.connectionState}`);
        }

        this.peerConnection.onicecandidate = ev => {
            if (!ev.candidate) {
                console.log(`[AVPublisherSession] End of ICE candidates`);
                return;
            }
            
            console.log(`[AVPublisherSession] Relaying WebRTC ICE candidate to session: '${JSON.stringify(ev.candidate)}'...`);

            this.signalSession.send(<RTCCandidateMessage>{
                type: 'candidate',
                candidate: JSON.stringify(ev.candidate)
            })
        };

        // let the "negotiationneeded" event trigger offer generation
        this.peerConnection.onnegotiationneeded = async () => {
            console.log(`[AVPublisherSession] Received RTC onNegotiationNeeded event....`);
            
            let sdp = await this.peerConnection.createOffer();
            //console.log(` - Description: ${JSON.stringify(sdp)}`);

            try {
                await this.peerConnection.setLocalDescription(sdp);
                // send the offer to the other peer

            } catch (err) {
                console.error(`[AVPublisherSession] Caught error during setLocalDescription(): ${err}`);
            }

            try {
                this.signalSession.send(<RTCNegotiationMessage>{
                    type: 'negotiation',
                    desc: JSON.stringify(sdp)
                });
            } catch (err) {
                console.error(`[AVPublisherSession] Caught error while sending negotiation event: ${err}`);
            }
        };

        await this.publishTracks();
    }

    private async handleCandidate(message : IPCMessage) {
        if (!this.peerConnection) {
            console.error(`[AVPublisher] Received candidate but peerConnection is not started!`);
            return;
        }
        console.log(`[AVPublisher] Received RTC candidate from session IPC`);

        let candidateMessage = <RTCCandidateMessage>message;
        let candidate = JSON.parse(candidateMessage.candidate);

        if (candidate) {
            this.peerConnection.addIceCandidate(candidate);
        } else {
            console.log(`[AVPublisher] Outdated peer sent null ICE candidate. This is not supported. Skipping!`);
        }
    }

    private async handleNegotiation(message : IPCMessage) {
        if (!this.peerConnection) {
            console.error(`[AVPublisher] Received offer but peerConnection is not started!`);
            return;
        }

        //console.log(`[AVPublisher] Received negotiation message from session IPC`);

        let negotiationMsg = <RTCNegotiationMessage>message;
        let desc = JSON.parse(negotiationMsg.desc);

        if (['offer', 'answer'].includes(desc.type)) {
            console.log(`[AVPublisher] Received SDP of type ${desc.type}. Setting remote description...`);
            this.peerConnection.setRemoteDescription(desc);

            if (desc.type === 'offer') {
                console.log(`[AVPublisher] Creating answer...`);

                await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());

                console.log(`[AVPublisher] Sending answer to session...`);
                this.signalSession.send(<RTCNegotiationMessage>{
                    type: 'negotiation',
                    desc: JSON.stringify(this.peerConnection.localDescription)
                });
            }
        } else {
            console.warn(`Unknown SDP type ${desc.type}`);
        }
    }

    private handleClosed(message : IPCMessage) {
        this.destroy();
    }

}

export class AVPublisher {
    constructor(
        readonly signaller : AVSignaller
    ) {
        this._streamReady = new Promise((resolve, reject) => this._streamReadyResolve = resolve);
        this.signaller.accepted.subscribe(session => this.accept(session));
    }

    public setStream(stream : MediaStream) {
        if (!stream)
            throw new Error(`AVPublisher: Passed stream was null`);
        
        console.log(`[AVPublisher] MediaStream is now available.`);
        this._stream = stream;
        this._streamReadyResolve();
    }
    
    private _streamReady : Promise<void>;
    private _streamReadyResolve : Function;
    private _stream : MediaStream = null;

    public get stream() {
        return this._stream;
    }

    private _sessions : AVPublisherSession[] = [];

    public listen() {
        console.log(`[AVPublisher] Listening for sessions...`);
        this.signaller.listenForSessions();
    }

    private async accept(signalSession : AVSignalSession) {
        if (!this._stream) {
            await this._streamReady;
            if (!this._stream)
                console.log(`[AVPublisher] WARNING: stream-ready received, but stream is not available!`);
        }

        console.log(`[AVPublisher] Accepting AVSession`);

        let session = new AVPublisherSession(this, signalSession);
        this._sessions.push(session);
        
        session.destroyed.subscribe(() => {
            this._sessions = this._sessions.filter(x => x !== session);
        });
    }
}