// © Copyright Astronaut Labs, LLC. All Rights Reserved.

import { Workman } from '../workman';
import { Subject, Observable } from 'rxjs';

declare let sampleRate;
    
function worklet() {

    const TP_COEFFICIENTS_48k = [

        // Bank 1

        0.0017089843750, 0.0109863281250, -0.0196533203125, 
        0.0332031250000, -0.0594482421875, 0.1373291015625, 
        0.9721679687500, -0.1022949218750, 0.0476074218750, 
        -0.0266113281250, 0.0148925781250, -0.0083007812500,

        // Bank 2
        -0.0291748046875, 0.0292968750000, -0.0517578125000, 
        0.0891113281250, -0.1665039062500, 0.4650878906250, 
        0.7797851562500, -0.2003173828125, 0.1015625000000, 
        -0.0582275390625, 0.0330810546875, -0.0189208984375

        // Bank 3 is Bank 2 reversed
        // Bank 4 is Bank 1 reversed
    ];
        
    class TruePeakAudioWorkletProcessor extends AudioWorkletProcessor {
        constructor() {
            super({

            });

            this.updateInterval = sampleRate / 20;

            if (sampleRate >= 96000)
                this.upsampleFactor = 2;
            else 
                this.upsampleFactor = 4;

            this.phaseCoefficients = [];

            for (let phase = 0, max = 4; phase < max; ++phase) {
                let coefficients = new Float32Array(12);
                for (let coefficientIndex = 0, max = 12; coefficientIndex < max; ++coefficientIndex) {
                    if (phase == 0)
                        coefficients[coefficientIndex] = TP_COEFFICIENTS_48k[coefficientIndex];
                    else if (phase == 1)
                        coefficients[coefficientIndex] = TP_COEFFICIENTS_48k[max + coefficientIndex];
                    else if (phase == 2)
                        coefficients[coefficientIndex] = TP_COEFFICIENTS_48k[max * 2 - 1 - coefficientIndex];
                    else if (phase == 3)
                        coefficients[coefficientIndex] = TP_COEFFICIENTS_48k[max - 1 - coefficientIndex];
                }

                this.phaseCoefficients.push(coefficients);
            }
        }

        static get parameterDescriptors() {
            return [
                { name: 'averaging', defaultValue: 0.95 }
            ]
        }

        phaseCoefficients : Float32Array[];
        upsampleFactor : number = 4;
        delayLines : Float32Array[] = [];
        delayLineIndex = 0;

        lastUpdated : number = 0;
        processedFramesSinceUpdate : number = 0;
        updateInterval : number = 0;

        sendUpdatesWhenScheduled(framesProcessed : number) {
            this.processedFramesSinceUpdate += framesProcessed;

            if (this.processedFramesSinceUpdate >= this.updateInterval) {
                this.processedFramesSinceUpdate = 0;
                this.sendUpdate();
            }
        }

        state : TruePeakState = {
            channels : [],
            rawPeaks: []
        };

        sendUpdate() {
            this.port.postMessage({
                type: 'update',
                state: this.state
            });
        }

        process(
            inputs : Float32Array[][], 
            outputs : Float32Array[][], 
            parameters : any) 
        {
            let input = inputs[0];
            let delayLineLength = 12;
            let frameCount = input[0].length;

            // Set up the delay lines if the channel count has changed

            if (input.length != this.delayLines.length) {
                for (let channelIndex = 0, max = input.length; channelIndex < max; ++channelIndex)
                    this.delayLines.push(new Float32Array(delayLineLength));
            }

            // Process the block

            for (let channelIndex = 0, max = input.length; channelIndex < max; ++channelIndex) {
                let delayLineIndex = this.delayLineIndex;
                let samples = input[channelIndex];
                let delayLine = this.delayLines[channelIndex];

                // - Because we have floating point samples, we do not need to attenuate
                // - Because we are not actually outputting the oversampled result, we do 
                //   not need to allocate RAM for the upsampled (2x/4x) result buffer
                
                let maxPeakThisBlock = 0;

                let maxSample = 0;

                for (let sampleIndex = 0, max = samples.length; sampleIndex < max; ++sampleIndex) {
                    // Push a sample onto the delay line 
                    delayLine[delayLineIndex] = samples[sampleIndex];

                    // Calculate four over-samples (FIR interpolation, 48-node, 4-phase) based on the 
                    // current delay line content

                    let peaks = new Float32Array(4);

                    for (let phaseFilterIndex = 0, max = peaks.length; phaseFilterIndex < max; ++phaseFilterIndex) {
                        let coefficients = this.phaseCoefficients[phaseFilterIndex];
                        let peak = 0;

                        for (let delayedSampleIndex = 0, max = delayLine.length; delayedSampleIndex < max; ++delayedSampleIndex) {
                            peak += coefficients[delayedSampleIndex] * delayLine[(delayLineIndex - delayedSampleIndex + delayLineLength) % delayLineLength];
                        } 

                        let absPeak = Math.abs(peak);

                        if (absPeak > maxPeakThisBlock)
                            maxPeakThisBlock = peak;
                    }
                    delayLineIndex = (delayLineIndex + 1) % delayLineLength;
                }

                let currentMaxPeak = this.state.channels[channelIndex] || 0;
                
                this.state.rawPeaks[channelIndex] = maxPeakThisBlock;

                if (maxPeakThisBlock > currentMaxPeak) {
                    this.state.channels[channelIndex] = maxPeakThisBlock;
                } else {
                    // Fade
                    this.state.channels[channelIndex] = currentMaxPeak * parameters.averaging[0]
                }
            }
            
            // Move the delay line ring buffer head forward by the amount of frames we've processed
            this.delayLineIndex = (this.delayLineIndex + frameCount) % delayLineLength;

            // Send updates as needed
            this.sendUpdatesWhenScheduled(frameCount);

            return true;
        }
    }

    registerProcessor('itu-r-bs1770-2.true-peak.processor', TruePeakAudioWorkletProcessor);
}

export interface TruePeakState {
    channels: number[];
    rawPeaks: number[];
}

/**
 * Implements true-peak monitoring as specified in ITU-R BS.1770-4 Annex 2.
 * Subscribe to `updates` observable to receive true peak values every 20 seconds
 */
export class TruePeakAudioWorkletNode extends AudioWorkletNode {
    constructor(context : AudioContext, averaging : number) {
        super(context, 'itu-r-bs1770-2.true-peak.processor', {
            parameterData: { averaging }
        })

        this.port.onmessage = ev => {
            if (ev.data.type == 'update') {
                this._updates.next(ev.data.state as TruePeakState);
            }
        };
    }

    _updates = new Subject<TruePeakState>();

    get updates(): Observable<TruePeakState> {
        return this._updates;
    }

    static async create(context : AudioContext, averaging : number) {
        await Workman.loadAudioWorklet(context, worklet);
        return new TruePeakAudioWorkletNode(context, averaging);
    }
}